import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchCategories,
  resetSearch,
  setCurrentLocationPath,
  clearGlobalErrorIndicator
} from "./store/actions";
import "./App.scss";
import Home from "./pages/home";
import SignUp from "./pages/signup";
import ProductDetail from "./pages/productdetail";
import StoreFront from "./pages/storefront";
import { Route, withRouter } from "react-router-dom";
import Header from "./components/common/header";
import Footer from "./components/common/footer";
import SessionTimeout from "./components/security/SessionTimeout";
import ManageMarketplace from "./pages/ManageMarketplace";
import ManagePartnerDetail from "./pages/ManagePartnerDetail";
import ViewPartnerDetail from "./pages/ViewPartnerDetail";
import { CookiesProvider } from "react-cookie";
import Help from "./pages/Help";
import Contactus from "./pages/ContactUs";
import ErrorModal from "./components/common/errormodal";
import {initializePendo} from "./Util/initializePendo";

class App extends Component {
  // Dispatch an action to fetch all of the categories.
  componentDidMount() {
    const { dispatch, location } = this.props;
    initializePendo();

    // Ensure that the current location path reflects the
    // route loaded from the browser.
    dispatch(setCurrentLocationPath(location.pathname));
    dispatch(fetchCategories());
  }

  componentDidUpdate(prevProps) {
    const { dispatch, location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      if (location.pathname.includes("storefront")) {
        dispatch(resetSearch());
      }
      dispatch(setCurrentLocationPath(location.pathname));
      dispatch(clearGlobalErrorIndicator());
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <React.Fragment>
        <CookiesProvider>
          <SessionTimeout />
          <Header />
          <ErrorModal />
          <Route exact path="/" component={Home} />
          <Route path="/signup" component={SignUp} />
          <Route exact path="/storefront" component={StoreFront} />
          <Route exact path="/search" component={StoreFront} />
          <Route exact path="/storefront/featured" component={StoreFront} />
          <Route exact path="/products/:id" component={ProductDetail} />
          <Route path="/managemarketplace" component={ManageMarketplace} />
          <Route
            exact
            path="/managepartnerdetail/:partnername"
            component={ManagePartnerDetail}
          />
          <Route
            exact
            path="/managepartnerdetail"
            component={ManagePartnerDetail}
          />
          <Route
            path="/viewpartnerdetail/:partnername"
            component={ViewPartnerDetail}
          />
          <Route path="/help" component={Help} />
          <Route path="/contactus" component={Contactus} />
          <Footer />
        </CookiesProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { currentLocationPath } = state;
  return {
    currentLocationPath
  };
};

export default withRouter(connect(mapStateToProps)(App));
