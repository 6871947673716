import React, { Component } from "react";
import "./storefront.scss";
import CategoryList from "./categoryList";
import FilterNav from "./filterNav";

class StoreFrontNav extends Component {
  render() {
    return (
      <React.Fragment>
        <FilterNav />
        <div className="CategoriesHeader paddingtop">Categories</div>
        <CategoryList />
      </React.Fragment>
    );
  }
}

export default StoreFrontNav;
