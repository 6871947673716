import "whatwg-fetch";
import { HOST } from "../config/environment";
import { setActionIndicator, setGlobalErrorIndicator } from "../store/actions";
import store from "../store/configure-store";

export const DefaultLoadError =
    "Sorry, this page didn't load correctly. Try refreshing the page. If this keeps happening, contact your Paycor Support Team.";
export const DefaultUpdateError =
    "Sorry, there was a problem with your submission. Try resubmitting the form. If this keeps happening, contact your Paycor Support Team.";

export default class Api {
    constructor(url, data = {}) {
        this.standardHeaders = new Headers({
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": HOST.ACCESS_CONTROL_MAX_AGE,
            Accept: "application/json",
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": HOST.APIM_KEY,
        });

        this.url = url;
        this.data = data;
    }

    getWithNoCache() {
        let headers = this.standardHeaders;
        headers.append("pragma", "no-cache");
        headers.append("cache-control", "no-store");
        return fetch(this.url, {
            method: "get",
            mode: "cors",
            headers: headers,
            credentials: "include",
        });
    }
    getWithNoCacheNoCors() {
        let headers = this.standardHeaders;
        headers.append("pragma", "no-cache");
        headers.append("cache-control", "no-store");
        return fetch(this.url, {
            method: "get",
            mode: "no-cors",
            headers: headers,
            credentials: "include",
        });
    }
}

export class FetchWrapper {
    constructor(url, data = {}) {
        this.standardHeaders = new Headers({
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Max-Age": HOST.ACCESS_CONTROL_MAX_AGE,
            Accept: "application/json",
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": HOST.APIM_KEY,
        });

        this.url = url;
        this.data = data;
    }

    get(
        success = null,
        fail = null,
        final = null,
        headers = this.standardHeaders
    ) {
        store.dispatch(
            setActionIndicator({ show: true, message: "Loading..." })
        );

        fetch(this.url, {
            method: "get",
            mode: "cors",
            headers: headers,
            credentials: "include",
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else throw resp;
            })
            .then((json) => {
                if (success) success(json);
            })
            .catch((error) => {
                if (fail) {
                    fail(error);
                    return;
                }
                store.dispatch(setGlobalErrorIndicator(DefaultLoadError));
            })
            .finally(() => {
                store.dispatch(
                    setActionIndicator({ show: false, message: "" })
                );
                if (final) final();
            });
    }

    getWithNoCache(success = null, fail = null, final = null) {
        let headers = this.standardHeaders;
        headers.append("pragma", "no-cache");
        headers.append("cache-control", "no-store");
        return this.get(success, fail, final, headers);
    }

    post(success = null, fail = null, final = null) {
        return this.upsert("post", success, fail, final);
    }

    put(success = null, fail = null, final = null) {
        return this.upsert("put", success, fail, final);
    }

    upsert(method, success = null, fail = null, final = null) {
        store.dispatch(
            setActionIndicator({ show: true, message: "Submitting..." })
        );
        return fetch(this.url, {
            method: method,
            mode: "cors",
            body: JSON.stringify(this.data),
            headers: this.standardHeaders,
            credentials: "include",
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else throw resp;
            })
            .then((json) => {
                if (success) success(json);
            })
            .catch((error) => {
                if (fail) {
                    fail(error);
                    return;
                }
                store.dispatch(setGlobalErrorIndicator(DefaultUpdateError));
            })
            .finally(() => {
                store.dispatch(
                    setActionIndicator({ show: false, message: "" })
                );
                if (final) final();
            });
    }
}
