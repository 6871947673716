import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SALESFORCE_PRIV } from "../../config/environment";
import Button from "@paycor/picl/button";
import "./faq.scss";
import { getUserProfile } from "../../store/root-reducer";
import * as actions from "../../store/actions";
import SuccessTeamModal from "./successteammodal";

class FaqIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            view: false,
        };
    }

    handleToggleModalButton = () => {
        const { view } = this.state;
        this.setState({
            view: !view,
        });
    };

    render() {
        let salesForcePriv = "";
        const { history } = this.props;

        if (this.props.userProfile) {
            salesForcePriv = this.props.userProfile.privileges;
        }
        return (
            <div>
                <div className="NeedMoreHelp">
                    <div className="FooterHeader1">Need something else?</div>
                    <div className="Dont-see-your-answer">
                        We're ready to help.
                    </div>
                    {salesForcePriv &&
                    salesForcePriv.some(
                        (item) =>
                            item.privilegeId === SALESFORCE_PRIV && item.allowed
                    ) ? (
                        <div>
                            <div className="row visible-xs">
                                <div className="col-xs-12 faqbuttons">
                                    <Button
                                        id="btnViewMySuccessTeam"
                                        busy={false}
                                        disabled={false}
                                        type="primary"
                                        onClick={this.handleToggleModalButton}
                                    >
                                        View My Success Team
                                    </Button>
                                </div>
                                <div className="col-xs-12">
                                    <Button
                                        id="btnContactus"
                                        busy={false}
                                        disabled={false}
                                        type={Button.Types.SECONDARY_ACTION}
                                        onClick={() =>
                                            history.push("/contactus")
                                        }
                                    >
                                        Contact Us
                                    </Button>
                                </div>
                            </div>
                            <div className="row visible-sm visible-md visible-lg">
                                <Button
                                    id="btnBrowseSolutions"
                                    busy={false}
                                    disabled={false}
                                    type="primary"
                                    onClick={this.handleToggleModalButton}
                                >
                                    View My Success Team
                                </Button>
                                <Button
                                    id="btnBrowseSolutions"
                                    busy={false}
                                    disabled={false}
                                    type={Button.Types.SECONDARY_ACTION}
                                    onClick={() => history.push("/contactus")}
                                >
                                    Contact Us
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Button
                                id="btnBrowseSolutions"
                                busy={false}
                                disabled={false}
                                type="primary"
                                onClick={() => history.push("/contactus")}
                            >
                                Contact Us
                            </Button>
                        </div>
                    )}
                    {this.state.view && (
                        <SuccessTeamModal
                            okayClicked={this.handleToggleModalButton}
                            showSuccessTeam={true}
                        />
                    )}
                    <div className="faqbuttonsPadd"> </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    userProfile: getUserProfile(state),
});

export default withRouter(connect(mapStateToProps, actions)(FaqIndex));
