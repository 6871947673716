import React, { Component } from "react";
import { HOST } from "../../config/environment";
import { NavLink } from "react-router-dom";
import footer from "./footer.module.scss";

class Footer extends Component {
  render() {
    return (
      <div className={footer.container}>
        <div className="App">
          <div className={`row ${footer.linkRow} hidden-xs`}>
            <div className={`col-md-2 ${footer.sideColumn}`} />
            <div className={`col-md-2 col-xs-3 ${footer.column}`}>
              <div className={footer.header}>Marketplace</div>
              <NavLink id="lnkHelp" className={footer.link} to="/help">
                Help
              </NavLink>
              <NavLink
                id="lnkContactUs"
                className={footer.link}
                to="/contactus"
              >
                Contact Us
              </NavLink>
            </div>
            <div className={`col-md-2 col-xs-3 ${footer.column}`}>
              <div className={footer.header}>Apps</div>
              <NavLink
                id="lnkBrowseAll"
                className={footer.link}
                to="/storefront"
              >
                Browse All
              </NavLink>
            </div>
            <div className={`col-md-2 col-xs-3 ${footer.column}`}>
              <div className={footer.header}>Partners</div>
              <a
                id="lnkLearnMore"
                className={footer.link}
                href={HOST.PARTNER_REGISTRATION}
                target = "_blank"
              >
                Partner with Us
              </a>
            </div>
            <div className={`col-md-2 col-xs-3 ${footer.column}`}>
              <div className={footer.header}>Developers</div>
              <a
                id="lnkDevPortal"
                className={footer.link}
                href={HOST.API_DEVELOPER_PORTAL}
              >
                Developer Portal
              </a>
            </div>
            <div className={`col-md-2 ${footer.sideColumn}`} />
          </div>
          <div className={`${footer.copyright} row`}>
            <div className={`${footer.text} hidden-xs`}>
              © {new Date().getFullYear()} Paycor, Inc |{" "}
              <a
                id="lnkPrivacy"
                className={footer.privacyLink}
                href="https://www.paycor.com/privacy-policy"
              >
                Privacy Policy
              </a>{" "}
              | 1-800-501-9462
            </div>
            <div className={`${footer.text} visible-xs`}>
              <div>
                © {new Date().getFullYear()} Paycor, Inc |{" "}
                <a
                  id="lnkPrivacy"
                  className={footer.privacyLink}
                  href="https://www.paycor.com/privacy-policy"
                >
                  Privacy Policy
                </a>
              </div>
              <div style={{ paddingTop: "8px" }}>1-800-501-9462</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
