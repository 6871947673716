import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Collapsible from "@paycor/picl/collapsible";
import FeedbackTab from "./feedbacktab";
import "../productdetail/productdetail.scss";

class ProductTabs extends Component {
    render() {
        const { productdetails, product } = this.props;
        const isReturnFromSignin = window.location
            .toString()
            .includes("feedback=true");

        if (productdetails == null || productdetails.length === 0) return "";
        const activeKey = isReturnFromSignin
            ? "Reviews"
            : productdetails[0].name;

        return (
            <React.Fragment>
                <div className="padding_to hidden-xs">
                    <Tabs
                        defaultActiveKey={activeKey}
                        id="product-tab"
                        className="tab_active"
                    >
                        {productdetails.map((detail) => (
                            <Tab
                                key={detail.name}
                                eventKey={detail.name}
                                title={detail.name}
                            >
                                <div className="tab_padding">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: detail.contentHtml,
                                        }}
                                    />
                                </div>
                            </Tab>
                        ))}
                        <Tab key="Reviews" eventKey="Reviews" title="Reviews">
                            <FeedbackTab product={product} />
                        </Tab>
                    </Tabs>
                </div>
                <div className="visible-xs" style={{ marginBottom: "25px" }}>
                    <Collapsible>
                        {productdetails.map((detail) => (
                            <Collapsible.Item
                                id={detail.name}
                                title={detail.name}
                                key={detail.name}
                                isActive={detail.name === "Details"}
                            >
                                <div className="tab_padding">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: detail.contentHtml,
                                        }}
                                    />
                                </div>
                            </Collapsible.Item>
                        ))}
                        <Collapsible.Item id={"Reviews"} title={"Reviews"}>
                            <FeedbackTab product={product} />
                        </Collapsible.Item>
                    </Collapsible>
                </div>
            </React.Fragment>
        );
    }
}
export default ProductTabs;
