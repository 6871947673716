import React, { Component } from "react";
import "./PartnerImageBanner.scss";
import PartnerList from "./PartnerList";
class SolutionsCategories extends Component {
  render() {
    return (
      <div className="PartnerImageBannerContainer">
        <div className="PartnerImageBanner row ">
          <div className="OurPartnerHeader row">Our Partners</div>
          <div className="row">
            <PartnerList />
          </div>
        </div>
      </div>
    );
  }
}

export default SolutionsCategories;
