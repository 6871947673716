import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "@paycor/picl/button";
import "./categorysolutions.scss";
import Category from "./category";
import { HOST } from "../../config/environment";
import { FetchWrapper, DefaultLoadError } from "../../Util/FetchWrapper";
import { setGlobalErrorIndicator } from "../../store/actions";

class CategorySolutions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        const { dispatch } = this.props;

        this.setState({
            categoryDataLoad: {
                display: "inline-block",
            },
        });

        let categoryurl =
            HOST.ENDPOINTS.MP_CATEGORIES + "/featured?productLimitPerGroup=5";
        let marketplaceApi = new FetchWrapper(categoryurl);
        marketplaceApi.get(
            data => {
                this.setState({
                    categoryData: data,
                    categoryDataLoad: {
                    display: "none"
                  }
                });
              },
            () => {
                dispatch(setGlobalErrorIndicator(DefaultLoadError));
                this.setState({
                    categoryDataLoad: {
                        display: "none",
                    },
                });
            }
        );
    }
    render() {
        let { categoryData } = this.state;
        const { history } = this.props;

        return (
            <div className="CategoryContainer row">
                <div className="LoadingSpinner">
                    <div
                        className="loading-spinner"
                        style={this.state.categoryDataLoad}
                    >
                        <div className="icon-spinner spinner-padding white" />
                    </div>
                </div>
                {categoryData
                    ? categoryData
                            .map((obj) => (
                              <Category key={obj.id} category={obj} />
                          ))
                    : ""}
                <div
                    id="buttonContainer"
                    className="col-md-12 col-sm-12 col-xs-12 buttonStoreFrontContainer"
                >
                    <Button
                        id="btnStoreFront"
                        aria-label="primary button"
                        busy={false}
                        disabled={false}
                        type="primary"
                        onClick={() => history.push("/storefront")}
                        className="buttonStoreFront"
                    >
                        View All Apps
                    </Button>
                </div>
            </div>
        );
    }
}

export default withRouter(connect()(CategorySolutions));
