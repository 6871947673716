import React, { Component } from "react";
import { HOST } from "../../config/environment";
import { FetchWrapper } from "../../Util/FetchWrapper";
import Table from "@paycor/picl/table";
import Button from "@paycor/picl/button";
import "./ManageDevelopers.scss";
import { connect } from "react-redux";
import { fetchUsersByPartner } from "../../store/actions";
import Banner from "@paycor/picl/banner";
import AddDeveloperUserDialog from "./AddDeveloperUserDialog";

class ManageDevelopers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: "",
            status: this.props.status,
            ShowAddUser: false,
            ResendInviteError: "",
            AddUserEnabled:
                this.props.status === "Active" ||
                this.props.status === "Pending",
        };

        this.GetUsers = this.GetUsers.bind(this);
        this.ResendInvite = this.ResendInvite.bind(this);
    }

    componentDidMount() {
        this.GetUsers();
    }

    GetUsers() {
        const { dispatch } = this.props;
        dispatch(fetchUsersByPartner(this.props.partner));
    }

    ResendInvite(id, email) {
        let url = `${HOST.ENDPOINTS.REG_USER_INVITE}/${id}/invite`;
        // console.log("In Resend Invite, url:", url);
        let marketplaceApi = new FetchWrapper(url);

        marketplaceApi.post(
            () => {
                this.setState({
                    emailAddress: email,
                    ResendInviteError: "",
                });
                console.log("Invitation sent successfully.");
            },
            () => {
                this.setState({
                    emailAddress: "",
                    ResendInviteError:
                        "Error in sending email! Please try again later.",
                });
            }
        );
    }

    render() {
        const { usersByPartner } = this.props;
        let banner = "";
        if (this.state.emailAddress !== "") {
            banner = (
                <Banner
                    id={"EmailSend"}
                    message={
                        "Success! An invite has been sent to " +
                        this.state.emailAddress +
                        "."
                    }
                    status={Banner.Status.SUCCESS}
                    show={true}
                />
            );
        }
        if (this.state.ResendInviteError !== "") {
            banner = (
                <Banner
                    id={"EmailSendError"}
                    message={this.state.ResendInviteError}
                    status={Banner.Status.ERROR}
                    show={true}
                />
            );
        }

        return (
            <div id="DevPortalContainer" className="col-md-12">
                <div id="DevPortalHeader" className="col-md-12 DevPortalDiv">
                    <span className="PortalHeaderText">
                        {" "}
                        Developer Portal Access
                    </span>
                    <Button
                        id="btnAddUser"
                        icon={"add"}
                        aria-label="button"
                        type="secondary-action"
                        className="ButtonUser"
                        disabled={!this.state.AddUserEnabled}
                        onClick={() => this.setState({ ShowAddUser: true })}
                    >
                        User
                    </Button>
                    <AddDeveloperUserDialog
                        ShowAddUser={this.state.ShowAddUser}
                        Partner={this.props.partner}
                        OnInviteSuccess={(email) =>
                            this.setState({ emailAddress: email })
                        }
                        OnDlgClose={() => this.setState({ ShowAddUser: false })}
                    />
                </div>
                <div className="BannerContainer">{banner}</div>

                <div
                    id="DevPortalTable"
                    className="col-md-12 row TableBottonDiv"
                >
                    <Table
                        isError={
                            (usersByPartner || usersByPartner.length) > 0
                                ? false
                                : true
                        }
                        customError="No Developer Portal Users"
                    >
                        <Table.Header>
                            <Table.HeaderCell id={"name"} columnWidth={"120px"}>
                                Name
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                id={"email"}
                                columnWidth={"200px"}
                            >
                                Email
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                id={"phone"}
                                columnWidth={"150px"}
                            >
                                Phone Number
                            </Table.HeaderCell>

                            <Table.HeaderCell id={"resend"} />
                        </Table.Header>
                        <Table.Body>
                            {usersByPartner && usersByPartner.length > 0
                                ? usersByPartner.map((data, key) => {
                                      return (
                                          <Table.Row id={key} key={key}>
                                              <Table.Cell>
                                                  {data.firstName +
                                                      " " +
                                                      data.lastName}
                                              </Table.Cell>
                                              <Table.Cell>
                                                  {data.emailAddress}
                                              </Table.Cell>
                                              <Table.Cell>
                                                  {data.phoneNumber}
                                              </Table.Cell>

                                              <Table.Cell
                                                  id={`${data.emailAddress}_ResendLink}`}
                                              >
                                                  <Button
                                                      id="ResendInvite"
                                                      aria-label="secondary button"
                                                      type={
                                                          Button.Types.SECONDARY
                                                      }
                                                      onClick={() =>
                                                          this.ResendInvite(
                                                              data.marketPlaceGuid,
                                                              data.emailAddress
                                                          )
                                                      }
                                                      children="Resend Invite"
                                                      className=".ButtonLink"
                                                      disabled={
                                                          !this.state
                                                              .AddUserEnabled
                                                      }
                                                  />
                                              </Table.Cell>
                                          </Table.Row>
                                      );
                                  })
                                : ""}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { usersByPartner, isFetching } = state.partnerUsersData;
    return { usersByPartner, isFetching };
};

export default connect(mapStateToProps)(ManageDevelopers);
