import React, { Component } from "react";
import StoreFrontNav from "./StoreFrontNav";
import ProductContainer from "./ProductContainer";
import MetaTags from "react-meta-tags";

class StoreFrontLayout extends Component {
  render() {
    return (
      <div className="StoreContentContainer">
        <MetaTags>
            <title>Marketplace Product Catalog</title>
                      
          <meta
            name="description"
            content="Search, sort, and filter through Paycor's wide variety of partners that offer unique benefits to your business. Payroll, finance, recruiting, benefits, and more!"
          />
                
        </MetaTags>

        <div className="row">
          <div className="col-sm-3 col-md-3 col-lg-2 visible-md visible-lg leftnav">
            <StoreFrontNav />
          </div>

          <div className="col-sm-12 col-md-9 col-lg-10 NoColPadding">
            <div className="StoreContent">
              <ProductContainer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StoreFrontLayout;
