import React, { Component } from "react";
import SignupBanner from "../components/signup/banner";
import SignupContactus from "../components/signup/contactus";
import PartnerImageBanner from "../components/signup/PartnerImages";
import BusyIndicator from "../components/common/busyindicator";

class SignUp extends Component {
  render() {
    return (
      <div>
        <BusyIndicator />
        <SignupBanner />
        <div className="App MainContent page-row page-row-expanded">
          <SignupContactus />
          <PartnerImageBanner />
        </div>
      </div>
    );
  }
}
export default SignUp;
