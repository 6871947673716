import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Search as SearchIcon } from "@paycor/picl/icon";
import {
    toggleSearchPopup,
    setSearchTerm,
    initiateSearch,
} from "../../store/actions";

class SearchPopup extends Component {
    constructor(props) {
        super(props);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSearchIconClicked = this.handleSearchIconClicked.bind(this);
        this.onSearchChanged = this.onSearchChanged.bind(this);
    }

    // Invoke the search delegate when user hits enter if there is a
    // phrase to search for.
    handleKeyPress(e) {
        if (e.key === " " && e.target.value === "") {
            e.preventDefault();
            return false;
        }
        if (e.key === "Enter") {
            if (e.target.value.trim() !== "") {
                e.preventDefault();
                this.onSearch();
            }
        }
    }

    onSearchChanged(e) {
        const { dispatch } = this.props;
        dispatch(setSearchTerm(e.target.value));
    }

    onSearch() {
        const { dispatch, currentTerm, history } = this.props;
        const modified = currentTerm.trim();

        if (modified !== "") {
            dispatch(setSearchTerm(modified));
            dispatch(toggleSearchPopup(false));
            dispatch(initiateSearch(modified));
            const url = history.location.pathname;
            if (!url.includes("search")) {
                history.push("/search");
            }
        }
    }

    handleSearchIconClicked() {
        const { isEntered, dispatch, currentTerm } = this.props;
        if (currentTerm) {
            this.onSearch();
        } else {
            dispatch(toggleSearchPopup(!isEntered));
        }
    }

    render() {
        const { currentTerm, isEntered, classParm } = this.props;
        if (isEntered)
            return (
                <React.Fragment>
                    {/* A bit code smell here, but necessary to turn of the right instance of this control at the right time */}
                    <div className={"tabletPadArea " + classParm}>
                        <div className="searchAreaActive">
                            <input
                                autoFocus
                                type="text"
                                id="txtSearch"
                                placeholder="Search apps or partners"
                                onKeyPress={this.handleKeyPress}
                                onChange={this.onSearchChanged}
                                value={currentTerm}
                            />
                            {/* eslint-disable-next-line */}
                            <a onClick={this.handleSearchIconClicked}>
                                <SearchIcon
                                    size="20px"
                                    className="searchIconEntered"
                                />
                            </a>
                        </div>
                    </div>
                </React.Fragment>
            );
        return "";
    }
}

const mapStateToProps = (state) => {
    const { isSearching, searchTerm, currentTerm, isEntered } =
        state.productData;
    const { currentLocationPath } = state;
    return {
        isEntered,
        isSearching,
        searchTerm,
        currentTerm,
        currentLocationPath,
    };
};

export default withRouter(connect(mapStateToProps)(SearchPopup));
