import React, { Component } from "react";
import "./contactus.scss";
import Button from "@paycor/picl/button";
import { InputField, TextField, Select } from "@paycor/picl/form-elements";
import { HOST } from "../../config/environment";
import { FetchWrapper } from "../../Util/FetchWrapper";
import {
    validateEmail,
    validateWebsite,
    validateRequired,
    InputMasks,
    validateRegularExpression,
    ValidationPatterns,
} from "../../Util/validators";
import MetaTags from "react-meta-tags";

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.loggingEnabled = false;
        this.state = {
            showConfirmation: false,
            isValidForm: true,
            errorMessage: "",
            FirstName: "",
            LastName: "",
            Email: "",
            PhoneNumber: "",
            PhoneNumberExt: "",
            CompanyName: "",
            CompanyWebsite: "",
            JobTitle: "",
            Comments: "",
            Error_FirstName: "",
            Error_LastName: "",
            Error_Comments: "",
        };
        this.handleRefTypeChange = this.handleRefTypeChange.bind(this);
    }

    getmktsource() {
        let cov = [
            {
                id: "1",
                label: "Internet Search",
                value: "Internet Search",
            },
            {
                id: "2",
                label: "Paycor Client",
                value: "Paycor Client",
            },
            {
                id: "3",
                label: "Paycor Salesperson",
                value: "Paycor Salesperson",
            },
            {
                id: "4",
                label: "Radio, Advertisement, or Event",
                value: "Radio, Advertisement, or Event",
            },
            {
                id: "5",
                label: "Other",
                value: "Other",
            },
        ];
        return cov;
    }

    contactSubmit(e) {
        e.preventDefault();
        const {
            FirstName,
            LastName,
            Email,
            PhoneNumber,
            PhoneExtension,
            CompanyName,
            CompanyWebsite,
            JobTitle,
            ReferenceType,
            SolutionDescription,
        } = this.state;

        // Validate each field.
        const errFirstName = validateRequired(
            FirstName,
            "First name is required"
        );
        const errLastName = validateRequired(LastName, "Last name is required");
        const errEmail = validateEmail(
            Email,
            "Email address is invalid",
            "Email address is required"
        );
        const errPhoneNumber = validateRequired(
            PhoneNumber,
            "Phone Number is required"
        );
        const errPhoneExtension = validateRegularExpression(
            PhoneExtension,
            ValidationPatterns.PhoneExtension,
            "Must be 4 digits or less."
        );

        const errCompanyName = validateRequired(
            CompanyName,
            "Company Name is required"
        );

        const errCompanyWebsite = validateWebsite(
            CompanyWebsite,
            "Website is invalid",
            "Website is required"
        );

        const errJobTitle = validateRequired(JobTitle, "Job Title is required");
        const errDescription = validateRequired(
            SolutionDescription,
            "Comments are required"
        );
        const errRefType = validateRequired(ReferenceType, "Select one value");

        // Determine if there were any errors.
        const error = Boolean(
            errFirstName.concat(
                errLastName,
                errEmail,
                errPhoneNumber,
                errCompanyName,
                errCompanyWebsite,
                errJobTitle,
                errDescription,
                errRefType
            )
        );

        this.setState({
            Error_FirstName: errFirstName,
            Error_LastName: errLastName,
            Error_Email: errEmail,
            Error_PhoneNumber: errPhoneNumber,
            Error_PhoneExtension: errPhoneExtension,
            Error_CompanyName: errCompanyName,
            Error_CompanyWebsite: errCompanyWebsite,
            Error_JobTitle: errJobTitle,
            Error_RefType: errRefType,
            Error_Description: errDescription,
        });

        if (error) return;

        if (!error) {
            let contactData = {
                firstName: FirstName,
                lastName: LastName,
                email: Email,
                phoneNumber: PhoneNumber,
                phoneExtension: PhoneExtension,
                companyName: CompanyName,
                companyWebsite: CompanyWebsite,
                jobTitle: JobTitle,
                referenceType: ReferenceType,
                solutionDescription: SolutionDescription,
            };

            let url = HOST.ENDPOINTS.MP_PRODUCT_REQUESTS;
            let marketplaceApi = new FetchWrapper(url, contactData);

            marketplaceApi.post(
                () => {
                    this.setState({
                        show: false,
                        showConfirmation: true,
                        isValidForm: true,
                    });
                },
                (error) => {
                    if (error.status === 400) {
                        this.setState({
                            isNetworkError: true,
                            show: true,
                            showConfirmation: false,
                            isValidForm: false,
                            SubmitErrorMessage:
                                "Partner Registration Information already exist for the Provided Company.",
                        });
                    } else {
                        this.setState({
                            show: true,
                            showConfirmation: false,
                            isValidForm: false,
                            SubmitErrorMessage:
                                "There was a problem submitting your contact request. Please try again later.",
                        });
                    }
                }
            );
        }
    }

    handleRefTypeChange(selectedOption) {
        this.setState({ ReferenceType: selectedOption.id });
    }
	
    renderConfirmation() {
        const { SubmitErrorMessage, showConfirmation, isValidForm } =
            this.state;

        if (!isValidForm) {
            return (
                <div id="pnl2" className={"icon-error iconCheckSpacing"}>
                    <span className="submissionSentNotification">
                        {SubmitErrorMessage}
                    </span>
                </div>
            );
        }
        if (showConfirmation) {
            return (
                <div id="pnl2" className={"icon-check iconCheckSpacing"}>
                    <span className="submissionSentNotification">
                        Thank you for contacting us! Someone from the team will
                        be in touch shortly.
                    </span>
                </div>
            );
        }
        return "";
    }

    render() {
        let {
            showConfirmation,
            FirstName,
            LastName,
            Email,
            PhoneNumber,
            PhoneExtension,
            CompanyName,
            CompanyWebsite,
            JobTitle,
            SolutionDescription,
            Error_FirstName,
            Error_LastName,
            Error_Email,
            Error_PhoneNumber,
            Error_PhoneExtension,
            Error_CompanyName,
            Error_CompanyWebsite,
            Error_JobTitle,
            Error_RefType,
            Error_Description,
        } = this.state;

        return (
            <div className="contentContainer">
                <MetaTags>
                      <title>Partner with Paycor</title>
                                
                    <meta
                        name="description"
                        content="We’re committed to better serving our clients by connecting our offerings with other solution providers. Together we can create unified, market leading alliances that expand our reach and provide new value for our customers."
                    />
                          
                </MetaTags>
                       
                <div className="row">
                    <div className="col-sm-5 col-md-5">
                        <div className="partnerHeader">
                            Why partner with us?
                        </div>
                        <div className="partnertext">
                            Paycor is committed to better serving our customers
                            by connecting our products and services with other
                            solution providers. The result of this approach is
                            to create unified, market leading alliances that
                            help expand our reach and provide new capabilities
                            and value for our customers.
                        </div>
                    </div>

                    <div className="col-sm-7 col-md-7">
                        <div className="contactUsHeader">
                            Partnership Request
                        </div>

                        <div
                            id="pnl1"
                            className={
                                showConfirmation
                                    ? "contactUsHideErrorPanel"
                                    : ""
                            }
                        >
                            <div>
                                <div className="contactUsHeaderText">
                                    If you are interested in working with us,
                                    please fill out this form and
                                    <b>
                                        {" "}
                                        someone from the team will be in touch
                                    </b>{" "}
                                    as soon as possible.
                                </div>
                                <div className="contactUsHeaderTextRequired">
                                    All fields are required, unless marked
                                    optional.
                                </div>
                            </div>
                        </div>

                        {this.renderConfirmation()}
                        {/* Contact Us Form */}
                        <div
                            className={
                                showConfirmation
                                    ? "contactUsHideErrorPanel"
                                    : "contactUsSignupColumn"
                            }
                        >
                            {/* First and Last Name */}
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <InputField
                                        id="txtFirstName"
                                        value={FirstName}
                                        label={"First Name"}
                                        className={"contactInput1"}
                                        characterLimit={50}
                                        inputMask={InputMasks.Name}
                                        errorMessage={Error_FirstName}
                                        onChange={(value) => {
                                            this.setState({ FirstName: value });
                                        }}
                                    />
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <InputField
                                        id="txtLastName"
                                        value={LastName}
                                        label={"Last Name"}
                                        className={"contactInput1"}
                                        characterLimit={50}
                                        inputMask={InputMasks.Name}
                                        errorMessage={Error_LastName}
                                        onChange={(value) => {
                                            this.setState({ LastName: value });
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Email */}
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <InputField
                                        id="txtEmail"
                                        value={Email}
                                        label={"Email"}
                                        className={"contactInput2"}
                                        characterLimit={254}
                                        inputMask={InputMasks.Email}
                                        errorMessage={Error_Email}
                                        onChange={(value) => {
                                            this.setState({ Email: value });
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Phone Number */}
                            <div className="row">
                                <div className="col-xs-8 col-sm-8 col-md-6">
                                    <InputField
                                        id="txtPhoneNumber"
                                        value={PhoneNumber}
                                        label={"Phone Number"}
                                        className={"contactPhone"}
                                        characterLimit={10}
                                        inputMask={InputMasks.PhoneShort}
                                        errorMessage={Error_PhoneNumber}
                                        onChange={(value) => {
                                            this.setState({
                                                PhoneNumber: value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-6">
                                    <InputField
                                        id="txtPhoneNumberExt"
                                        value={PhoneExtension}
                                        label={"EXT (optional)"}
                                        characterLimit={4}
                                        errorMessage={Error_PhoneExtension}
                                        onChange={(value) => {
                                            this.setState({
                                                PhoneExtension: value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Company Name and Website */}
                            <div className="row">
                                <div className="col-sm-12  col-md-6">
                                    <InputField
                                        id="txtCompanyName"
                                        value={CompanyName}
                                        label={"Company Name"}
                                        className={"contactInput1"}
                                        characterLimit={255}
                                        errorMessage={Error_CompanyName}
                                        onChange={(value) => {
                                            this.setState({
                                                CompanyName: value,
                                            });
                                        }}
                                    />
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <InputField
                                        id="txtCompanyWebsite"
                                        value={CompanyWebsite}
                                        label={"Company Website"}
                                        inputMask={InputMasks.Website}
                                        className={"contactInput1"}
                                        characterLimit={255}
                                        errorMessage={Error_CompanyWebsite}
                                        onChange={(value) => {
                                            this.setState({
                                                CompanyWebsite: value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            {/* Job Title */}
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <InputField
                                        id="txtJobTitle"
                                        value={JobTitle}
                                        label={"Job Title"}
                                        className={"contactInput2"}
                                        characterLimit={255}
                                        errorMessage={Error_JobTitle}
                                        onChange={(value) => {
                                            this.setState({ JobTitle: value });
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Describe Solution */}

                            <div className="row">
                                <div className="col-sm-12 pseudo-col-select">
                                    <TextField
                                        id="txtDescribe"
                                        value={SolutionDescription}
                                        label={
                                            "Tell us about your app or service and why we should work together."
                                        }
                                        height="207px"
                                        characterLimit={1000}
                                        errorMessage={Error_Description}
                                        onChange={(value) => {
                                            this.setState({
                                                SolutionDescription: value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            {/* Paycor referral */}
                            <div className="row">
                                <div className="col-sm-12 col-md-9">
                                    <Select
                                        options={this.getmktsource()}
                                        id={"mktsource"}
                                        placeholder="Please Select an Option"
                                        label="How did you hear about Paycor?"
                                        name="mktsource"
                                        errorMessage={Error_RefType}
                                        onChange={this.handleRefTypeChange}
                                        selected={{
                                            id: this.state.ReferenceType,
                                            value: this.state.ReferenceType,
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Submit Button */}

                            <div className="row col-sm-12 col-md-12 topSpacer">
                                <Button
                                    aria-label="primary button"
                                    busy={false}
                                    disabled={false}
                                    type="primary"
                                    value="Submit"
                                    onClick={(e) => this.contactSubmit(e)}
                                    className="submitButton"
                                    show={showConfirmation}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactUs;
