import React, { Component } from "react";
import Rating from "react-rating";
import { connect } from "react-redux";
import Button from "@paycor/picl/button";
import { TextField } from "@paycor/picl/form-elements";
import { StarFilled, StarOutline } from "@paycor/picl/icon";
import Banner from "@paycor/picl/banner";
import { HOST } from "../../config/environment";
import { getUserProfile } from "../../store/root-reducer";
import { FetchWrapper } from "../../Util/FetchWrapper";

class FeedbackTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rating: 0,
            feedback: "",
            busy: false,
            isError: false,
            showConfirmation: false,
            productId: props.productId,
            ratingError: "",
            feedbackError: "",
            isValid: true,
        };

        this.onRatingChanged = this.onRatingChanged.bind(this);
        this.handleSubmitReviewClick = this.handleSubmitReviewClick.bind(this);
    }

    onRatingChanged(rating) {
        this.setState({ rating: rating });
    }

    handleSignInClick() {
        var returnUrl = escape(window.location.href + "?feedback=true");
        let signinurl = `${HOST.ENDPOINTS.SIGNIN}?ReturnUrl=${returnUrl}`;

        window.location = signinurl;
    }

    handleSubmitReviewClick() {
        const { rating, feedback } = this.state;
        let ratingError = "";
        let feedbackError = "";

        if (rating < 1) {
            ratingError = "A rating is required";
        }
        if (!feedback.trim()) {
            feedbackError = "Review comments are required";
        }

        if (ratingError || feedbackError) {
            this.setState({
                isValid: false,
                ratingError: ratingError,
                feedbackError: feedbackError,
            });
        } else {
            this.setState(
                {
                    isValid: true,
                    ratingError: "",
                    feedbackError: "",
                },
                () => this.saveForm()
            );
        }
    }

    saveForm() {
        const { id } = this.props.product;
        const { rating, feedback } = this.state;

        const payload = {
            productId: id,
            rating: rating,
            comment: feedback,
        };

        let url = HOST.ENDPOINTS.MP_FEEDBACK;
        let marketplaceApi = new FetchWrapper(url, payload);

        this.setState({
            busy: true,
        });
        marketplaceApi.post(
            () => {
                console.log("Submission sent successfully.");
                this.setState({
                    busy: false,
                    showConfirmation: true,
                });
            },
            () => {
                this.setState({
                    busy: false,
                    isError: true,
                    showConfirmation: true,
                });
                console.log(
                    "No internet connection found. App is running in offline mode."
                );
            }
        );
    }

    renderHeading() {
        const { companyName } = this.props.product;
        return (
            <React.Fragment>
                <h4 className="feedbackHeading">
                    Reviews for {companyName} and Paycor
                </h4>
                <div
                    style={{
                        fontStyle: "italic",
                        color: "#303640",
                        marginTop: "17px",
                        fontWeight: "300",
                    }}
                >
                    No reviews
                </div>
            </React.Fragment>
        );
    }

    renderFeedbackForm() {
        const { companyName } = this.props.product;
        const { busy, isValid, ratingError, feedbackError } = this.state;
        return (
            <React.Fragment>
                <Banner
                    id={"validationRequired"}
                    message={
                        "Missing required field(s). Please complete the fields below."
                    }
                    status={Banner.Status.ERROR}
                    show={!isValid}
                />
                <div
                    style={{
                        fontSize: "18px",
                        marginTop: "23px",
                        fontWeight: "300",
                    }}
                >
                    Leave a Review
                </div>
                <p style={{ fontSize: "14px", marginTop: "5px" }}>
                    * Required fields
                </p>
                <div
                    style={{
                        fontWeight: "300",
                        color: "#303640",
                        fontSize: "16px",
                        marginTop: "19px",
                    }}
                >
                    Rate your experience with {companyName} and Paycor *
                </div>
                <div style={{ marginTop: "4px" }}>
                    <Rating
                        id="ratingId"
                        onChange={this.onRatingChanged}
                        initialRating={this.state.rating}
                        emptySymbol={
                            <StarOutline size={"25px"} color={"#6a7280"} />
                        }
                        fullSymbol={
                            <StarFilled size={"25px"} color={"#f37321"} />
                        }
                    />
                </div>
                {ratingError ? (
                    <div
                        style={{
                            color: "#d81118",
                            fontSize: "16px",
                            fontWeight: "normal",
                        }}
                    >
                        {ratingError}
                    </div>
                ) : (
                    ""
                )}
                <div style={{ marginTop: "6px" }}>
                    <TextField
                        value={""}
                        label="Tell us how we are doing. *"
                        onBlur={(currentValue) =>
                            this.setState({ feedback: currentValue })
                        }
                        characterLimit={400}
                        placeholder="Please add any comments..."
                        errorMessage={feedbackError}
                        height="200px"
                    />
                </div>
                <div style={{ marginTop: "8px" }}>
                    <Button
                        className="reviewButton"
                        id="leaveFeedback"
                        aria-label="primary button"
                        busy={busy}
                        busyLabel="Saving..."
                        disabled={false}
                        type="primary"
                        onClick={this.handleSubmitReviewClick}
                    >
                        Submit Review
                    </Button>
                </div>
            </React.Fragment>
        );
    }

    renderConfirmation() {
        const { isError } = this.state;
        let id = isError ? "msgError" : "msgSuccess";
        let message = isError
            ? "Sorry, we can't submit your review right now. Please wait a bit and try again."
            : "Success! Your feedback was submitted. Thanks for taking the time to tell us what you think.";
        let status = isError ? Banner.Status.ERROR : Banner.Status.SUCCESS;

        return (
            <div className="bannerContainer">
                <Banner id={id} message={message} status={status} show={true} />
            </div>
        );
    }

    render() {
        const isLoggedIn = !!this.props.userProfile.userId;
        const { showConfirmation } = this.state;

        return isLoggedIn ? (
            <React.Fragment>
                <div className="tab_padding" style={{ marginBottom: "128px" }}>
                    {this.renderHeading()}
                    <hr style={{ borderTop: "1px solid #bec3cc" }} />
                    {showConfirmation
                        ? this.renderConfirmation()
                        : this.renderFeedbackForm()}
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="tab_padding" style={{ marginBottom: "128px" }}>
                    {this.renderHeading()}
                    <div style={{ marginTop: "20px" }}>
                        <Button
                            id="signIn"
                            aria-label="primary button"
                            busy={false}
                            disabled={false}
                            type="primary"
                            className="signinButton"
                            onClick={this.handleSignInClick}
                        >
                            Sign In to Leave a Review
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userProfile: getUserProfile(state),
});
export default connect(mapStateToProps)(FeedbackTab);
