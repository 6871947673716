import React, { Component } from "react";
import { HOST } from "../../config/environment";
import { fixImageUri } from "../../Util/Helper";
import { FetchWrapper } from "../../Util/FetchWrapper";
import {
    InputMasks,
    validateEmail,
    validatePhone,
    validateRequired,
    validateRegularExpression,
    ValidationPatterns,
} from "../../Util/validators";
import Button from "@paycor/picl/button";
import ProductTabs from "./tabs";
import Modal from "@paycor/picl/modal";
import { InputField, TextField, Select } from "@paycor/picl/form-elements";
import ToolTip from "@paycor/picl/tooltip";
import Banner from "@paycor/picl/banner";
import { Info, CheckCircle } from "@paycor/picl/icon";
import { connect } from "react-redux";
import { getUserProfile } from "../../store/root-reducer";
import MetaTags from "react-meta-tags";

class ProductSummary extends Component {
    constructor(props) {
        super(props);

        let isRedirect = window.location.toString().includes("modal=true");

        this.state = {
            userProfile: null,
            product: null,
            show: isRedirect,
            showConfirmation: false,
            isValidForm: true,
            isBadRequest: false,
            errorMessage: "",
            validationError: "",
            FullName: "",
            Email: "",
            PhoneNumber: "",
            Extension: "",
            ZipCode: "",
            AppName: "",
            Partner: "",
            ClientId: "",
            CompanyName: "",
            NumberOfEmployees: "",
            Industry: "",
            Comments: "",
            clients: [],
            PartnerEmail: "",
            disabledDropdown: false,
            selectedClient: "",
            selectedClientValue: "",
            Error_FullName: "",
            Error_PhoneNumber: "",
            Error_ZipCode: "",
            Error_CompanyName: "",
            Error_ClientId: "",
            Error_NumberOfEmployees: "",
        };

        this.onClick = this.onClick.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
        this.Salutation = this.Salutation.bind(this);
        this.PopulateClientsDropdown = this.PopulateClientsDropdown.bind(this);
        this.handleClientIdChange = this.handleClientIdChange.bind(this);
    }

    Salutation(c) {
        if (["a", "e", "i", "o", "u"].indexOf(c.toLowerCase()) !== -1)
            return " an";
        else {
            return " a";
        }
    }

    PopulateClientsDropdown(clientsList) {
        var newClientList = clientsList.map((client) => {
            return {
                id: client.clientId,
                label: client.clientId + " - " + client.name,
                value: client.clientId + " - " + client.name,
            };
        });

        return newClientList;
    }

    getUserData() {
        let { userProfile } = this.props;

        let fullName = "";
        let email = "";
        let phoneNumber = "";
        let clients = [];

        // New API Call
        if (userProfile) {
            fullName = userProfile.firstName;
            let lastName = userProfile.lastName;
            if (fullName && lastName) {
                fullName += " " + lastName;
            }
            if (userProfile.email) email = userProfile.email;
            if (userProfile.clients) clients = userProfile.clients;

            if (userProfile.phoneNumbers) {
                if (userProfile.phoneNumbers.length) {
                    phoneNumber = userProfile.phoneNumbers[0];
                }
            }

            let userData = {
                fullName: fullName,
                email: email,
                clients: clients,
                phoneNumber: phoneNumber,
                userLoggedIn: userProfile.isAuthenticated,
            };

            return userData;
        }
    }

    onClick() {
        let userData = this.getUserData();

        this.setState({
            show: true,
            errorMessage: "",
            FullName: userData.fullName,
            Email: userData.email,
            PhoneNumber: userData.phoneNumber,
            Extension: "",
            ZipCode: "",
            AppName: "",
            Partner: "",
            clients: userData.clients,
            // ClientId: 0,
            CompanyName: "",
            NumberOfEmployees: "",
            Industry: "",
            Comments: "",
            PartnerEmail: "",
            Error_FullName: "",
            Error_PhoneNumber: "",
            Error_ZipCode: "",
            Error_CompanyName: "",
            Error_ClientId: "",
            Error_NumberOfEmployees: "",
            Error_PhoneExtension: "",
            isValidForm: true,
            isNetworkError: false,
            userLoggedIn: userData.userLoggedIn,
        });
    }

    onClickOk() {
        this.setState({
            show: false,
            showConfirmation: false,
        });
    }

    onClickCancel() {
        this.setState({
            show: false,
        });
    }

    componentDidUpdate(prevProps) {
        let prevId = "";

        if (prevProps.userProfile) prevId = prevProps.userProfile.userId;

        const { userProfile } = this.props;

        if (
            userProfile &&
            userProfile.userId &&
            userProfile.userId !== prevId
        ) {
            let userData = this.getUserData();
            if (userData) {
                this.setState({
                    FullName: userData.fullName,
                    Email: userData.email,
                    clients: userData.clients,
                    PhoneNumber: userData.phoneNumber,
                    userLoggedIn: userData.userLoggedIn,
                });
            }
        }
    }

    componentDidMount() {
        const { id } = this.props.productid;

        let url = `${HOST.ENDPOINTS.MP_PRODUCTS}/${id}`;
        let marketPlaceApi = new FetchWrapper(url);
        marketPlaceApi.get((product) => {
            this.setState({ product });
        });

        if (this.props.userProfile) {
            let userData = this.getUserData();
            if (userData) {
                this.setState({
                    FullName: userData.fullName,
                    Email: userData.email,
                    PhoneNumber: userData.phoneNumber,
                    clients: userData.clients,
                    userLoggedIn: userData.userLoggedIn,
                });
            }
        }
    }

    handleSignInClick() {
        var returnUrl = escape(window.location.href + "?modal=true");
        let signinurl = `${HOST.ENDPOINTS.SIGNIN}?ReturnUrl=${returnUrl}`;

        window.location = signinurl;
    }

    onClickSubmit() {
        const {
            FullName,
            Email,
            PhoneNumber,
            Extension,
            ZipCode,
            product,
            CompanyName,
            ClientId,
            NumberOfEmployees,
            Industry,
            Comments,
            userLoggedIn,
        } = this.state;

        const errors = {};

        errors.FullName = validateRequired(FullName, "Full name is required.");
        errors.Email = validateEmail(
            Email,
            "Email is invalid. Use format name@company.com.",
            "Email is required."
        );
        errors.PhoneNumber = validatePhone(
            PhoneNumber,
            "Phone is invalid, it must be 10 digits.",
            "Phone number is required."
        );
        errors.ZipCode = validateRegularExpression(
            ZipCode,
            ValidationPatterns.ZipCode,
            "Must be 5 digits.",
            "Zip code is required."
        );
        errors.PhoneExtension = validateRegularExpression(
            Extension,
            ValidationPatterns.PhoneExtension,
            "Must be 4 digits or less."
        );
        errors.NumberOfEmployees = validateRequired(
            NumberOfEmployees,
            "Number of employees is required."
        );
        errors.CompanyName = validateRequired(
            CompanyName,
            "Company name is required."
        );

        if (userLoggedIn) {
            errors.ClientId = validateRequired(
                `${ClientId}`,
                "Must select ONE clientID."
            );
        } else {
            errors.ClientId = "";
        }

        const error = Boolean(
            errors.FullName.concat(
                errors.Email,
                errors.PhoneNumber,
                errors.ZipCode,
                errors.NumberOfEmployees,
                errors.CompanyName,
                errors.ClientId,
                errors.PhoneExtension
            )
        );

        this.setState({
            isValidForm: !error,
            errorMessage: errors.Email,
            Error_FullName: errors.FullName,
            Error_PhoneNumber: errors.PhoneNumber,
            Error_ZipCode: errors.ZipCode,
            Error_CompanyName: errors.CompanyName,
            Error_NumberOfEmployees: errors.NumberOfEmployees,
            Error_ClientId: errors.ClientId,
            Error_PhoneExtension: errors.PhoneExtension,
        });

        if (error) {
            window.scrollTo(0, 0);
            return;
        }

        //call backend on get started
        let getStartedData = {
            fullName: FullName,
            email: Email,
            phoneNumber: PhoneNumber,
            extension: Extension,
            zipCode: ZipCode,
            appName: product.name,
            partner: product.companyName,
            clientId: ClientId,
            companyName: CompanyName,
            numberOfEmployees: NumberOfEmployees,
            relationshipType: product.relationshipType,
            industry: Industry,
            comments: Comments,
            appId: product.id,
        };

        let url = HOST.ENDPOINTS.MP_GETSTARTED;
        let marketplaceApi = new FetchWrapper(url, getStartedData);

        marketplaceApi.post(
            () => {
                console.log("Submission sent successfully.");
                this.setState({
                    show: false,
                    showConfirmation: true,
                    isValidForm: true,
                });
            },
            (error) => {
                window.scrollTo(0, 0);
                if (error.status === 400) {
                    console.log("Bad Request Error:", error);
                    error.json().then((msg) => {
                        this.setState({
                            isBadRequest: true,
                            validationError: Object.values(msg.source),
                        });
                    });
                    return;
                }

                console.warn("An http Error occurred:", error);
                this.setState({
                    isNetworkError: true,
                    show: true,
                    showConfirmation: false,
                });
            }
        );
    }
	
    handleClientIdChange(selectedOption) {
        this.setState({ ClientId : selectedOption.id });
    }

    render() {
        let { product } = this.state;

        if (product) {
            return (
                <div className="row">
                    <MetaTags>
                          <title>Marketplace - {product.name}</title>
                                    
                        <meta
                            name="description"
                            content={product.description}
                        />
                    </MetaTags>
                    <div id="productDetails" className="col-12">
                        <div className="row mobileCard">
                            <div
                                className="col-xs-12 visible-xs detailProduct"
                                style={{
                                    marginTop: "24px",
                                    marginBottom: "18px",
                                }}
                            >
                                {product.name}
                            </div>
                            <div className="col-sm-3 col-md-3 mobileCardImg">
                                <img
                                    id="partnerDetailImage"
                                    className="partnerDetailImage "
                                    alt={product.name}
                                    src={fixImageUri(
                                        product.image.portraitThumbnailUrl
                                    )}
                                />
                            </div>
                            <div className="col-sm-6 col-md-6 mobileCardDetail">
                                <div className="detailProduct hidden-xs">
                                    {product.name}
                                </div>
                                <div className="detailVendor">
                                    {product.companyName}
                                </div>
                                <div className="detailCategory">
                                    {product.categoryName}
                                </div>
                                <div className="detailDescription hidden-xs">
                                    {product.description}
                                </div>
                            </div>
                            <div className="col-xs-12 visible-xs detailDescription">
                                {product.description}
                            </div>
                            <div
                                className="col-sm-3 col-md-3 col-xs-12"
                                style={{ margin: "16px 0px" }}
                            >
                                {this.renderDetails()}
                            </div>
                        </div>
                        <ProductTabs
                            productdetails={product.productDetails}
                            product={product}
                        />
                    </div>
                    {this.renderModals()}
                </div>
            );
        }
        return "";
    }

    setConfirmation(status, message) {
        return (
            <Banner
                id="divNetworkError"
                status={status}
                show={true}
                message={message}
            />
        );
    }

    renderModals() {
        let {
            product,
            show,
            isValidForm,
            isNetworkError,
            isBadRequest,
            FullName,
            Error_FullName,
            Email,
            errorMessage,
            validationError,
            PhoneNumber,
            Error_PhoneNumber,
            Error_PhoneExtension,
            Extension,
            ZipCode,
            Error_ZipCode,
            CompanyName,
            Error_CompanyName,
            Error_ClientId,
            NumberOfEmployees,
            Error_NumberOfEmployees,
            Industry,
            Comments,
            clients,
            userLoggedIn,
        } = this.state;
        let options = this.PopulateClientsDropdown(clients);
        let confirmHtml = "";

        if (!isValidForm) {
            confirmHtml = this.setConfirmation(
                Banner.Status.ERROR,
                "Missing required field(s). Please complete the fields below."
            );
        } else if (isBadRequest) {
            confirmHtml = this.setConfirmation(
                Banner.Status.ERROR,
                validationError
            );
        } else if (isNetworkError) {
            confirmHtml = this.setConfirmation(
                Banner.Status.ERROR,
                "Sorry, we can't submit your information right now. Please wait a bit and try again."
            );
        }

        return (
            <React.Fragment>
                <Modal
                    show={show}
                    showClose={true}
                    size=""
                    title="Contact Representative"
                    body={
                        <div className="modalcontactRep">
                            {confirmHtml}
                            <div
                                id="loggedin"
                                className={userLoggedIn ? "" : "modalHidden"}
                            >
                                Fill out the form below and{" "}
                                {this.Salutation(product.companyName.charAt(0))}{" "}
                                {product.companyName} representative will be in
                                touch soon.
                            </div>
                            <div
                                id="notloggedin"
                                className={userLoggedIn ? "modalHidden" : ""}
                            >
                                <div className="modalSigninMessage">
                                    Sign in to speed up the process or fill out
                                    the form below and
                                    {this.Salutation(
                                        product.companyName.charAt(0)
                                    )}{" "}
                                    {product.companyName} representative will be
                                    in touch soon.
                                </div>
                                <div className="signincontainer">
                                    <Button
                                        id="btnModalSignIn"
                                        className="signinbutton"
                                        aria-label="primary button"
                                        busy={false}
                                        disabled={false}
                                        type={Button.Types.PRIMARY}
                                        onClick={() => this.handleSignInClick()}
                                        children="Sign In with Paycor"
                                    />
                                </div>
                            </div>
                            <div className="modalwarning">
                                {" "}
                                All fields are required unless marked optional.
                            </div>
                            {/* First and Last Name */}
                            <InputField
                                id="txtFullName"
                                value={FullName}
                                label={"Full Name"}
                                className="modalTextbox1"
                                characterLimit={100}
                                inputMask={InputMasks.Name}
                                errorMessage={Error_FullName}
                                onChange={(value) => {
                                    this.setState({ FullName: value });
                                }}
                            />
                            <InputField
                                id="txtEmail"
                                value={Email}
                                label={"Email Address"}
                                className="modalTextbox1"
                                characterLimit={254}
                                inputMask={InputMasks.Email}
                                errorMessage={errorMessage}
                                onChange={(value) => {
                                    this.setState({ Email: value });
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <InputField
                                    id="txtPhoneNumber"
                                    value={PhoneNumber}
                                    label={"Phone Number"}
                                    className="modalTextbox2"
                                    inputMask={InputMasks.Phone}
                                    characterLimit={15}
                                    errorMessage={Error_PhoneNumber}
                                    onChange={(value) => {
                                        this.setState({ PhoneNumber: value });
                                    }}
                                />
                                <InputField
                                    id="txtExt"
                                    value={Extension}
                                    label={"EXT (optional)"}
                                    className="modalTextbox4"
                                    characterLimit={4}
                                    inputMask={InputMasks.PhoneExtension}
                                    errorMessage={Error_PhoneExtension}
                                    onChange={(value) => {
                                        this.setState({ Extension: value });
                                    }}
                                />
                            </div>
                            <div>
                                <InputField
                                    id="txtZip"
                                    value={ZipCode}
                                    label={"Zip Code"}
                                    className="modalTextbox3"
                                    characterLimit={10}
                                    inputMask={InputMasks.ZipCode}
                                    errorMessage={Error_ZipCode}
                                    onChange={(value) => {
                                        this.setState({ ZipCode: value });
                                    }}
                                />
                            </div>
                            <div className={userLoggedIn ? "" : "modalHidden"}>
                                <div className="divWithTooltip">
                                    <label className="labelWithTooltip">
                                        Client
                                    </label>
                                    <ToolTip
                                        position={ToolTip.Positions.RIGHT}
                                        persistent={true}
                                    >
                                        <ToolTip.Anchor>
                                            <Info
                                                color="#005f8c"
                                                size={"15px"}
                                            />
                                        </ToolTip.Anchor>
                                        <ToolTip.Body>
                                            <div
                                                className={"tooltipText"}
                                                id={"PartnerNameTooltipText"}
                                            >
                                                Do you want this app or service
                                                for multiple clients? Share
                                                additional Client Ids in the
                                                comment section.
                                            </div>
                                        </ToolTip.Body>
                                    </ToolTip>
                                </div>

                                <Select
                                    options={options}
                                    id="txtClient"
                                    className="modalTextbox1"
                                    errorMessage={Error_ClientId}
                                    onChange={this.handleClientIdChange}
                                    placeholder={"Select a value"}
                                ></Select>
                            </div>
                            <InputField
                                id="txtCompanyName"
                                value={CompanyName}
                                label={"Company Name"}
                                className="modalTextbox1"
                                characterLimit={255}
                                // inputMask={InputMasks.Words}
                                errorMessage={Error_CompanyName}
                                onChange={(value) => {
                                    this.setState({ CompanyName: value });
                                }}
                            />
                            <div>
                                <InputField
                                    id="txtNumEmp"
                                    value={NumberOfEmployees}
                                    label={"Number of Employees"}
                                    className="modalTextbox5"
                                    characterLimit={9}
                                    inputMask={InputMasks.Number}
                                    errorMessage={Error_NumberOfEmployees}
                                    onChange={(value) => {
                                        this.setState({
                                            NumberOfEmployees: value,
                                        });
                                    }}
                                />
                            </div>
                            <InputField
                                id="txtIndustry"
                                value={Industry}
                                label={"Industry (optional)"}
                                className="modalTextbox1"
                                characterLimit={50}
                                inputMask={InputMasks.Words}
                                onChange={(value) => {
                                    this.setState({ Industry: value });
                                }}
                            />
                            <TextField
                                id="txtComments"
                                value={Comments}
                                label={"Comments (optional)"}
                                className="modalTextbox1"
                                height="100"
                                maxHeight="100"
                                characterLimit={300}
                                onChange={(value) => {
                                    this.setState({ Comments: value });
                                }}
                            />
                        </div>
                    }
                    footer={
                        <div className="modalFooterButtons">
                            <Button
                                aria-label="secondary button"
                                busy={false}
                                disabled={false}
                                type={Button.Types.SECONDARY}
                                onClick={this.onClickCancel}
                                children="Cancel"
                                className="modalButtons hidden-xs"
                            />
                            <Button
                                aria-label="primary button"
                                busy={false}
                                disabled={false}
                                type="primary"
                                onClick={this.onClickSubmit}
                                children="Submit"
                                className="modalButtons"
                            />
                        </div>
                    }
                    backdrop={true}
                    footerStyle={Modal.Footer.DARK}
                    onClose={(closeVal) => this.setState({ show: closeVal })}
                />

                <Modal
                    show={this.state.showConfirmation}
                    showClose={true}
                    size=""
                    title="Success!"
                    backdrop={true}
                    footerStyle={Modal.Footer.DARK}
                    onClose={(closeVal) =>
                        this.setState({ showConfirmation: closeVal })
                    }
                    body={
                        <div className="modalConfirmation">
                            <span style={{ paddingRight: "10px" }}>
                                <CheckCircle
                                    size="19px"
                                    className="icon"
                                    color={"#69b545"}
                                />
                            </span>
                            Your information was sent to a&nbsp;{" "}
                            {product.companyName} representative. Someone will
                            be in touch soon!
                        </div>
                    }
                    footer={
                        <div className="modalFooterButtons">
                            <Button
                                aria-label="primary button"
                                busy={false}
                                disabled={false}
                                type="primary"
                                onClick={this.onClickOk}
                                children="OK"
                                className="modalButtons"
                            />
                        </div>
                    }
                />
            </React.Fragment>
        );
    }
    renderDetails() {
        /*let product = this.state.product;
    let integrationDetailsHtml = "";
    let types = {
      "Paycor Product": (
        <Fragment>
          With one single source of truth for all employee data, you’ll never
          have to switch platforms, log-in to multiple systems, re-key
          information or open numerous spreadsheets.
          <br />
          <br />
          Your everyday processes become simplified, allowing you and your
          organizations to focus on your most important work.
        </Fragment>
      ),
      Complete: (
        <Fragment>
          Complete Integration is an automated integration platform between
          Paycor and third-party partners. It provides file-based data standards
          that enable Paycor’s partners to send employee deductions and earnings
          directly to Paycor.
          <br />
          <br />
          This completes the transmission circle of employee demographic and
          census data to the provider from payroll, and employee deduction and
          earning data from the provider to payroll.
        </Fragment>
      ),
      "File Based": (
        <Fragment>
          With this service, Paycor prepares basic employee census information
          and creates a data file to be downloaded via online reporting. Data is
          then directly forwarded to the third-party provider. The file is
          pre-formatted to the third-party providers’ specifications.
        </Fragment>
      )
    };

    if (
      product.integrationDetailsHtml &&
      product.integrationDetailsHtml !== ""
    ) {
      integrationDetailsHtml = product.integrationDetailsHtml;
    } else {
      if (
        product.integrationType !== null &&
        product.integrationType !== undefined
      ) {
        integrationDetailsHtml = types[product.integrationType].props.children;
      } else {
        integrationDetailsHtml = "";
      }
    }*/

        return (
            <React.Fragment>
                <div className="GetStarted">
                    <Button
                        aria-label="primary button"
                        busy={false}
                        disabled={false}
                        type={Button.Types.SAVE}
                        onClick={this.onClick}
                        children="Contact Representative"
                        className="contactRepButton"
                    />
                </div>
                {/*
        <div id="sectionIntegrationType" className="IntegrationTypeSection">
          <div className="TypeName">Integration Details</div>
           <div className="TypeDescription">{integrationDetailsHtml}</div> 
        </div>
         */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userProfile: getUserProfile(state),
});

export default connect(mapStateToProps)(ProductSummary);
