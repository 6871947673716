import React from "react";
import PropTypes from "prop-types";
import { Modal } from "paycor-react-bootstrap";

export default class ConfirmModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    size: PropTypes.string,
    title: PropTypes.string.isRequired,
    actionLabel: PropTypes.string,
    actionLabelIcon: PropTypes.string,
    backdropClassName: PropTypes.string,
    cancelLabel: PropTypes.string,
    modalClassName: PropTypes.string,
    onActionClicked: PropTypes.func.isRequired,
    onCancelClicked: PropTypes.func.isRequired,
    isActionDisabled: PropTypes.bool,
    showButtonIcons: PropTypes.bool,
    hideHeaderCloseButton: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      hideHeaderCloseButton:
        props.hideHeaderCloseButton && props.hideHeaderCloseButton === true
    };
  }

  componentWillMount = () => {
    if (
      this.props.isActionDisabled !== null &&
      this.props.isActionDisabled !== undefined
    ) {
      this.setState({ disabled: this.props.isActionDisabled });
    }
  };

  componentWillUnmount = () => {
    this.setState({ disabled: false });
  };

  actionClicked = callback => e => {
    callback();
  };

  render() {
    let actionLabelIcon =
      this.props.actionLabelIcon !== undefined &&
      this.props.actionLabelIcon !== "" ? (
        <span className="icon-checkmark" />
      ) : null;

    let actionClass = `btn btn-primary ${
      this.props.actionLabelIcon !== undefined &&
      this.props.actionLabelIcon !== ""
        ? "btn-action"
        : ""
    }`;

    return (
      <Modal
        show={this.props.show}
        bsSize={this.props.size || "md"}
        dialogClassName={this.props.modalClassName}
        backdropClassName={this.props.backdropClassName}
      >
        <Modal.Header
          onHide={this.props.onCancelClicked}
          closeButton={!this.props.hideHeaderCloseButton}
        >
          <h4 className="modalTitle">{this.props.title}</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="modalContent">{this.props.children}</div>
        </Modal.Body>
        <Modal.Footer className="modal-footer modal-button-bar">
          <button className="btn btn-link" onClick={this.props.onCancelClicked}>
            {this.props.cancelLabel || "Cancel"}
          </button>
          <button
            className={actionClass}
            onClick={this.actionClicked(this.props.onActionClicked)}
            disabled={this.state.disabled}
          >
            {actionLabelIcon} {this.props.actionLabel || "OK"}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
