import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Summary from "../components/productdetail/summary";
import BusyIndicator from "../components/common/busyindicator";

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
  }
  
  render() {
    const { isSearching } = this.props;
    let BackLink = <NavLink id="browseback" to="/storefront">&laquo; Back to Browse Apps</NavLink>
    if(isSearching)
      BackLink = <NavLink id="browseback" onClick={this.goBack}>&laquo; Back to Search Results</NavLink>

    return (
      <div className="gutter">
        <BusyIndicator />
        <div className="App MainContent page-row page-row-expanded">
          <div className="BrowseBack hidden-xs">
           {BackLink}
          </div>
          <Summary productid={this.props.match.params} />
        </div>
      </div>
    );
  }
  goBack() {
    window.history.back();
    }
  }

const mapStateToProps = state => {
  const { isSearching } = state.productData;
  return { isSearching };
};

export default withRouter(connect(mapStateToProps)(ProductDetail));
