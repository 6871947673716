import React, { Component } from "react";
import "./banner.scss";
class Banner extends Component {
  render() {
    return (
      <div>
        <div className="SignupBannerImg">
          <div className="MarketplacehomeHeader">Partner with Paycor</div>
          <div className="MarketplacehomeHeader2">We're Invested in You</div>
        </div>
      </div>
    );
  }
}

export default Banner;
