import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleCategory, setProductPage } from "../../store/actions";
import { Checkbox } from "@paycor/picl/form-elements";
import queryString from "query-string";
import { getQueryString } from "../../Util/Helper";

class Category extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            initialState: window.location.href.includes("categories")
                ? true
                : false,
        };
    }

    handleChange(e) {
        const { dispatch, category } = this.props;
        dispatch(toggleCategory(category.id));
        dispatch(setProductPage(0));
    }

    render() {
        let { category, selectedCategories } = this.props;

        const url = getQueryString(window.location.href);
        const values = queryString.parse(url);
        const querystringCategory = values.categories;

        if (
            this.state.initialState &&
            selectedCategories &&
            selectedCategories.length > 0 &&
            category &&
            querystringCategory === category.id
        ) {
            category.status = Checkbox.StatusTypes.CHECKED;
            this.setState({
                initialState: false,
            });
        }

        return (
            <div>
                <Checkbox
                    checkedStatus={category.status}
                    id={category.name}
                    label={category.name}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedCategories } = state.categoryData;
    return { selectedCategories };
};
export default connect(mapStateToProps)(Category);
