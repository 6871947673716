import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { InputField, Select } from "@paycor/picl/form-elements";
import Banner from "@paycor/picl/banner";
import Button from "@paycor/picl/button";
import ToolTip from "@paycor/picl/tooltip";
import { Info } from "@paycor/picl/icon";
import "./PartnerDetail.scss";
import { FetchWrapper } from "../../Util/FetchWrapper";
import {
    InputMasks,
    validateEmail,
    validatePhone,
    validateRequired,
    trimPhoneForSMA,
} from "../../Util/validators";
import { HOST } from "../../config/environment";
import PartnerTypesModal from "./PartnerTypesModal";
import ManageDevelopers from "./ManageDevelopers";

class PartnerDetail extends Component {
    constructor(props) {
        super(props);
        this.state = this.getFormState();
        this.onClickSubmit = this.onClickSubmit.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
    }

    componentDidUpdate(oldProps) {
        const { partner } = this.props;
        if (partner !== oldProps.partner) {
            this.setState(this.getFormState());
        }
    }

    getFormState() {
        const { partner, isNew } = this.props;

        if (partner && !isNew) {
            return {
                PartnerName: partner.name,
                Error_PartnerName: "",
                Status: partner.status,
                Error_Status: "",
                Error_PartnerType: false,
                ContactFirstName: partner.firstName,
                Error_ContactFirstName: "",
                ContactLastName: partner.lastName,
                Error_ContactLastName: "",
                ContactEmail: partner.emailAddress,
                Error_ContactEmail: "",
                ContactPhone: partner.phoneNumber,
                Error_ContactPhone: "",
                is_Error: false,
                isNetworkError: false,
                SubmitErrorMessage:
                    "Sorry, we can't submit your information right now. Please wait a bit and try again.",
                isSubmitted: false,
                isEditMode: !isNew,
            };
        }
        if (!partner) {
            return {
                PartnerName: "",
                Error_PartnerName: "",
                Status: null,
                Error_Status: "",
                Error_PartnerType: false,
                ContactFirstName: "",
                Error_ContactFirstName: "",
                ContactLastName: "",
                Error_ContactLastName: "",
                ContactEmail: "",
                Error_ContactEmail: "",
                ContactPhone: "",
                Error_ContactPhone: "",
                is_Error: false,
                isNetworkError: false,
                SubmitErrorMessage: "",
                isSubmitted: false,
                isEditMode: false,
            };
        }
    }
    onClickCancel() {
        const { history } = this.props;
        const { isEditMode, PartnerName } = this.state;

        if (isEditMode) {
            var redirecturl = `/viewpartnerdetail/${PartnerName}`;
        } else {
            redirecturl = "/managemarketplace";
        }
        history.push(redirecturl);
    }

    onClickSubmit() {
        const { history, partner } = this.props;
        const {
            PartnerName,
            Status,
            ContactFirstName,
            ContactLastName,
            ContactEmail,
            ContactPhone,
            isEditMode,
        } = this.state;

        var selectedTypes = [];
        if (partner && partner.types) {
            selectedTypes =
                partner.types && partner.types.length ? partner.types : [];
        }

        const errors = {};
        errors.PartnerName = validateRequired(
            PartnerName,
            "Partner name is required"
        );
        errors.Status = validateRequired(Status, "Status is required");

        errors.PartnerType = validateRequired(
            selectedTypes.join(", "),
            "PartnerType is required"
        );
        errors.ContactFirstName = validateRequired(
            ContactFirstName,
            "First name is required"
        );
        errors.ContactLastName = validateRequired(
            ContactLastName,
            "Last name is required"
        );
        errors.ContactEmail = validateEmail(
            ContactEmail,
            "Email address is invalid",
            "Email address is required"
        );
        errors.ContactPhone = validatePhone(
            ContactPhone,
            "Phone is invalid, it must be 10 digits",
            "Phone number is required"
        );

        const error = errors.PartnerName.concat(
            errors.Status,
            errors.PartnerType,
            errors.ContactFirstName,
            errors.ContactLastName,
            errors.ContactEmail,
            errors.ContactPhone
        );

        this.setState({
            is_Error: error,
            Error_PartnerName: errors.PartnerName,
            Error_Status: errors.Status,
            Error_PartnerType: Boolean(errors.PartnerType),
            Error_ContactFirstName: errors.ContactFirstName,
            Error_ContactLastName: errors.ContactLastName,
            Error_ContactEmail: errors.ContactEmail,
            Error_ContactPhone: errors.ContactPhone,
        });

        if (!error) {
            //call backend on get started
            let partnerData = {
                name: PartnerName,
                status: Status,
                types: selectedTypes,
                firstName: ContactFirstName,
                lastName: ContactLastName,
                phoneNumber: trimPhoneForSMA(ContactPhone),
                emailAddress: ContactEmail,
            };

            let url = HOST.ENDPOINTS.REG_PARTNERS_ADD;
            let marketplaceApi = new FetchWrapper(url, partnerData);
            if (!isEditMode) {
                marketplaceApi.post(
                    () => {
                        history.push(
                            `/viewpartnerdetail/${PartnerName}?submit=true`
                        );
                    },
                    (error) => {
                        if (error.status === 409)
                            this.setState({
                                isNetworkError: true,
                                showConfirmation: false,
                                is_Error: false,
                                SubmitErrorMessage:
                                    "A partner with this name already exists, please try another name.",
                            });
                        else {
                            this.setState({
                                isNetworkError: true,
                                showConfirmation: false,
                                is_Error: false,
                                SubmitErrorMessage:
                                    "Sorry, we can't submit your information right now. Please wait a bit and try again.",
                            });
                        }
                    }
                );
            } else {
                marketplaceApi.put(
                    () => {
                        history.push(
                            `/viewpartnerdetail/${PartnerName}?submit=true`
                        );
                    },
                    (error) => {
                        if (error.status === 409)
                            this.setState({
                                isNetworkError: true,
                                showConfirmation: false,
                                is_Error: false,
                                SubmitErrorMessage:
                                    "A partner with this name already exists, please try another name.",
                            });
                        else {
                            this.setState({
                                isNetworkError: true,
                                showConfirmation: false,
                                is_Error: false,
                                SubmitErrorMessage:
                                    "Sorry, we can't submit your information right now. Please wait a bit and try again.",
                            });
                        }
                    }
                );
            }
        }

        return error;
    }

    render() {
        const options = [
            { id: "Pending", label: "Pending", value: "Pending" },
            { id: "Active", label: "Active", value: "Active" },
            { id: "Inactive", label: "Inactive", value: "Inactive" },
            { id: "Declined", label: "Declined", value: "Declined" },
        ];
        const {
            isNetworkError,
            SubmitErrorMessage,
            is_Error,
            PartnerName,
            Error_PartnerName,
            isEditMode,
            Error_Status,
            Status,
            Error_PartnerType,
            ContactFirstName,
            Error_ContactFirstName,
            ContactLastName,
            Error_ContactLastName,
            ContactEmail,
            Error_ContactEmail,
            ContactPhone,
            Error_ContactPhone,
        } = this.state;

        const { partner } = this.props;

        let typesDisplay = "";
        if (partner && partner.types) {
            typesDisplay = partner.types.join(", ");
        }

        return (
            <React.Fragment>
                <div className="PartnerDetailContainer">
                    <div className="BrowseBack">
                        <NavLink id="browseback" to="/managemarketplace">
                            &laquo; Back to Partners
                        </NavLink>
                    </div>
                    {isNetworkError ? (
                        <Banner
                            id={"NetworkError"}
                            message={SubmitErrorMessage}
                            status={Banner.Status.ERROR}
                            show={true}
                        />
                    ) : (
                        ""
                    )}
                    {is_Error ? (
                        <Banner
                            id={"DataMissing"}
                            message={
                                "Missing required field(s). Please complete the fields below."
                            }
                            status={Banner.Status.ERROR}
                            show={true}
                        />
                    ) : (
                        ""
                    )}

                    <div className="Header1">
                        {partner ? partner.name : "New Partner"}
                    </div>
                    <div className="fields-required ">
                        All fields are required unless marked optional.
                    </div>
                    <div className="Header2">General Information</div>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className="divWithTooltip">
                                <label className="labelWithTooltip">
                                    Partner Name
                                </label>
                                <ToolTip
                                    position={ToolTip.Positions.RIGHT}
                                    persistent={true}
                                >
                                    <ToolTip.Anchor>
                                        <Info color="#005f8c" size={"15px"} />
                                    </ToolTip.Anchor>
                                    <ToolTip.Body>
                                        <div
                                            className={"tooltipText"}
                                            id={"PartnerNameTooltipText"}
                                        >
                                            <h1>Cannot be changed</h1>
                                            Partner Name is a unique identifier
                                            and cannot be changed once it is
                                            saved.
                                        </div>
                                    </ToolTip.Body>
                                </ToolTip>
                            </div>
                            <InputField
                                id="txtPartnerName"
                                value={PartnerName}
                                label={""}
                                characterLimit={255}
                                inputMask={InputMasks.Name}
                                errorMessage={Error_PartnerName}
                                disabled={isEditMode ? true : false}
                                onChange={(value) => {
                                    this.setState({ PartnerName: value });
                                }}
                            />
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <Select
                                options={options}
                                id="txtStatus"
                                errorMessage={Error_Status}
                                handleChange={(selection) => {
                                    this.setState(
                                        { Status: selection.value },

                                        () => {
                                            console.log(this.state.Status);
                                        }
                                    );
                                }}
                                placeholder={"Please Select an Option"}
                                label="Status"
                                selected={{
                                    label: Status,
                                    value: Status,
                                }}
                            ></Select>
                        </div>
                        <div className="col-xs-12 col-md-12">
                            <div
                                className={
                                    Error_PartnerType
                                        ? "errorlabelText"
                                        : "labeltext"
                                }
                            >
                                Partner Type
                            </div>
                            <div
                                className={
                                    partner && partner.types
                                        ? "labelValue"
                                        : "nolabelValue"
                                }
                            >
                                {typesDisplay}
                            </div>
                            <div
                                className={
                                    partner && partner.types
                                        ? "divHidden"
                                        : "nolabelValue"
                                }
                            >
                                None selected
                            </div>
                            <PartnerTypesModal
                                isEditMode={isEditMode}
                                PartnerType={
                                    partner && partner.types
                                        ? partner.types
                                        : []
                                }
                                partner={partner}
                            />
                        </div>

                        <div className="Header2 col-xs-12 col-md-12">
                            Contact Information
                        </div>
                        <div className="col-xs-12 col-md-12">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div className="col-xs-12 col-md-6">
                                    <InputField
                                        id="txtContactFirstName"
                                        value={ContactFirstName}
                                        label={"First Name"}
                                        characterLimit={50}
                                        inputMask={InputMasks.Name}
                                        errorMessage={Error_ContactFirstName}
                                        onChange={(value) => {
                                            this.setState({
                                                ContactFirstName: value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <InputField
                                        id="txtContactLastName"
                                        value={ContactLastName}
                                        label={"Last Name"}
                                        characterLimit={50}
                                        inputMask={InputMasks.Name}
                                        errorMessage={Error_ContactLastName}
                                        onChange={(value) => {
                                            this.setState({
                                                ContactLastName: value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-12">
                            <div className="col-xs-12 col-md-6">
                                <div className="divWithTooltip">
                                    <label className="labelWithTooltip">
                                        Email
                                    </label>
                                    <ToolTip
                                        position={ToolTip.Positions.RIGHT}
                                        persistent={true}
                                    >
                                        <ToolTip.Anchor>
                                            <Info
                                                color="#005f8c"
                                                size={"15px"}
                                            />
                                        </ToolTip.Anchor>
                                        <ToolTip.Body>
                                            <div
                                                className={"tooltipText"}
                                                id={"EmailTooltipText"}
                                            >
                                                <h1>Cannot be changed</h1>
                                                Email is a unique identifier and
                                                cannot be changed.
                                            </div>
                                        </ToolTip.Body>
                                    </ToolTip>
                                </div>
                                <InputField
                                    id="txtContactEmail"
                                    value={ContactEmail}
                                    label={""}
                                    characterLimit={254}
                                    inputMask={InputMasks.Email}
                                    errorMessage={Error_ContactEmail}
                                    disabled={isEditMode ? true : false}
                                    onChange={(value) => {
                                        this.setState({ ContactEmail: value });
                                    }}
                                />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <InputField
                                    id="txtContactPhonenumber"
                                    value={ContactPhone}
                                    label={"Phone Number"}
                                    characterLimit={15}
                                    inputMask={InputMasks.PhoneShort}
                                    errorMessage={Error_ContactPhone}
                                    onChange={(value) => {
                                        this.setState({ ContactPhone: value });
                                    }}
                                />
                            </div>
                        </div>

                        {partner && typeof partner.name !== "undefined" ? (
                            <div>
                                <ManageDevelopers
                                    partner={partner.name}
                                    status={partner.status}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="FooterButtonsdiv col-md-12">
                        <div className="FooterButtons">
                            <Button
                                aria-label="secondary button"
                                busy={false}
                                disabled={false}
                                type={Button.Types.SECONDARY_ACTION}
                                onClick={this.onClickCancel}
                                children="Cancel"
                                icon={"close"}
                                className="modalButtons_partner"
                            />
                            <Button
                                aria-label="primary button"
                                type={Button.Types.SAVE}
                                busy={false}
                                disabled={false}
                                onClick={this.onClickSubmit}
                                children="Save"
                                icon={"checkmark"}
                                className="modalButtons_partner"
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { partner, isNew } = state.partnerByNameData;
    return { partner, isNew };
};

export default withRouter(connect(mapStateToProps)(PartnerDetail));
