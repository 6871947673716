import { combineReducers } from "redux";
import ROOT_ACTION_TYPES from "./constants";

//#region Legacy reducers
const currentLocationPath = (state = "", action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.SET_CURRENT_LOCATION_PATH:
      return action.currentLocationPath;
    default:
      return state;
  }
};

const isFormDirty = (state = false, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.SET_FORM_DIRTY:
      return action.isFormDirty;
    default:
      return state;
  }
};

export const getCurrentLocationPath = state => state.currentLocationPath;
export const getIsFormDirty = state => state.isFormDirty;
//#endregion

//#region User Information
const userProfile = (state = {}, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.LOAD_USER_META_DATA_RESPONSE:
      return Object.assign({}, state, action.userProfile, {
        isAuthenticated: action.isAuthenticated
      });
    default:
      return state;
  }
};
export const getUserProfile = state => state.userProfile;

//#endregion

//#region ActionIndicator
const _defaultState = {
  message: "",
  show: false
};

const showActionIndicator = (state = _defaultState, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.SHOW_ACTION_INDICATOR:
      return action.showActionIndicator;
    default:
      return state;
  }
};

export const getActionIndicator = state => state.showActionIndicator;
//#endregion

//#region Global Error State reducer

const _defaultErrorState = {
  message: "",
  show: false
};

const showGlobalErrorIndicator = (state = _defaultErrorState, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.SHOW_GLOBAL_ERROR_INDICATOR:
      return Object.assign({}, state, {
        message: action.message,
        show: true
      });
    case ROOT_ACTION_TYPES.CLEAR_GLOBAL_ERROR_INDICATOR:
      return Object.assign({}, state, _defaultErrorState);
    default:
      return state;
  }
};
export const getGlobalErrorIndicator = state => state.showGlobalErrorIndicator;
//#endregion

//#region Security
const showTimeoutModal = (state = false, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.SHOW_SESSION_TIMEOUT_MODAL:
      return action.showTimeoutModal;
    default:
      return state;
  }
};

const sessionTimeRemaining = (state = "", action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.SET_SESSION_TIME_REMAINING:
      return action.sessionTimeRemaining;
    default:
      return state;
  }
};

export const getSessionTimeRemaining = state => state.sessionTimeRemaining;
export const getTimeoutModal = state => state.showTimeoutModal;
//#endregion

//#region Load Category reducers
const initialCategoryState = {
  error: "",
  isFetching: false,
  categories: [],
  selectedCategories: []
};

const getSelectedCategories = (categories, action) => {
  if (categories.includes(action.id)) {
    return Object.assign([], categories.filter(x => x !== action.id));
  } else {
    return [...categories, action.id];
  }
};

const setInitialCategories = (categories, action) => {
  if (categories.includes(action.id)) {
    return Object.assign([], categories);
  } else if (action.id) {
    return [action.id];
  }
  return [];
};

const categoryData = (state = initialCategoryState, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.LOAD_CATEGORY_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        timeStamp: action.timeStamp,
        isFetching: action.isFetching
      });
    case ROOT_ACTION_TYPES.LOAD_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        timeStamp: action.timeStamp,
        isFetching: action.isFetching
      });
    case ROOT_ACTION_TYPES.LOAD_CATEGORY_RESPONSE:
      return Object.assign({}, state, {
        timeStamp: action.timeStamp,
        isFetching: action.isFetching,
        categories: action.categories
      });
    case ROOT_ACTION_TYPES.CATEGORY_CLEAR_ALL:
      return Object.assign({}, state, {
        selectedCategories: []
      });
    case ROOT_ACTION_TYPES.CATEGORY_TOGGLE:
      return Object.assign({}, state, {
        selectedCategories: getSelectedCategories(
          state.selectedCategories,
          action
        )
      });
    case ROOT_ACTION_TYPES.CATEGORY_INITIATE:
      return Object.assign({}, state, {
        selectedCategories: setInitialCategories(
          state.selectedCategories,
          action
        )
      });
    default:
      return state;
  }
};
//#endregion

//#region Load Product reducers
const initialProductState = {
  error: "",
  isFetching: false,
  products: [],
  searchTerm: "",
  currentTerm: "",
  isSearching: false,
  isEntered: false,
  filter: "All",
  page: 0
};

const productData = (state = initialProductState, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.SEARCH_SET_TERM:
      return Object.assign({}, state, {
        currentTerm: action.currentTerm
      });
    case ROOT_ACTION_TYPES.SEARCH_INITIATE:
      return Object.assign({}, state, {
        isSearching: true,
        searchTerm: action.searchTerm,
        page: 0
      });
    case ROOT_ACTION_TYPES.SEARCH_POPUP_TOGGLE:
      return Object.assign({}, state, {
        isEntered: action.isEntered
      });
    case ROOT_ACTION_TYPES.SEARCH_RESET:
      return Object.assign({}, state, {
        isSearching: false,
        isEntered: false
      });
    case ROOT_ACTION_TYPES.LOAD_PRODUCT_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        timeStamp: new Date(),
        isFetching: false
      });
    case ROOT_ACTION_TYPES.LOAD_PRODUCT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        timeStamp: new Date()
      });
    case ROOT_ACTION_TYPES.LOAD_PRODUCT_RESPONSE:
      return Object.assign({}, state, {
        isFetching: false,
        timeStamp: new Date(),
        products: action.products,
        totalFound: action.totalFound
      });
    case ROOT_ACTION_TYPES.SET_PRODUCT_FILTER:
      return Object.assign({}, state, {
        filter: action.filter
      });
    case ROOT_ACTION_TYPES.SET_PRODUCT_PAGE:
      return Object.assign({}, state, {
        page: action.page
      });
    default:
      return state;
  }
};

const initialPartnerState = {
  error: "",
  isFetching: false,
  partners: [],
  sort: "",
  page: 1
};

const partnerData = (state = initialPartnerState, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.LOAD_PARTNER_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        timeStamp: new Date(),
        isFetching: false
      });
    case ROOT_ACTION_TYPES.LOAD_PARTNER_REQUEST:
      return Object.assign({}, state, {
        sort: action.sort,
        pageSize: action.pageSize,
        isFetching: true,
        timeStamp: new Date(),
        page: action.page
      });
    case ROOT_ACTION_TYPES.LOAD_PARTNER_RESPONSE:
      return Object.assign({}, state, {
        isFetching: false,
        timeStamp: new Date(),
        partners: action.partners
      });
    default:
      return state;
  }
};

const initialPartnerUsersState = {
  error: "",
  isFetching: false,
  usersByPartner: []
};
const partnerUsersData = (state = initialPartnerUsersState, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.LOAD_PARTNER_USERS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        timeStamp: new Date(),
        isFetching: false
      });
    case ROOT_ACTION_TYPES.LOAD_PARTNER_USERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        timeStamp: new Date()
      });
    case ROOT_ACTION_TYPES.LOAD_PARTNER_USERS_RESPONSE:
      return Object.assign({}, state, {
        isFetching: false,
        timeStamp: new Date(),
        usersByPartner: action.usersByPartner
      });
    default:
      return state;
  }
};

const initialPartnerByNameState = {
  error: "",
  isFetching: false,
  partner: null,
  isNew: true
};
const partnerByNameData = (state = initialPartnerByNameState, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.LOAD_PARTNER_BYNAME_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        timeStamp: new Date(),
        isFetching: false
      });
    case ROOT_ACTION_TYPES.LOAD_PARTNER_BYNAME_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        timeStamp: new Date()
      });
    case ROOT_ACTION_TYPES.LOAD_PARTNER_BYNAME_RESPONSE:
      return Object.assign({}, state, {
        isFetching: false,
        timeStamp: new Date(),
        partner: action.partner,
        isNew: false
      });
    case ROOT_ACTION_TYPES.CLEAR_PARTNER:
      return Object.assign({}, state, {
        isFetching: false,
        timeStamp: new Date(),
        partner: null,
        isNew: true
      });
    case ROOT_ACTION_TYPES.SET_PARTNER_TYPE:
      const setPartnerTypes =
        state.partner && state.partner.types
          ? state.partner.types.concat([action.partnerTypeId])
          : [action.partnerTypeId];

      return Object.assign({}, state, {
        partner: Object.assign({}, state.partner, {
          types: setPartnerTypes
        })
      });
    case ROOT_ACTION_TYPES.CLEAR_PARTNER_TYPE:
      const clearedPartnerTypes = state.partner.types.filter(
        element => action.partnerTypeId !== element
      );

      return Object.assign({}, state, {
        partner: Object.assign({}, state.partner, {
          types: clearedPartnerTypes
        })
      });
    default:
      return state;
  }
};

const initialPartnerTypeData = {
  error: "",
  isFetching: false,
  partnerTypes: []
};

const partnerTypeData = (state = initialPartnerTypeData, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.LOAD_PARTNER_TYPE_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        timeStamp: new Date(),
        isFetching: false
      });
    case ROOT_ACTION_TYPES.LOAD_PARTNER_TYPE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        timeStamp: new Date()
      });
    case ROOT_ACTION_TYPES.LOAD_PARTNER_TYPE_RESPONSE:
      return Object.assign({}, state, {
        isFetching: false,
        timeStamp: new Date(),
        partnerTypes: action.partnerTypes
      });
    default:
      return state;
  }
};

//#endregion

//#region My success team
const isLoadingSuccessTeam = (state = false, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.LOAD_SUCCESS_TEAM_REQUEST:
      return true;
    case ROOT_ACTION_TYPES.LOAD_SUCCESS_TEAM_FAILURE:
    case ROOT_ACTION_TYPES.LOAD_SUCCESS_TEAM_RESPONSE:
      return false;
    default:
      return state;
  }
};

const successTeam = (state = [], action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.LOAD_SUCCESS_TEAM_RESPONSE:
      return action.successTeam;
    default:
      return state;
  }
};

const successTeamErrorMessage = (state = null, action) => {
  switch (action.type) {
    case ROOT_ACTION_TYPES.LOAD_SUCCESS_TEAM_FAILURE:
      return action.message;
    case ROOT_ACTION_TYPES.LOAD_SUCCESS_TEAM_RESPONSE:
    case ROOT_ACTION_TYPES.LOAD_SUCCESS_TEAM_REQUEST:
      return null;
    default:
      return state;
  }
};

export const getIsLoadingSuccessTeam = state => state.isLoadingSuccessTeam;
export const getSuccessTeam = state => state.successTeam;
export const getSuccessTeamErrorMessage = state =>
  state.successTeamErrorMessage;
//#endregion

//#region Combine Reducers

const marketplaceReducer = combineReducers({
  currentLocationPath,
  isFormDirty,
  userProfile,
  showActionIndicator,
  showGlobalErrorIndicator,
  showTimeoutModal,
  sessionTimeRemaining,
  categoryData,
  productData,
  partnerData,
  partnerTypeData,
  partnerByNameData,
  partnerUsersData,
  isLoadingSuccessTeam,
  successTeam,
  successTeamErrorMessage
});

//#endregion

export default marketplaceReducer;
