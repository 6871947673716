import React, { Component } from "react";
import { InputField } from "@paycor/picl/form-elements";
import Button from "@paycor/picl/button";
import Modal from "@paycor/picl/modal";
import { HOST } from "../../config/environment";
import { FetchWrapper } from "../../Util/FetchWrapper";
import { connect } from "react-redux";
import { fetchUsersByPartner } from "../../store/actions";
import {
    InputMasks,
    validateRequired,
    validateEmail,
    validatePhone,
    trimPhoneForSMA,
} from "../../Util/validators";

class AddDeveloperUserDialog extends Component {
    constructor(props) {
        super(props);

        this.onSendInvite = this.onSendInvite.bind(this);
        this.onAddUserCancel = this.onAddUserCancel.bind(this);

        this.state = {
            ShowAddUser: false,
            errors: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
            },
            fields: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
            },
            isSending: false,
            isSendError: false,
            isValidForm: false,
            hasValidated: false,
            SendErrorMsg:
                "Sorry, we can't send the invite right now. Please wait a bit and try again.",
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ShowAddUser: nextProps.ShowAddUser,
            errors: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
            },
            fields: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
            },
            isSending: false,
            isSendError: false,
            isValidForm: false,
            hasValidated: false,
        });
    }

    GetUsers() {
        const { dispatch } = this.props;
        console.log("in Get Users Partner", this.props.Partner);
        dispatch(fetchUsersByPartner(this.props.Partner));
    }

    onAddUserCancel() {
        if (this.props.OnDlgClose) this.props.OnDlgClose();

        this.setState({
            ShowAddUser: false,
            isSending: false,
            isSendError: false,
            hasValidated: false,
        });
    }

    validateForm() {
        let { fields } = this.state;
        let errors = {};

        errors.firstName = validateRequired(
            fields.firstName,
            "First name is required"
        );
        errors.lastName = validateRequired(
            fields.lastName,
            "Last name is required"
        );
        errors.email = validateEmail(
            fields.email,
            "Email address is invalid",
            "Email is required"
        );
        errors.phone = validatePhone(
            fields.phone,
            "Phone is invalid, it must be 10 digits",
            "Phone is required"
        );
        const isValid = !Boolean(
            errors.firstName.concat(errors.lastName, errors.email, errors.phone)
        );

        this.setState({
            errors: errors,
            isValidForm: isValid,
            isSendError: false,
            hasValidated: true,
        });

        return isValid;
    }

    handleSendError() {
        this.setState({
            isSending: false,
            isSendError: true,
            SendErrorMsg:
                "Sorry, we can't send the invite right now. Please wait a bit and try again.",
        });
    }

    onSendInvite() {
        if (this.validateForm()) {
            const { fields } = this.state;
            const { OnDlgClose, OnInviteSuccess } = this.props;
            const email = fields.email;

            let userData = {
                firstName: fields.firstName,
                lastName: fields.lastName,
                emailAddress: email,
                phoneNumber: trimPhoneForSMA(fields.phone),
                partnerIdentifier: this.props.Partner,
            };

            let url = HOST.ENDPOINTS.REG_USER_INVITE;
            let intApi = new FetchWrapper(url, userData);

            intApi.post(
                () => {
                    this.setState({
                        ShowAddUser: false,
                        isSending: false,
                        isSendError: false,
                        hasValidated: false,
                    });

                    this.GetUsers();

                    if (OnInviteSuccess) OnInviteSuccess(email);
                    if (OnDlgClose) OnDlgClose();
                },
                (res) => {
                    if (res.status === 409) {
                        this.setState({
                            isSending: false,
                            isSendError: true,
                            SendErrorMsg:
                                "A partner user with this email already exists, please try another email address.",
                        });
                    } else {
                        this.handleSendError();
                    }
                }
            );

            this.setState({
                isSending: true,
            });
        }
    }

    setFieldState(prop, val) {
        var fields = this.state.fields;
        fields[prop] = val;
        this.setState({ fields: fields });
    }

    render() {
        let {
            ShowAddUser,
            errors,
            fields,
            isValidForm,
            hasValidated,
            isSendError,
        } = this.state;
        return (
            <Modal
                show={ShowAddUser}
                showClose={true}
                title="Add Developer Portal User"
                backdrop={true}
                body={
                    <React.Fragment>
                        <div id="formContainer" className="SendInviteFields">
                            <div
                                id="divDataMissing"
                                className={
                                    isValidForm || !hasValidated
                                        ? "modalHideErrorPanel"
                                        : "icon-error iconErrorModal"
                                }
                            >
                                <span className="paddingLeft">
                                    Missing required field(s). Please complete
                                    the fields below.
                                </span>
                            </div>
                            <div
                                id="divSendError"
                                className={
                                    isSendError
                                        ? "icon-error iconErrorModal"
                                        : "modalHideErrorPanel"
                                }
                            >
                                <span className="paddingLeft">
                                    {this.state.SendErrorMsg}
                                </span>
                            </div>
                            <div
                                style={{
                                    fontSize: "14px",
                                    color: "#303640",
                                    fontWeight: "300",
                                }}
                            >
                                All fields are required unless marked optional.
                            </div>
                            <div
                                className="feauxcontainer"
                                style={{ marginTop: "13px" }}
                            >
                                <div className="row">
                                    <div className="col-xs-6">
                                        <div className="prompt">First Name</div>
                                        <InputField
                                            id="fname"
                                            value={fields.firstName}
                                            characterLimit={50}
                                            inputMask={InputMasks.Name}
                                            errorMessage={errors.firstName}
                                            onChange={(val) =>
                                                this.setFieldState(
                                                    "firstName",
                                                    val
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="col-xs-6">
                                        <div className="prompt">Last Name</div>
                                        <InputField
                                            id="lname"
                                            value={fields.lastName}
                                            characterLimit={50}
                                            inputMask={InputMasks.Name}
                                            errorMessage={errors.lastName}
                                            onChange={(val) =>
                                                this.setFieldState(
                                                    "lastName",
                                                    val
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className="row"
                                    style={{ marginTop: "29px" }}
                                >
                                    <div className="col-xs-6">
                                        <div className="prompt">Email</div>
                                        <InputField
                                            id="email"
                                            value={fields.email}
                                            characterLimit={254}
                                            inputMask={InputMasks.Email}
                                            errorMessage={errors.email}
                                            onChange={(val) =>
                                                this.setFieldState("email", val)
                                            }
                                        />
                                    </div>
                                    <div className="col-xs-6">
                                        <div className="prompt">Phone</div>
                                        <InputField
                                            id="phone"
                                            value={fields.phone}
                                            characterLimit={15}
                                            inputMask={InputMasks.PhoneShort}
                                            errorMessage={errors.phone}
                                            onChange={(val) =>
                                                this.setFieldState("phone", val)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                footer={
                    <div className="modalFooterButtons">
                        <Button
                            id="btnCancel"
                            aria-label="secondary"
                            type={Button.Types.SECONDARY}
                            onClick={this.onAddUserCancel}
                            children="Cancel"
                            className="modalButtons"
                        />
                        <Button
                            id="btnSend"
                            aria-label="primary button"
                            icon={"Send"}
                            busy={false}
                            disabled={false}
                            type="primary"
                            children="Send Invite"
                            onClick={this.onSendInvite}
                        />
                    </div>
                }
                onClose={this.onAddUserCancel}
            />
        );
    }
}

export default connect()(AddDeveloperUserDialog);
