import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./featuredPartner.scss";
import { truncate } from "../../Util/Helper";

class featuredPartner extends Component {
  render() {
    // console.log(this.props);
    return this.props ? (
      <div className="col-sm-6 partnerContainerWrap">
        <div className="row partnerContainer">
          <NavLink
            className="featuredLink"
            id="lnkGotoProductDetail"
            to={"/products/" + this.props.partner.id}
          >
            <div>
              <img
                className="partnerImage"
                alt={this.props.partner.name}
                src={this.props.partner.image.portraitThumbnailUrl}
              />
              <div className="partnertitle">{this.props.partner.name}</div>
              <div className="partnerCategory">
                {this.props.partner.categoryName}{" "}
              </div>
              <div className="partnerDesc">
                {truncate(this.props.partner.description, 135)}{" "}
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default featuredPartner;
