import React, { Component } from "react";
import FaqIndex from "../components/FAQ/faqindex";
import FaqFooter from "../components/FAQ/faqfooter";

class Help extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="App MainContent page-row page-row-expanded">
          <FaqIndex />
          {/* <HelpContactus />           */}
        </div>
        <FaqFooter />
      </React.Fragment>
    );
  }
}
export default Help;
