import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Solution extends Component {
  render() {
    return (
      <div className="col-md-3 col-sm-3 col-xs-6">
        <NavLink
          id="lnkPartnerImageLink"
          to={"/products/" + this.props.solution.id}
        >
          <img
            className="OurPartnerImage"
            alt={this.props.solution.name}
            src={this.props.solution.image.portraitThumbnailUrl}
          />
        </NavLink>
      </div>
    );
  }
}
export default Solution;
