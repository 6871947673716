import { HOST } from "../config/environment";
import Api from "./FetchWrapper";

export const redirectOnUnauthenticated = () => {
  let _return = encodeURIComponent(window.location.href);
  let SigninUrl = `${HOST.ENDPOINTS.SIGNIN}?ReturnUrl=${_return}`;
  window.location.href = SigninUrl;
};

export const redirectToLogout = () => {
  let signoutUrl = HOST.ENDPOINTS.SIGNOUT;
  window.location.href = signoutUrl;
};

export const refreshSession = () => {
  let url = HOST.ENDPOINTS.ACCTS;
  let marketPlaceApi = new Api(url);
  return marketPlaceApi.getWithNoCacheNoCors();
};
