import React, { Component } from "react";
import { connect } from "react-redux";
import Banner from "@paycor/picl/banner";
import { clearGlobalErrorIndicator } from "../../store/actions";

class ErrorModal extends Component {
    constructor(props) {
        super(props);
        this.onClose = this.onClose.bind(this);
    }

    render() {
        const { message, hasErrors } = this.props;
        return (
            <Banner
                id="error_modal"
                status={Banner.Status.ERROR}
                show={hasErrors}
                message={message}
                onClose={() => this.onClose()}
            />
        );
    }

    onClose() {
        const { dispatch } = this.props;
        dispatch(clearGlobalErrorIndicator());
    }
}

const mapStateToProps = (state) => {
    const { show, message } = state.showGlobalErrorIndicator;
    return {
        hasErrors: show,
        message: message,
    };
};
export default connect(mapStateToProps)(ErrorModal);
