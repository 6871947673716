import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@paycor/picl/button";

export default class FilterPanel extends Component {
    static propTypes = {
        size: PropTypes.string,
        title: PropTypes.string.isRequired,
        onApplyClicked: PropTypes.func.isRequired,
        onClearAllClicked: PropTypes.func.isRequired,
        hideHeaderCloseButton: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            disabled: false,
            hideHeaderCloseButton:
                props.hideHeaderCloseButton &&
                props.hideHeaderCloseButton === true,
        };
    }

    componentWillMount = () => {
        const { isActionDisabled } = this.props;
        if (isActionDisabled !== null && isActionDisabled !== undefined) {
            this.setState({ disabled: isActionDisabled });
        }
    };

    componentWillUnmount = () => {
        this.setState({ disabled: false });
    };

    applyClicked = (callback) => (e) => {
        callback();
    };

    clearAllClicked = (callback) => (e) => {
        callback();
    };

    render() {
        const { title, onApplyClicked, children, onClearAllClicked } =
            this.props;
        return (
            <div className="FilterOuterContainer">
                <div className="InputBlock" />
                <div className="FilterPanelContainer">
                    <div className="FilterPanelHeader">
                        &nbsp;
                        <div className="FilterPanelTitle">{title}</div>
                        <div className="FilterPanelHeaderLink">
                            {/* eslint-disable-next-line */}
                            <a onClick={this.applyClicked(onApplyClicked)}>
                                OK
                            </a>
                        </div>
                    </div>
                    <div className="FilterPanelBody">{children}</div>
                    <div className="FilterPanelFooter">
                        <Button
                            type="primary"
                            onClick={this.applyClicked(onApplyClicked)}
                        >
                            OK
                        </Button>
                        <Button
                            type="secondary-action"
                            onClick={this.clearAllClicked(onClearAllClicked)}
                        >
                            Clear All Filters
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
