export const ValidationPatterns = {
    // eslint-disable-next-line
    Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ZipCode: /^\d{5}([-])?(\d{4})?$/g,
    PhoneExtension: /^\d{1,4}$/g,
    Website: /^[a-z0-9.:/]+$/i,
};

// TODO: validate each of these
export const InputMasks = {
    Name: /^([a-zA-Z0-9_\s\w\-,`'.()]*)$/,
    Email: /[@!#$%&'*+-/=?^_`{|}~\w]/g,
    PhoneShort: "9999999999",
    Phone: /^([\d()\s-])*$/,
    PhoneExtension: "9999",
    ZipCode: /^\d{1,5}$/g,
    Number: "999999999",
    Words: /[-\s\w]/gi,
    Website: /^[a-z0-9.:/]+$/i,
};

export function validateRequired(fieldValue, errorMessage) {
    if (fieldValue && fieldValue.trim()) {
        return "";
    }
    return errorMessage;
}

export function validateEmail(fieldValue, invalidErrMsg, requiredErrMsg = "") {
    return validateRegularExpression(
        fieldValue,
        ValidationPatterns.Email,
        invalidErrMsg,
        requiredErrMsg
    );
}

export function validateWebsite(
    fieldValue,
    invalidErrMsg,
    requiredErrMsg = ""
) {
    return validateRegularExpression(
        fieldValue,
        ValidationPatterns.Website,
        invalidErrMsg,
        requiredErrMsg
    );
}

export function validatePhone(fieldValue, invalidErrMsg, requiredErrMsg = "") {
    let error = "";
    // If a required error message is passed in, then perform required field validation.
    if (Boolean(requiredErrMsg)) {
        error = validateRequired(fieldValue, requiredErrMsg);
    }
    if (Boolean(error)) return error;

    // Ensure we have a phone.
    if (fieldValue.length < 10) {
        return invalidErrMsg;
    }

    return "";
}

export function trimPhoneForSMA(phone) {
    return phone.replace(/[-()\s]/g, "");
}

export function validateRegularExpression(
    fieldValue,
    expression,
    invalidErrMsg,
    requiredErrMsg = ""
) {
    let error = "";
    // If a required error message is passed in, then perform required field validation.
    if (Boolean(requiredErrMsg)) {
        error = validateRequired(fieldValue, requiredErrMsg);
    }
    if (Boolean(error)) return error;

    // Ensure the field passes the regular expression validation if it has a value.
    if (Boolean(fieldValue) && !fieldValue.match(expression)) {
        return invalidErrMsg;
    }

    return "";
}

export function validateLength() {
    return "";
}
