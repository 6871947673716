import React from "react";
import Solution from "./solution";
import { HOST } from "../../config/environment";
import { FetchWrapper } from "../../Util/FetchWrapper";
import { Spinner } from "@paycor/picl/icon";

class List extends React.Component {
    constructor() {
        super();
        this.state = {
            partners: [],
            isBusy: true,
        };
    }

    componentDidMount() {
        let url = `${HOST.ENDPOINTS.MP_PRODUCTS}?pageSize=8&partnerLevels=Platinum,Gold&orderBy=random`;

        let marketPlaceApi = new FetchWrapper(url);
        marketPlaceApi.get((data) => {
            let partners = data.items
                ? data.items.map((partner) => {
                      return (
                          <span key={partner.id}>
                              <Solution solution={partner} />
                          </span>
                      );
                  })
                : "";
            this.setState({ partners, isBusy: false });
        });
    }

    render() {
        const { partners, isBusy } = this.state;
        return isBusy ? (
            <div
                style={{
                    textAlign: "center",
                    minHeight: "128px",
                    paddingTop: "58px",
                }}
            >
                <Spinner animation="spin" size="36px" />
            </div>
        ) : (
            <span>{partners}</span>
        );
    }
}

export default List;
