import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Search as SearchIcon } from "@paycor/picl/icon";
import { toggleSearchPopup, initiateSearch } from "../../store/actions";
import SearchPopup from "./searchpopup";

class Search extends Component {
    constructor(props) {
        super(props);
        this.handleSearchIconClicked = this.handleSearchIconClicked.bind(this);
    }

    onSearch() {
        // Change the route to search, to ensure the search is
        // processed accordingly.
        const { currentTerm, dispatch, history } = this.props;
        dispatch(initiateSearch(currentTerm));
        const url = history.location.pathname;
        if (!url.includes("search")) {
            history.push("/search");
        }
    }

    // Initiate a search since the icon was pressed.
    handleSearchIconClicked() {
        const { currentTerm, dispatch } = this.props;
        if (currentTerm) {
            this.onSearch();
        }
        dispatch(toggleSearchPopup(false));
    }

    render() {
        const { isEntered, dispatch } = this.props;

        if (isEntered) {
            // Display the input field when the user has pressed the search icon.
            return (
                <React.Fragment>
                    <div className="searchArea blue hidden-md hidden-lg">
                        {/* eslint-disable-next-line */}
                        <a
                            className="searchLink"
                            onClick={this.handleSearchIconClicked}
                        >
                            <SearchIcon size="20px" className="searchIcon" />
                        </a>
                    </div>
                    <SearchPopup classParm="hidden-xs" />
                </React.Fragment>
            );
        } else {
            // Just display the search icon.
            return (
                <div className="searchArea">
                    {/* eslint-disable-next-line */}
                    <a
                        className="searchLink"
                        onClick={() => {
                            dispatch(toggleSearchPopup(!isEntered));
                        }}
                    >
                        <SearchIcon
                            className="searchIcon"
                            size="20px"
                            color="white"
                        />
                    </a>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    const { currentTerm, isEntered } = state.productData;
    return {
        isEntered,
        currentTerm,
    };
};

export default withRouter(connect(mapStateToProps)(Search));
