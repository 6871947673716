import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Button from "@paycor/picl/button";
import Banner from "@paycor/picl/banner";
import "./ViewPartnerDetail.scss";
import ManageDevelopers from "./ManageDevelopers";

class PartnerDetail extends Component {
    constructor(props) {
        super(props);
        this.myRef = null;
        this.onClickEdit = this.onClickEdit.bind(this);
        this.scrollToMyRef = this.scrollToMyRef.bind(this);
    }

    scrollToMyRef = () => {
        // run this method to execute scrolling.
        window.scrollTo(
            0,
            this.myRef.offsetTop
            //{ top: this.myRef.offsetTop,
            // behavior: "smooth" // Optional, adds animation
            //}
        );
    };

    onClickEdit() {
        var { history } = this.props;
        var redirecturl = `/managepartnerdetail/${this.props.partner.name}`;
        history.push(redirecturl);
    }

    render() {
        const { partner } = this.props;

        let isSubmitted = window.location.toString().includes("submit=true");

        var types = "";
        if (partner && partner.types && partner.types.length > 0) {
            types = partner.types.join(", ");
        }

        var data = {
            showModal: false,
            PartnerName: partner ? partner.name : "",
            Status: partner ? partner.status : "",
            PartnerType: types,
            ContactFirstName: partner ? partner.firstName : "",
            ContactLastName: partner ? partner.lastName : "",
            ContactEmail: partner ? partner.emailAddress : "",
            ContactPhone: partner ? partner.phoneNumber : "",
            selectedCategories: [],
            isSubmitted: isSubmitted,
            missingCategories: false,
            isViewOnlyMode: false,
        };

        if (partner)
            return (
                <React.Fragment>
                    <div className="PartnerDetailContainer">
                        <div className="BrowseBack">
                            <NavLink id="browseback" to="/managemarketplace">
                                &laquo; Back to Partners
                            </NavLink>
                        </div>
                        <div className="Bannerspacing">
                            <Banner
                                id={"Success"}
                                message={
                                    <div>
                                        Success! {data.PartnerName} &nbsp;was
                                        saved.
                                        {data.Status &&
                                        (data.Status.toLowerCase() ===
                                            "active" ||
                                            data.Status.toLowerCase() ===
                                                "pending") ? (
                                            <div>
                                                You can now give users access to
                                                the Developer Portal
                                                <Button
                                                    aria-label="secondary button"
                                                    busy={false}
                                                    disabled={false}
                                                    type={
                                                        Button.Types.SECONDARY
                                                    }
                                                    onClick={this.scrollToMyRef}
                                                    children="here."
                                                    className="herelink"
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                }
                                status={Banner.Status.SUCCESS}
                                show={data.isSubmitted}
                            />
                        </div>
                        <div className="row">
                            <div className="Header1 col-md-6">
                                {data.PartnerName}
                            </div>
                            <div className="col-md-6">
                                <Button
                                    aria-label="secondary button"
                                    busy={false}
                                    disabled={false}
                                    type={Button.Types.SECONDARY}
                                    icon={"edit"}
                                    onClick={this.onClickEdit}
                                    children="Edit Partner"
                                    className="EditButtons"
                                />
                            </div>
                        </div>

                        <div className="Header2">General Information</div>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <div className="labeltext">Partner Name</div>
                                <div className="labelValue">
                                    {data.PartnerName}
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <div className="labeltext">Status</div>
                                <div className="labelValue">{data.Status}</div>
                            </div>
                            <div className="col-xs-12 col-md-12">
                                <div className="labeltext">Partner Type</div>
                                <div className="labelValue">
                                    {data.PartnerType}
                                </div>
                            </div>
                            <div className="Header3 col-xs-12 col-md-12">
                                Contact Information
                            </div>

                            <div className="col-xs-12 col-md-6">
                                <div className="labeltext">First Name</div>
                                <div className="labelValue">
                                    {data.ContactFirstName}
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <div className="labeltext">Last Name</div>
                                <div className="labelValue">
                                    {data.ContactLastName}
                                </div>
                            </div>

                            <div className="col-xs-12 col-md-6">
                                <div className="labeltext">Email</div>
                                <div className="labelValue">
                                    {data.ContactEmail}
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <div className="labeltext">Phone Number</div>
                                <div className="labelValue">
                                    {data.ContactPhone}
                                </div>
                            </div>
                            {partner && typeof partner.name !== "undefined" ? (
                                <div
                                    id="Devportal"
                                    ref={(ref) => (this.myRef = ref)}
                                >
                                    <ManageDevelopers
                                        partner={partner.name}
                                        status={partner.status}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </React.Fragment>
            );
        else return "";
    }
}

const mapStateToProps = (state) => {
    const { partner } = state.partnerByNameData;
    return { partner };
};

export default withRouter(connect(mapStateToProps)(PartnerDetail));
