import { HOST } from "../config/environment";
import { FetchWrapper } from "./FetchWrapper";

export const initializePendo = () => {
  let url = HOST.ENDPOINTS.PENDO;
  let marketplaceApi = new FetchWrapper(url);

  marketplaceApi.get(
    json => {
      var _pendo = {
        apiKey: "8621d39c-8c6f-413e-67b0-74762f2cca48",
        visitor: json,
        account: { id: json.clientId, name: json.clientName }
      };
      window.pendo && window.pendo.initialize(_pendo);
    },
    error => {
      //fake it to guest
      var guestjson = {
        Cached: "true",
        cached: "false",
        clientId: "-1",
        clientName: "Guest",
        companyId: "0",
        employee: "false",
        employeeOnly: "False",
        hasClientAccount: "false",
        hasContactAccount: "false",
        hasEmployeeAccount: "false",
        hasHosted: "false",
        hasPerform: "true",
        hasToD: "true",
        id: "891ab316-46ab-e611-944b-005056abeccd",
        language: "en_US",
        name: "Guest User",
        pendoCompanyAdmin: "true",
        pendoEmployeeAdmin: "true",
        pendoHrAdmin: "true",
        pendoManagerAdmin: "true",
        pendoPayrollAdmin: "true",
        pendoReportingAdmin: "true"
      };
      var _pendo = {
        apiKey: "8621d39c-8c6f-413e-67b0-74762f2cca48",
        visitor: guestjson,
        account: { id: "-1", name: "Guest" }
      };
      window.pendo && window.pendo.initialize(_pendo);
    }
  );
};

export default initializePendo;
