import React, { Component } from "react";
import { connect } from "react-redux";
import { Spinner } from "@paycor/picl/icon";
import "../ActionIndicator/_styles.scss";

class BusyIndicator extends Component {
    render() {
        let { show, message } = this.props;
        const messageFragment = Boolean(message) ? (
            <div className="message">{message}</div>
        ) : (
            ""
        );

        if (show)
            return (
                <action-indicator>
                    <section className="action-message">
                        <Spinner className="spinner" animation="spin" />
                        {messageFragment}
                    </section>
                </action-indicator>
            );

        return "";
    }
}

const mapStateToProps = (state) => {
    const { show, message } = state.showActionIndicator;
    return { show, message };
};

export default connect(mapStateToProps)(BusyIndicator);
