import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { HOST } from "../../config/environment";
import FeaturedPartner from "./featuredPartner";
import { FetchWrapper, DefaultLoadError } from "../../Util/FetchWrapper";
import { setGlobalErrorIndicator } from "../../store/actions";

class FeaturedApps extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { dispatch } = this.props;

    this.setState({
      partnerDataLoad: {
        display: "inline-block"
      }
    });

    let producturl =
      HOST.ENDPOINTS.MP_PRODUCTS + "?partnerLevels=Platinum&pageSize=2&orderBy=random";
    let marketplaceApi = new FetchWrapper(producturl);
    marketplaceApi.get(
      data => {
        this.setState({
          partnerData: data,
          partnerDataLoad: {
            display: "none"
          }
        });
      },
      () => {
        dispatch(setGlobalErrorIndicator(DefaultLoadError));
        this.setState({
          partnerDataLoad: {
            display: "none"
          }
        });
      }
    );
  }
  render() {
    let { partnerData } = this.state;
    return (
      <React.Fragment>
        <div className="row FeaturedContainer">
          <div className="FeaturedHeader">Featured Apps</div>
          <div className="lnkviewall">
            <NavLink id="lnkViewAllApps" to="/storefront">
              View all apps
            </NavLink>
           </div>
          <div className="loading-spinner" style={this.state.partnerDataLoad}>
            <div className="icon-spinner spinner-padding white" />
            {/* <div className="loading-padding">Loading</div> */}
          </div>
          {partnerData
            ? partnerData.items.map(obj => (
                <FeaturedPartner key={obj.id} partner={obj} />
              ))
            : ""}
             <hr
            style={{
              borderColor: "#f37321",
              marginLeft: "4px",
              marginRight: "4px",
              marginTop: "32px",
              marginBottom: "9px"
            }}
          />
            </div>
      </React.Fragment>
    );
  }
}

export default connect()(FeaturedApps);
