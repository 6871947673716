import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Button from "@paycor/picl/button";
import { setProductPage } from "../../store/actions";
import { truncate, fixImageUri } from "../../Util/Helper";

class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.handleShowMore = this.handleShowMore.bind(this);
    }

    // handles the show more items button click
    handleShowMore() {
        const { dispatch, page } = this.props;

        dispatch(setProductPage(page + 1));
    }

    // determines if there are more products to fetch
    hasMore() {
        const { products, totalFound } = this.props;
        return products.length < totalFound;
    }

    render() {
        const { products, isFetching } = this.props;

        if (products)
            return (
                <React.Fragment>
                    <div className="row">
                        <span>
                            {products.map((product) => {
                                return (
                                    <div key={product.id} className="col-sm-3">
                                        <NavLink
                                            id={product.id + "_lnk"}
                                            to={"/products/" + product.id}
                                            className="nohoverLink"
                                        >
                                            <div className="FeauxTainer">
                                                <div className="PartnerBorder">
                                                    <img
                                                        className="PartnerImage"
                                                        alt={product.name}
                                                        src={fixImageUri(
                                                            product.image
                                                                .portraitThumbnailUrl
                                                        )}
                                                    />
                                                    <div className="partnerNameDiv">
                                                        <div
                                                            className="Ascensus"
                                                            title={product.name}
                                                        >
                                                            {truncate(
                                                                product.name,
                                                                45
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="KProviders">
                                                        {truncate(
                                                            product.categoryName,
                                                            26
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                );
                            })}
                        </span>
                    </div>
                    <div className="row ShowMoreContainer">
                        {this.hasMore() || isFetching ? (
                            <Button
                                id="btnShowMore"
                                aria-label="button"
                                busy={isFetching}
                                type="secondary-action"
                                onClick={this.handleShowMore}
                            >
                                Show More Apps
                            </Button>
                        ) : (
                            ""
                        )}
                    </div>
                </React.Fragment>
            );

        if (isFetching)
            return (
                <div
                    style={{
                        textAlign: "center",
                        paddingTop: "200px",
                    }}
                >
                    <div className="icon-spinner spinner-padding white" />
                </div>
            );
        return (
            <div>
                <span>No results were found. Please try another search.</span>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedCategories } = state.categoryData;
    const { products, totalFound, isFetching, page } = state.productData;
    return {
        selectedCategories,
        products,
        totalFound,
        isFetching,
        page,
    };
};

export default connect(mapStateToProps)(ProductList);
