import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    clearAllCategories,
    setProductPage,
    setSearchTerm,
    resetSearch,
} from "../../store/actions";
import Category from "./category";
import { Checkbox } from "@paycor/picl/form-elements";

class CategoryList extends React.Component {
    constructor(props) {
        super(props);
        this.clearAll = this.clearAll.bind(this);
    }

    clearAll() {
        const { dispatch, history } = this.props;
        dispatch(setSearchTerm(""));
        dispatch(resetSearch());
        dispatch(clearAllCategories());
        dispatch(setProductPage(0));
        history.push("/storefront");
    }

    render() {
        let { categories, selectedCategories } = this.props;

        categories.forEach((c) => {
            c.status = selectedCategories.includes(c.id)
                ? Checkbox.StatusTypes.CHECKED
                : Checkbox.StatusTypes.NONE;
        });

        if (categories)
            return (
                <React.Fragment>
                    <div className="FilterListContainer">
                        {categories.map((category) => (
                            <Category
                                key={category.id}
                                category={category}
                                onCategoryChange={this.props.onCategoryChange}
                                status={category.status}
                            />
                        ))}
                    </div>
                    <div className="ClearAll visible-md visible-lg">
                        {/* eslint-disable-next-line */}
                        <a
                            id="clearAll"
                            onClick={this.clearAll}
                            style={{ cursor: "pointer" }}
                        >
                            Clear All
                        </a>
                    </div>
                </React.Fragment>
            );
        return "";
    }
}

const mapStateToProps = (state) => {
    const { categories, selectedCategories } = state.categoryData;
    return { categories, selectedCategories };
};

export default withRouter(connect(mapStateToProps)(CategoryList));
