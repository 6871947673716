import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withCookies, Cookies } from "react-cookie";

import { SESSION_TIMEOUT_TIME_TO_SHOW_MODAL } from "../../config/environment";
import * as AuthenticationUtils from "../../Util/authentication";
import ConfirmModal from "../Modals/Confirm";

import {
  getSessionTimeRemaining,
  getTimeoutModal
} from "../../store/root-reducer";
import {
  setSessionTimeRemaining,
  setSessionTimeoutModal,
  setActionIndicator
} from "../../store/actions";

class SessionTimeout extends React.Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    showTimeoutModal: PropTypes.bool.isRequired,
    sessionTimeRemaining: PropTypes.string.isRequired,
    setSessionTimeRemaining: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      buttonDisabled: false
    };
  }

  componentDidMount() {
    AuthenticationUtils.refreshSession();
    this.startCheckingSession();
  }

  componentWillUnmount() {
    this.resetSessionModal();
    this.props.setActionIndicator({ message: "", show: false });
  }

  updateSessionTimeLeft = () => {
    const {
      cookies,
      setSessionTimeRemaining,
      setSessionTimeoutModal
    } = this.props;

    const currentTime = moment();
    const sessionTimeout = moment(cookies.get("SESSIONTIMEOUT"));

    const secondsToExpiration = sessionTimeout.diff(currentTime, "seconds");
    if (
      secondsToExpiration <= 0 ||
      sessionTimeout.unix() < currentTime.unix()
    ) {
      this.sessionTimedOut();
    } else {
      let displayableTimeLeft = moment(sessionTimeout.diff(currentTime)).format(
        "m:ss"
      );
      setSessionTimeRemaining(displayableTimeLeft);
      setSessionTimeoutModal(true);
    }
  };

  startUpdatingTimeLeft = () => {
    this._checkTimeLeftTimer = setInterval(this.updateSessionTimeLeft, 1000);
  };

  stopUpdatingTimeLeft = () => {
    clearInterval(this._checkTimeLeftTimer);
  };

  checkIfSessionIsTimingOut = () => {
    const { cookies } = this.props;
    if (typeof cookies === "undefined") {
      return;
    }
    const now2 = moment();
    let cookieTimeOut = cookies.get("SESSIONTIMEOUT");
    let cookieServerTime = cookies.get("SERVERTIME");
    if (
      typeof cookieTimeOut === "undefined" ||
      cookieTimeOut === null ||
      typeof cookieServerTime === "undefined" ||
      cookieServerTime === null
    ) {
      return;
    }

    var cookieTimeOut2 = moment(cookieTimeOut);
    var theDiff = cookieTimeOut2.diff(now2, "seconds");
    if (theDiff <= SESSION_TIMEOUT_TIME_TO_SHOW_MODAL) {
      this.stopCheckingSession();
      this.startUpdatingTimeLeft();
    }
  };

  startCheckingSession = () => {
    this._checkSessionTimer = setInterval(this.checkIfSessionIsTimingOut, 5000);
  };

  stopCheckingSession = () => {
    clearInterval(this._checkSessionTimer);
  };

  sessionTimedOut = () => {
    this.resetSessionModal();
    this.props.setActionIndicator({ message: "Loading", show: true });
    AuthenticationUtils.redirectOnUnauthenticated();
  };

  handleSessionOptOut = () => {
    this.resetSessionModal();
    this.props.setActionIndicator({ message: "Loading", show: true });
    AuthenticationUtils.redirectToLogout();
  };

  refreshSession = () => {
    console.log("In refreshSession");
    this.props.setActionIndicator({ message: "Loading", show: true });
    this.setState({ buttonDisabled: true });
    AuthenticationUtils.refreshSession().then(
      response => {
        console.log("In refresh session - resetting");
        this.resetSessionModal();
        this.startCheckingSession();
        return;
      },
      error => {
        console.log("error in refresh session");
        AuthenticationUtils.redirectOnUnauthenticated();
        return {};
      }
    );
  };

  resetSessionModal = () => {
    this.stopCheckingSession();
    this.stopUpdatingTimeLeft();
    this.setState({ buttonDisabled: false });
    this.props.setActionIndicator({ message: "", show: false });
    this.props.setSessionTimeoutModal(false);
    this.props.setSessionTimeRemaining("0:00");
  };

  render() {
    const { showTimeoutModal, sessionTimeRemaining } = this.props;
    return (
      <session-timeout>
        {showTimeoutModal && (
          <ConfirmModal
            show={true}
            size="sm"
            hideHeaderCloseButton={true}
            isActionDisabled={this.state.buttonDisabled}
            modalClassName="session-timeout-modal"
            backdropClassName="session-timeout-backdrop"
            title="Are you still there?"
            actionLabel="Yes, I'm still here!"
            cancelLabel="Log me out"
            onCancelClicked={this.handleSessionOptOut}
            onActionClicked={this.refreshSession}
          >
            <p>
              To protect your privacy, we'll automatically log you out after
              periods of inactivity. To keep working, click the button below.
            </p>
            <p>
              <strong>Logging off in {sessionTimeRemaining}</strong>
            </p>
          </ConfirmModal>
        )}
      </session-timeout>
    );
  }
}

const mapStateToProps = state => ({
  showTimeoutModal: getTimeoutModal(state),
  sessionTimeRemaining: getSessionTimeRemaining(state)
});

SessionTimeout = connect(
  mapStateToProps,
  {
    setSessionTimeRemaining: sessionTimeRemaining =>
      setSessionTimeRemaining(sessionTimeRemaining),
    setSessionTimeoutModal: showTimeoutModal =>
      setSessionTimeoutModal(showTimeoutModal),
    setActionIndicator: indicator => setActionIndicator(indicator)
  }
)(SessionTimeout);

export default withCookies(SessionTimeout);
