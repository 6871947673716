import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Solution from "./solution";
import { truncate } from "../../Util/Helper";

class Category extends Component {
  render() {
    return (
      <article>
        <div>
          <div className="SolutionsCategorySubHeader">
            {this.props.category.name === "Featured Apps"
              ? "More Featured Apps"
              : truncate(this.props.category.name, 30)}
          </div>
          <div className="row SolutionsCategory">
            {this.props.category.products
              ? this.props.category.products.map((solution, index) => (
                  <Solution solution={solution} />
                ))
              : ""}
            <div className="col-sm-4 col-md-3 col-lg-2">
              <div className="FeauxTainer">
                <NavLink
                  id="btnMore"
                  to={
                    this.props.category.name === "Featured Apps"
                      ? "/storefront/featured"
                      : "/storefront?categories=" + this.props.category.id
                  }
                  className="MorePartnersLink"
                >
                  <div className="MorePartnersBox">
                    <div className="MorePartners">
                      View All {truncate(this.props.category.name, 50)}
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Category;
