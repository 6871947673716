import React, { Component } from "react";
import { connect } from "react-redux";
import "../components/managemarketplace/managemarketplace.scss";
import { fetchPartners } from "../store/actions";
import ManageNavigation from "../components/managemarketplace/ManageNavigation";
import ManagePartners from "../components/managemarketplace/ManagePartners";
import BusyIndicator from "../components/common/busyindicator";

class ManageMarketplace extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchPartners());
  }

  render() {
    return (
      <React.Fragment>
        <BusyIndicator />
        <div className="flexcontainer">
          <ManageNavigation />
          <ManagePartners />
        </div>
      </React.Fragment>
    );
  }
}
export default connect()(ManageMarketplace);
