import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { HOST } from "../../config/environment";
import { FetchWrapper } from "../../Util/FetchWrapper";
import Button from "@paycor/picl/button";
import { InputField, TextField, Select } from "@paycor/picl/form-elements";
import Banner from "@paycor/picl/banner";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { getUserProfile } from "../../store/root-reducer";
import {
    validateEmail,
    validateRequired,
    InputMasks,
} from "../../Util/validators";
import "./contactus.scss";
import MetaTags from "react-meta-tags";

class Contactus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmation: false,
            isValidForm: true,
            errorMessage: "",
            FirstName: "",
            LastName: "",
            Email: "",
            Comments: "",
            Error_FirstName: "",
            Error_LastName: "",
            Error_Comments: "",
        };

        this.onClickSubmit = this.onClickSubmit.bind(this);
        this.handleRefTypeChange = this.handleRefTypeChange.bind(this);
    }

    getModalData() {
        let { userProfile } = this.props;
        let firstName = "";
        let lastName = "";
        let email = "";

        if (userProfile) {
            if (userProfile.firstName) firstName  = userProfile.firstName;
            if (userProfile.lastName) lastName  = userProfile.lastName;
            if (userProfile.email) email = userProfile.email;
        }

        let modalData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
        };

        return modalData;
    }

    componentDidUpdate(prevProps) {
        let prevId = "";

        if (prevProps.userProfile) prevId = prevProps.userProfile.userId;

        const { userProfile } = this.props;

        if (
            userProfile &&
            userProfile.userId &&
            userProfile.userId !== prevId
        ) {
            let modalData = this.getModalData();

            this.setState({
                FirstName: modalData.firstName,
                LastName: modalData.lastName,
                Email: modalData.email,
            });
        }
    }
    componentDidMount() {
        if (this.props.userProfile) {
            let modalData = this.getModalData();

            this.setState({
                FirstName: modalData.firstName,
                LastName: modalData.lastName,
                Email: modalData.email,
            });
        }
    }

    onClickSubmit() {
        const { FirstName, LastName, Email, Comments, referenceType } =
            this.state;

        // Validate each field.
        const errFirstName = validateRequired(
            FirstName,
            "First name is required"
        );
        const errLastName = validateRequired(LastName, "Last name is required");
        const errEmail = validateEmail(
            Email,
            "Email address is invalid",
            "Email address is required"
        );
        const errComments = validateRequired(Comments, "Comments are required");
        const errRefType = validateRequired(referenceType, "Select one value");

        // Determine if there were any errors.
        const error = Boolean(
            errFirstName.concat(errLastName, errEmail, errComments, errRefType)
        );

        this.setState({
            isValidForm: !error,
            Error_FirstName: errFirstName,
            Error_LastName: errLastName,
            errorMessage: errEmail,
            Error_RefType: errRefType,
            Error_Comments: errComments,
        });

        if (error) return;

        //call backend API
        let contactUsData = {
            firstName: FirstName,
            lastName: LastName,
            email: Email,
            description: Comments,
            referenceType: referenceType,
        };

        let url = HOST.ENDPOINTS.MP_FAQCONTACTUS;
        let marketplaceApi = new FetchWrapper(url, contactUsData);

        marketplaceApi.post(
            () => {
                console.log("Submission sent successfully.");
                this.setState({
                    show: false,
                    showConfirmation: true,
                    isValidForm: true,
                });
            },
            () => {
                this.setState({
                    isNetworkError: true,
                    show: true,
                    showConfirmation: false,
                });
                console.log(
                    "No internet connection found. App is running in offline mode."
                );
            }
        );
    }

    setConfirmation(status, message) {
        return (
            <Banner
                id="divNetworkError"
                status={status}
                show={true}
                message={message}
            />
        );
    }
    
    handleRefTypeChange(selectedOption) {
        this.setState({ referenceType: selectedOption.id });
    }

    render() {
        let {
            isValidForm,
            isNetworkError,
            showConfirmation,
            FirstName,
            LastName,
            Email,
            errorMessage,
            Comments,
            Error_FirstName,
            Error_LastName,
            Error_Comments,
        } = this.state;

        let options = [
            {
                id: "1",
                label: "I'm a Paycor client",
                value: "I'm a Paycor client",
            },
            {
                id: "2",
                label: "I'm a Paycor partner",
                value: "I'm a Paycor partner",
            },
            {
                id: "3",
                label: "I want to learn more about Paycor",
                value: "I want to learn more about Paycor",
            },
            {
                id: "4",
                label: "None of these apply",
                value: "None of these apply",
            },
        ];

        let confirmation = "";
        if (showConfirmation)
            confirmation = this.setConfirmation(
                Banner.Status.SUCCESS,
                "Success! Thanks for reaching out."
            );
        else if (!isValidForm)
            confirmation = this.setConfirmation(
                Banner.Status.ERROR,
                "Missing required field(s). Please complete the fields below."
            );
        else if (isNetworkError)
            confirmation = this.setConfirmation(
                Banner.Status.ERROR,
                "Sorry, we can't submit your information right now. Please wait a bit and try again."
            );

        return (
            <React.Fragment>
                <div className="contactusForm">
                    <MetaTags>
                          <title>Contact Paycor</title>
                        <meta
                            name="description"
                            content="Can't find the answer you need? Have a suggestion to share? Complete this form to connect with the team at Paycor."
                        />
                    </MetaTags>
                    <div className="BrowseBack">
                        <NavLink id="browseback" to="/help">
                            &laquo; Back to Help
                        </NavLink>
                    </div>

                    <div className="Contact-Us-Header"> Contact Us</div>
                    {confirmation}
                    {showConfirmation || isNetworkError ? (
                        ""
                    ) : (
                        <div>
                            <div className="All-fields-are-requi">
                                All fields are required.
                            </div>

                            <div className="row">
                                <div className="pseudo-col">
                                    <InputField
                                        id="txtFirstName"
                                        value={FirstName}
                                        label={"First Name"}
                                        className="Textbox1"
                                        characterLimit={50}
                                        inputMask={InputMasks.Name}
                                        errorMessage={Error_FirstName}
                                        onChange={(value) => {
                                            this.setState({ FirstName: value });
                                        }}
                                    />
                                </div>
                                <div className="pseudo-spc" />
                                <div className="pseudo-col">
                                    <InputField
                                        id="txtLastName"
                                        value={LastName}
                                        label={"Last Name"}
                                        className="Textbox1"
                                        characterLimit={50}
                                        inputMask={InputMasks.Name}
                                        errorMessage={Error_LastName}
                                        onChange={(value) => {
                                            this.setState({ LastName: value });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-9">
                                    <InputField
                                        id="txtEmail"
                                        value={Email}
                                        label={"Email"}
                                        className="Textbox2"
                                        characterLimit={254}
                                        inputMask={InputMasks.Email}
                                        errorMessage={errorMessage}
                                        onChange={(value) => {
                                            this.setState({ Email: value });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="pseudo-col">
                                <Select
                                        options={options}
                                        id="txtRefType"
                                        label="Which best describes you?"
                                        placeholder={"Select one"}
                                        value={this.state.referenceType}
                                        selected={{
                                            id: this.state.referenceType,
                                            value: this.state.referenceType,
                                        }}
                                        onChange={this.handleRefTypeChange}
                                        errorMessage={this.state.Error_RefType}
                                    ></Select>
                                    
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <TextField
                                        id="txtComments"
                                        value={Comments}
                                        label={"What can we help you with?"}
                                        className="Textbox3"
                                        height="207px"
                                        characterLimit={1000}
                                        errorMessage={Error_Comments}
                                        onChange={(value) => {
                                            this.setState({ Comments: value });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="submitbuttondiv">
                                <Button
                                    aria-label="primary button"
                                    busy={false}
                                    disabled={false}
                                    type="primary"
                                    onClick={this.onClickSubmit}
                                    children="Submit"
                                    className="modalButtons"
                                    show={showConfirmation}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userProfile: getUserProfile(state),
});

export default connect(mapStateToProps, actions)(Contactus);
