import React, { Component } from "react";
import HelpContactus from "../components/FAQ/contactus";
import BusyIndicator from "../components/common/busyindicator";

class ContactUsHelp extends Component {
  render() {
    return (
      <React.Fragment>
        <BusyIndicator />
        <div className="App MainContent page-row page-row-expanded">
          <HelpContactus />
        </div>
      </React.Fragment>
    );
  }
}
export default ContactUsHelp;
