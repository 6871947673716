import React, { Component } from "react";
import { connect } from "react-redux";
import { setProductFilter, setProductPage } from "../../store/actions";
import { RadioGroup, Radio } from "@paycor/picl/form-elements";

class FilterNav extends Component {
    render() {
        const { dispatch, filter } = this.props;

        return (
            <React.Fragment>
                <div
                    className="CategoriesHeader paddingtop"
                    style={{ fontSize: "28px", fontWeight: "bold" }}
                >
                    Filters
                </div>
                <RadioGroup
                    onSelect={(filter) => {
                        dispatch(setProductFilter(filter.id));
                        dispatch(setProductPage(0));
                    }}
                >
                    <Radio
                        id="All"
                        label="All Apps"
                        checkedStatus={
                            filter === "All"
                                ? Radio.StatusTypes.CHECKED
                                : Radio.StatusTypes.NONE
                        }
                    />
                    <Radio
                        id="Featured"
                        label="Featured"
                        checkedStatus={
                            filter === "Featured"
                                ? Radio.StatusTypes.CHECKED
                                : Radio.StatusTypes.NONE
                        }
                    />
                </RadioGroup>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { filter } = state.productData;
    return { filter };
};
export default connect(mapStateToProps)(FilterNav);
