// VERY IMPORTANT!! The react-app-polyfill must be the first import or this app will
// crash in IE11. Please do not move it.
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/configure-store";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "./index.scss";
import "paycor-bootstrap/dist/css/paycor-bootstrap.min.css";
import "paycor-bootstrap/dist/css/paycor-bootstrap-theme.min.css";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <App />
        <Redirect to="/PageNotFound" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
