import React, { Component } from "react";
import { connect } from "react-redux";
import SiteFeature from "../components/home/sitefeature";
import CategorySolutions from "../components/home/categorysolutions";
import Banner from "../components/home/banner";
import FeaturedApps from "../components/home/featuredApps";
import {
    setProductFilter,
    clearAllCategories,
    resetSearch,
    setSearchTerm,
    fetchProducts,
} from "../store/actions";

class Home extends Component {
    componentDidUpdate(prevProps) {
        const { dispatch, currentLocationPath, page, products } = this.props;
        if (currentLocationPath !== prevProps.currentLocationPath) {
            dispatch(setProductFilter("All"));
            dispatch(clearAllCategories());
            dispatch(setSearchTerm(""));
            dispatch(resetSearch());
            dispatch(fetchProducts(page > 0 ? products : []));
        }
    }

    render() {
        return (
            <div>
                <Banner />
                <div className="backgroundwhite">
                    <div className="App MainContentPadding">
                        <SiteFeature />
                    </div>
                </div>
                <div className="backgroundgrey">
                    <div className="App MainContentPadding">
                        <FeaturedApps />
                        <CategorySolutions categoryLimit="999" />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const { searchTerm, currentTerm, isSearching, filter, products, page } =
        state.productData;
    const { selectedCategories } = state.categoryData;
    const { currentLocationPath } = state;
    return {
        currentTerm,
        searchTerm,
        selectedCategories,
        currentLocationPath,
        isSearching,
        filter,
        products,
        page,
    };
};

export default connect(mapStateToProps)(Home);
