const env = process.env;

const HOST_LOCAL = {
    API_DEVELOPER_PORTAL: "https://developers.paycor.com/",
    API_DEVELOPER_PORTAL_API: "https://developers.paycor.com/explore/",
    APIM_KEY: "9346c58fdf5f46028123334cecbd56e9", // THIS IS A QTR KEY!!
    APP_BASE: env.REACT_APP_LOCAL_PROXY,
    HCM_BASE: env.REACT_APP_HCM_PROXY,
    APIM_BASE: env.REACT_APP_APIM_PROXY,
    ACCESS_CONTROL_MAX_AGE: "600",
    PARTNER_REGISTRATION: "http://partnerportal.paycor.com/",
};

const HOST_PRODUCTION = {
    API_DEVELOPER_PORTAL: "#{DeveloperPortal}",
    API_DEVELOPER_PORTAL_API: "#{DeveloperPortal}explore/",
    APIM_KEY: "#{Project-APIm-Key}",
    APP_BASE: "#{HCM_BaseURL_FQDN}",
    HCM_BASE: "#{HCM_BaseURL_FQDN}",
    APIM_BASE: "#{APIM_BaseURL_FQDN}",
    PRODUCT_PAGE_SIZE: "#{ProductPageSize}",
    ACCESS_CONTROL_MAX_AGE: "#{CORS_AccessControlMaxAgeSec}",
    PARTNER_REGISTRATION: "http://partnerportal.paycor.com/",
};

export let ENV = "#{Octopus.Environment.Name | tolower}";
export const UPPER_ENV = [
    "development-rc",
    "quarterly",
    "rc",
    "qa",
    "staging",
    "performance",
    "internal",
    "demo",
    "production",
];
export let HOST = HOST_PRODUCTION;

if (env.NODE_ENV === "development" || env.REACT_APP_ISLOCAL !== "false") {
    ENV = "local";
    HOST = HOST_LOCAL;
}

const mp_base_uri = HOST.APIM_BASE + env.REACT_APP_MARKETPLACE_SVC_SUFFIX;

const HOST_DEFAULT = {
    ENDPOINTS: {
        MP_PRODUCTS: mp_base_uri + "products",
        MP_SEARCH: mp_base_uri + "products/search",
        MP_CATEGORIES: mp_base_uri + "categories",
        MP_GETSTARTED: mp_base_uri + "getstarted",
        MP_FAQCONTACTUS: mp_base_uri + "contactus",
        MP_PRODUCT_REQUESTS: mp_base_uri + "productrequests",
        MP_FEEDBACK: mp_base_uri + "feedback",
        ACCTS: HOST.HCM_BASE + "/accounts/",
        COMPANY_PRIVATE: HOST.APIM_BASE + "/companyprivate/",
        SIGNIN: HOST.HCM_BASE + "/authentication/signin",
        SIGNOUT: HOST.HCM_BASE + "/authentication/logout",
        REG_PARTNERS: HOST.APIM_BASE + env.REACT_APP_PARTNERS_SUFFIX,
        REG_PARTNERS_ADD: HOST.APIM_BASE + env.REACT_APP_PARTNERS_ADD_SUFFIX,
        PARTNER_TYPES: HOST.APIM_BASE + env.REACT_APP_PARTNER_TYPES_SUFFIX,
        USERINFO: mp_base_uri + "userinfo",
        PENDO: HOST.APIM_BASE + env.REACT_APP_PENDO_SUFFIX,
        REG_USERSBYPARTNERS:
            HOST.APIM_BASE + env.REACT_APP_PARTNERS_USERS_SUFFIX,
        REG_USER_INVITE: HOST.APIM_BASE + env.REACT_APP_USER_INVITE_SUFFIX,
    },
    PARTNER_PAGE_SIZE: 100,
    PRODUCT_PAGE_SIZE: 15,
    ACCESS_CONTROL_MAX_AGE: HOST.ACCESS_CONTROL_MAX_AGE,
};

HOST = {
    ...HOST_DEFAULT,
    ...HOST,
};

// The time before session ends to show the Timeout Modal (in seconds)
export const SESSION_TIMEOUT_TIME_TO_SHOW_MODAL = 120;
export const MARKETPLACE_PRIV = 133;
export const SALESFORCE_PRIV = 730;
