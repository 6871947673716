import React, { Component } from "react";

class ManageNavigation extends Component {
  render() {
    return (
      <div className="manageNavigation">
        <div className="manageNavHeader">Manage Marketplace</div>
        <div className="menuItem">Partners</div>
      </div>
    );
  }
}

export default ManageNavigation;
