import React, { Component } from "react";
import { connect } from "react-redux";
import { Checkbox } from "@paycor/picl/form-elements";
import {
    setPartnerTypeForPartner,
    clearPartnerTypeForPartner,
} from "../../store/actions";

class PartnerTypeList extends Component {
    render() {
        const { partnerTypes, selectedTypes, dispatch } = this.props;
        if (partnerTypes) {
            return (
                <React.Fragment>
                    <div className="FilterListContainer">
                        <ul>
                            {partnerTypes.map((pt) => {
                                var isSelected =
                                    selectedTypes != null
                                        ? selectedTypes.find(
                                              (ptype) =>
                                                  ptype === pt.partnerType
                                          )
                                        : null;
                                return (
                                    <div
                                        key={pt.partnerTypeId}
                                        className="categorycheckbox"
                                    >
                                        <Checkbox
                                            checkedStatus={
                                                isSelected
                                                    ? Checkbox.StatusTypes
                                                          .CHECKED
                                                    : Checkbox.StatusTypes.NONE
                                            }
                                            id={pt.partnerType}
                                            label={pt.partnerType}
                                            onChange={(selected) => {
                                                if (selected.checked) {
                                                    dispatch(
                                                        setPartnerTypeForPartner(
                                                            selected.id
                                                        )
                                                    );
                                                } else {
                                                    dispatch(
                                                        clearPartnerTypeForPartner(
                                                            selected.id
                                                        )
                                                    );
                                                }
                                            }}
                                            classname="categorycheckbox"
                                        />
                                    </div>
                                );
                            })}
                        </ul>
                    </div>
                </React.Fragment>
            );
        }
        return "";
    }
}

const mapStateToProps = (state) => {
    const { partnerTypes } = state.partnerTypeData;
    return { partnerTypes };
};

export default connect(mapStateToProps)(PartnerTypeList);
