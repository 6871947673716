import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchPartner,
  clearPartner,
  fetchPartnerTypes
} from "../store/actions";
import ManageNavigation from "../components/managemarketplace/ManageNavigation";
import PartnerDetail from "../components/managemarketplace/PartnerDetail";
import BusyIndicator from "../components/common/busyindicator";

class ManagePartnerDetail extends Component {
  componentDidMount() {
    const { partnername } = this.props.match.params;
    const { dispatch } = this.props;

    if (partnername) {
      dispatch(fetchPartner(partnername));
    } else {
      dispatch(clearPartner());
    }
    dispatch(fetchPartnerTypes());
  }

  render() {
    return (
      <React.Fragment>
        <BusyIndicator />
        <div className="page-row page-row-expanded">
          <div className="row flexcontainer">
            <ManageNavigation />
            <PartnerDetail />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(ManagePartnerDetail);
