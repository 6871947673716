import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Drawer from "@paycor/picl/drawer";
import { Close, BurgerMobile } from "@paycor/picl/icon";
import { HOST, MARKETPLACE_PRIV } from "../../config/environment";
import { getUserProfile } from "../../store/root-reducer";
import * as actions from "../../store/actions";
import Search from "./search";
import SearchPopup from "./searchpopup";
import paycorlogo from "../../images/paycor_logo_grey.svg";
import "./header.scss";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLink: null,
            userProfile: null,
            isLoggedIn: false,
            drawerOpen: false,
        };

        this.props.loadUserMetaData();
    }

    HandleSignInClick(e) {
        var returnUrl = escape(window.location.href);
        let signinurl = `${HOST.ENDPOINTS.SIGNIN}?ReturnUrl=${returnUrl}`;

        e.preventDefault();
        window.location.href = signinurl;
    }

    Greeting() {
        const signoutUrl = HOST.ENDPOINTS.SIGNOUT;
        let loggedInUser = "";
        let marketPlacePriv = "";
        const { userProfile } = this.props;

        if (userProfile) {
            loggedInUser = userProfile.firstName;
            marketPlacePriv = userProfile.privileges;
        }

        const greeting = loggedInUser ? (
            <React.Fragment>
                Welcome, {loggedInUser}
                {marketPlacePriv &&
                marketPlacePriv.some(
                    (item) =>
                        item.privilegeId === MARKETPLACE_PRIV && item.allowed
                ) ? (
                    <NavLink
                        id="lnkManageMarketPlace"
                        className="NavTopLink"
                        to="/managemarketplace"
                    >
                        Manage Partners
                    </NavLink>
                ) : (
                    ""
                )}
            </React.Fragment>
        ) : (
            ""
        );

        let signout = loggedInUser ? (
            <a id="lnkSignOut" className="NavTopLink" href={signoutUrl}>
                Sign Out
            </a>
        ) : (
            <a
                id="lnkSignIn"
                className="NavTopLink"
                href={HOST.ENDPOINTS.SIGNIN}
                onClick={(e) => this.HandleSignInClick(e)}
            >
                Sign In
            </a>
        );

        return (
            <p className="hidden-xs">
                {greeting}
                <a
                    id="link2Paycor"
                    className="NavTopLink"
                    href="https://www.paycor.com"
                >
                    Paycor
                </a>
                <NavLink id="lnkTopHelp" className="NavTopLink" to="/help">
                    Help
                </NavLink>
                {signout}
            </p>
        );
    }

    closeDrawer() {
        this.setState({ drawerOpen: false });
    }

    renderDrawer() {
        const { drawerOpen } = this.state;
        const { firstName: isLoggedIn } = this.props.userProfile;
        const { history } = this.props;

        return (
            <Drawer isOpen={drawerOpen}>
                <div style={{ width: "85vw" }}>
                    <div
                        style={{
                            backgroundColor: "#00b3f0",
                            color: "white",
                            padding: "16px 10px",
                            fontSize: "14px",
                            fontWeight: "normal",
                        }}
                    >
                        Menu
                        <div style={{ display: "inline", float: "right" }}>
                            {/* eslint-disable-next-line */}
                            <a
                                className="drawerLink"
                                onClick={() => this.closeDrawer()}
                            >
                                <Close />
                            </a>
                        </div>
                    </div>
                    <div
                        className="topLink"
                        onClick={() => {
                            history.push("/storefront");
                            this.closeDrawer();
                        }}
                    >
                        Browse Apps
                    </div>
                    <div
                        className="topLink"
                        onClick={() => {
                            window.location.href = HOST.API_DEVELOPER_PORTAL;
                            this.closeDrawer();
                        }}
                    >
                        Developer Portal
                    </div>
                    <div
                        style={{
                            paddingTop: "12px",
                            paddingBottom: "11px",
                            backgroundColor: "#6a7280",
                        }}
                    >
                        <div
                            className="subLink"
                            onClick={(e) =>
                                isLoggedIn
                                    ? history.push(HOST.ENDPOINTS.SIGNOUT)
                                    : this.HandleSignInClick(e)
                            }
                        >
                            Sign {isLoggedIn ? "out" : "in"}
                        </div>
                        <div
                            className="subLink"
                            onClick={() => {
                                history.push("/help");
                                this.closeDrawer();
                            }}
                        >
                            Help
                        </div>
                        <div
                            className="subLink"
                            onClick={() => {
                                history.push("/contactus");
                                this.closeDrawer();
                            }}
                        >
                            Contact Us
                        </div>
                    </div>
                </div>
            </Drawer>
        );
    }

    render() {
        return (
            <div>
                <div className="visible-xs">{this.renderDrawer()}</div>
                <div className="NavTop row">
                    <div className="LeftNavTop col-sm-3 col-xs-12">
                        <a id="lnkPaycor" href="https://www.paycor.com/">
                            <img
                                src={paycorlogo}
                                className="Paycor-Logo"
                                alt="Paycor"
                            />
                        </a>
                    </div>
                    <div className="RightNavTop col-sm-9">
                        {this.Greeting()}
                    </div>
                </div>
                <div className="NavMain row">
                    <div className="LeftNavText col-sm-2 col-xs-8">
                        {/* eslint-disable-next-line */}
                        <div className="drawerLinkContainer">
                            <a
                                className="drawerLink"
                                onClick={() => {
                                    this.setState({ drawerOpen: true });
                                }}
                            >
                                <BurgerMobile
                                    size="20px"
                                    style={{
                                        top: "4px",
                                        marginRight: "8px",
                                        marginLeft: "4px",
                                    }}
                                />
                            </a>
                        </div>
                        <NavLink
                            id="lnkMarketPlace"
                            className="HomeLink"
                            to="/"
                        >
                            Marketplace
                        </NavLink>
                    </div>
                    <div className="RightNavText col-sm-10 col-xs-4">
                        <div className="LinkContainer hidden-xs">
                            <NavLink
                                id="lnkBrowseSolutions"
                                to="/storefront"
                                className="HeaderLink"
                                activeClassName="ActiveHeaderLink"
                            >
                                Browse Apps
                            </NavLink>
                        </div>
                        <div className="LinkContainer hidden-xs">
                            <a
                                id="lnkDevPortalTop"
                                className="HeaderLink"
                                href={HOST.API_DEVELOPER_PORTAL}
                            >
                                Developer Portal
                            </a>
                        </div>
                        <Search />
                    </div>
                </div>
                <SearchPopup classParm="visible-xs" />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userProfile: getUserProfile(state),
});

export default withRouter(connect(mapStateToProps, actions)(Header));
