import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Table from "@paycor/picl/table";
import Button from "@paycor/picl/button";
import { Utils } from "@paycor/picl/core";
import { fetchPartners } from "../../store/actions";

class ManageNavigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnSorting: {
                name: Table.HeaderCell.SortDirection.NONE,
                status: Table.HeaderCell.SortDirection.NONE,
                types: Table.HeaderCell.SortDirection.NONE,
            },
        };

        this.handleSort = this.handleSort.bind(this);
    }

    handleSort(e) {
        let { columnSorting } = this.state;
        if (columnSorting[e.columnId]) {
            let cols = { ...columnSorting };
            Object.keys(cols).forEach((key) => {
                cols[key] = Table.HeaderCell.SortDirection.NONE;
            });

            columnSorting = {
                ...cols,
                [e.columnId]: e.direction,
            };

            // convert the columnSorting object to a sort string that can be sent to the ajax request
            let sort = "";
            cols = { ...columnSorting };
            Object.keys(cols).forEach((key) => {
                if (cols[key] !== Table.HeaderCell.SortDirection.NONE) {
                    sort = `${sort === "" ? "" : sort + ","}${
                        cols[key] === Table.HeaderCell.SortDirection.ASC
                            ? ""
                            : "-"
                    }${key}`;
                }
            });

            const { dispatch } = this.props;
            // TODO: don't use 1 as the first argument, go with the current page property.
            dispatch(fetchPartners(1, sort));

            this.setState({
                columnSorting: columnSorting,
            });
        }
    }

    sortData(id, direction) {
        const { partners } = this.props;
        return Utils.Sorting.ObjectSort(partners, id, direction);
    }

    render() {
        const { columnSorting } = this.state;
        const { partners, isFetching, history } = this.props;

        let editurl = "/viewpartnerdetail/";
        return (
            <div className="manageContent">
                <div className="flexcontainer">
                    <div className="pageHead" style={{ fontWeight: "300" }}>
                        Partners
                    </div>
                    <div className="btnContainer">
                        <Button
                            id="btnAddPartner"
                            icon={"add"}
                            aria-label="button"
                            busy={this.state.busy}
                            type="secondary-action"
                            onClick={() => history.push("/managepartnerdetail")}
                        >
                            Partner
                        </Button>
                    </div>
                </div>

                <Table
                    bordered={false}
                    isResizable={false}
                    onSortClick={this.handleSort}
                    isSelectable={false}
                    isBusy={isFetching}
                >
                    <Table.Header>
                        <Table.HeaderCell
                            id={"name"}
                            sortDirection={columnSorting["name"]}
                            columnWidth={"715px"}
                        >
                            Name
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            id={"status"}
                            sortDirection={columnSorting["status"]}
                            columnWidth={"150px"}
                        >
                            Status
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            id={"types"}
                            sortDirection={columnSorting["types"]}
                            columnWidth={"150px"}
                        >
                            Types
                        </Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {partners
                            ? partners.map((data, key) => {
                                  return (
                                      <Table.Row id={key} key={key}>
                                          <Table.Cell>
                                              <NavLink
                                                  id="lnkPartnerEdit"
                                                  to={editurl + data.name}
                                              >
                                                  {" "}
                                                  {data.name}
                                              </NavLink>
                                          </Table.Cell>
                                          <Table.Cell>{data.status}</Table.Cell>
                                          <Table.Cell>
                                              {data.types ? data.types[0] : ""}
                                          </Table.Cell>
                                      </Table.Row>
                                  );
                              })
                            : ""}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { partners, sort, page, isFetching } = state.partnerData;
    return { partners, sort, page, isFetching };
};

export default withRouter(connect(mapStateToProps)(ManageNavigation));
