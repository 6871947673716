import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPartner } from "../store/actions";
import ManageNavigation from "../components/managemarketplace/ManageNavigation";
import ViewPartnerDetail from "../components/managemarketplace/ViewPartnerDetail";
import BusyIndicator from "../components/common/busyindicator";

class ViewManagePartnerDetail extends Component {
  componentDidMount() {
    const { partnername } = this.props.match.params;
    const { dispatch } = this.props;
    if (partnername) {
      dispatch(fetchPartner(partnername));
    }
  }

  render() {
    return (
      <React.Fragment>
        <BusyIndicator />
        <div className="page-row page-row-expanded">
          <div className="row flexcontainer">
            <ManageNavigation />
            <ViewPartnerDetail />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(ViewManagePartnerDetail);
