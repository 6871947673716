import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { truncate } from "../../Util/Helper";

class Solution extends Component {
  render() {
    return (
      <div key={this.props.solution.id} className="categoryItem col-sm-4 col-md-3 col-lg-2">
        <NavLink
          id={this.props.solution.id + "_lnk"}
          to={"/products/" + this.props.solution.id}
          className="nohoverLink"
        >
          <div className="FeauxTainer">
            <div className="PartnerBorder">
              <img
                className="PartnerImage"
                alt={truncate(this.props.solution.name, 45)}
                src={this.props.solution.image.portraitThumbnailUrl}
              />
              <div className="partnerNameDiv">
                <div className="Ascensus" title={this.props.solution.name}>
                  {truncate(this.props.solution.name, 45)}
                </div>
              </div>
              <div className="KProviders">
                {truncate(this.props.solution.categoryName, 26)}
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    );
  }
}
export default Solution;
