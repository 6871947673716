import React, { Component } from "react";
import { connect } from "react-redux";
import StoreFrontLayout from "../components/storefront/StoreFrontLayout";
import {
  fetchProducts,
  setProductFilter,
  setProductPage,
  clearAllCategories,
  toggleCategory
} from "../store/actions";
import queryString from "query-string";
import { getQueryString } from "../Util/Helper";

class StoreFront extends Component {
  componentDidUpdate(prevProps) {
    const {
      dispatch,
      currentLocationPath,
      searchTerm,
      isSearching,
      filter,
      page,
      products,
      selectedCategories
    } = this.props;

    // The route changed to this page. Force a reset of the load context.
    if (currentLocationPath !== prevProps.currentLocationPath) {
      dispatch(setProductPage(0));
      //if prev page is product then dont clear the categories and reset the Product Filter
      if (!prevProps.currentLocationPath.includes("/products/")) {
        dispatch(
          setProductFilter(
            currentLocationPath === "/storefront/featured" ? "Featured" : "All"
          )
        );
        dispatch(clearAllCategories());
      }
      //if coming from seach page, do  dispatch with search term set
      if (currentLocationPath.includes("/search")) {
        dispatch(fetchProducts(page > 0 ? products : []));
      }
      if (selectedCategories.length === 0) {
        const url = getQueryString(window.location.href);
        const values = queryString.parse(url);
        const categories = values.categories
          ? values.categories.split(",")
          : null;

        if (categories && categories.length > 0) {
          categories.forEach(category => dispatch(toggleCategory(category)));
        }
      }
    }
    if (
      selectedCategories !== prevProps.selectedCategories ||
      filter !== prevProps.filter ||
      isSearching !== prevProps.isSearching ||
      page !== prevProps.page ||
      searchTerm !== prevProps.searchTerm
    ) {
      dispatch(fetchProducts(page > 0 ? products : []));
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="App page-row page-row-expanded">
          <StoreFrontLayout />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    searchTerm,
    currentTerm,
    isSearching,
    filter,
    products,
    page
  } = state.productData;
  const { selectedCategories } = state.categoryData;
  const { currentLocationPath } = state;
  return {
    currentTerm,
    searchTerm,
    selectedCategories,
    currentLocationPath,
    isSearching,
    filter,
    products,
    page
  };
};

export default connect(mapStateToProps)(StoreFront);
