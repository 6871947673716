import React, { Component } from "react";
import "../common/banner.scss";
import MetaTags from "react-meta-tags";

class Banner extends Component {
  render() {
    return (
      <div>
        <MetaTags>
            <title>Paycor Marketplace</title>
                      
          <meta
            name="description"
            content="Paycor's Marketplace gives you access to our partner technology and service providers. These are integrated products and valuable service offerings we recommend. Go to Browse Apps to discover new ways to streamline your business."
          />
        </MetaTags>

        <div className="BannerImg">
          <div className="MarketplacehomeHeader">Moving Beyond HCM</div>
          <div className="MarketplacehomeHeader2">
            Making a Difference Together
          </div>
        </div>
        <div className="BannerTextSection">
          <div className="BannerText">
            Marketplace is a collection of our industry-leading partners and
            products.
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
