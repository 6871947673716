export const ROOT_ACTION_TYPES = {
  SET_CURRENT_LOCATION_PATH: "Navigation: Location Change",
  SET_FORM_DIRTY: "Form: Set Dirty",

  //general
  ERROR_CANT_LOAD_PAGE: `Sorry, we can't load this page right now. Please wait a bit and try again.`,

  //security
  LOAD_USER_META_DATA_REQUEST: "User: Load Request",
  LOAD_USER_META_DATA_RESPONSE: "User: Load Response",
  LOAD_USER_META_DATA_FAILURE: "User: Load Failure",

  SET_USER_PROFILE: "User: Set Profile",
  SHOW_SESSION_TIMEOUT_MODAL: "Session: Show Timeout Modal",
  SET_SESSION_TIME_REMAINING: "Session: Set Time Remaining",
  SHOW_ACTION_INDICATOR: "Action In Progress",
  SHOW_GLOBAL_ERROR_INDICATOR: "Error Occurred",
  CLEAR_GLOBAL_ERROR_INDICATOR: "Error Resolved",

  LOAD_CATEGORY_REQUEST: "Category: Load Request",
  LOAD_CATEGORY_RESPONSE: "Category: Load Response",
  LOAD_CATEGORY_FAILURE: "Category: Load Failure",
  LOAD_PRODUCT_REQUEST: "Product: Load Request",
  LOAD_PRODUCT_RESPONSE: "Product: Load Response",
  LOAD_PRODUCT_FAILURE: "Product: Load Failure",
  SET_PRODUCT_FILTER: "Product: Set Filter",
  SET_PRODUCT_PAGE: "Product: Set Page",

  LOAD_PARTNER_REQUEST: "Partner: Load Request",
  LOAD_PARTNER_RESPONSE: "Partner: Load Response",
  LOAD_PARTNER_FAILURE: "Partner: Load Failure",
  LOAD_PARTNER_BYNAME_REQUEST: "Partner (Name): Load Request",
  LOAD_PARTNER_BYNAME_RESPONSE: "Partner (Name): Load Response",
  LOAD_PARTNER_BYNAME_FAILURE: "Partner (Name): Load Failure",
  LOAD_PARTNER_USERS_REQUEST: "Partner Users: Load Request",
  LOAD_PARTNER_USERS_RESPONSE: "Partner Users: Load Response",
  LOAD_PARTNER_USERS_FAILURE: "Partner Users: Load Failure",
  CLEAR_PARTNER: "Partner (Name): Clear Partner",
  SET_PARTNER_TYPE: "Partner (Name): Set Partner Type",
  CLEAR_PARTNER_TYPE: "Partner (Name): Clear Partner Type",

  LOAD_PARTNER_TYPE_FAILURE: "Partner Types: Load Failure",
  LOAD_PARTNER_TYPE_REQUEST: "Partner Types: Load Request",
  LOAD_PARTNER_TYPE_RESPONSE: "Partner Types: Load Response",

  SEARCH_POPUP_TOGGLE: "Search: Toggle Popup",
  SEARCH_INITIATE: "Search: Initiate",
  SEARCH_RESET: "Search: Reset",
  SEARCH_SET_TERM: "Search: Set Search Term",

  CATEGORY_TOGGLE: "Category: Toggle Selection",
  CATEGORY_INITIATE: "Category: Set Selection Initial State",
  CATEGORY_CLEAR_ALL: "Category: Selection Reset",

  GET_MORE_INITIATE: "Product: Load Next Page",

  //My Success Team
  LOAD_SUCCESS_TEAM_REQUEST: "LOAD/SUCCESS_TEAM/REQUEST",
  LOAD_SUCCESS_TEAM_RESPONSE: "LOAD/SUCCESS_TEAM/RESPONSE",
  LOAD_SUCCESS_TEAM_FAILURE: "LOAD/SUCCESS_TEAM/FAILURE"
};

export const MOCK_DATA = {
  Records: [
    {
      SpecialistType: "Payroll",
      SpecialistName: "William Moss",
      SpecialistEmail: "wmoss@paycor.com",
      SpecialistPhone: "(855) 565-3285",
      SpecialistExtension: "12345"
    },
    {
      SpecialistType: "HRBenefitCOE",
      SpecialistName: "Annette Schwartz",
      SpecialistEmail: "aschwartz@paycor.com",
      SpecialistPhone: "(855) 565-3285",
      SpecialistExtension: "98765"
    },
    {
      SpecialistType: "HR",
      SpecialistName: "Vanessa Gibson",
      SpecialistEmail: "vgibson@paycor.com",
      SpecialistPhone: "(855) 565-3285",
      SpecialistExtension: "13579"
    },
    {
      SpecialistType: "CSM",
      SpecialistName: "Dale Larson",
      SpecialistEmail: "dlarson@paycor.com",
      SpecialistPhone: "(855) 565-3285",
      SpecialistExtension: "24680"
    }
  ]
};
export default ROOT_ACTION_TYPES;
