import { ROOT_ACTION_TYPES } from "./constants";
import { FetchWrapper, DefaultLoadError } from "../Util/FetchWrapper";
import { HOST, ENV, UPPER_ENV } from "../config/environment";
import SUCCESS_TEAM_ACTION_TYPES, { MOCK_DATA } from "./constants";

export const setCurrentLocationPath = (location) => ({
    type: ROOT_ACTION_TYPES.SET_CURRENT_LOCATION_PATH,
    currentLocationPath: location,
});

export const setIsFormDirty = (isFormDirty) => ({
    type: ROOT_ACTION_TYPES.SET_FORM_DIRTY,
    isFormDirty,
});

//#region Security actions
export const setSessionTimeRemaining = (sessionTimeRemaining) => {
    return {
        type: ROOT_ACTION_TYPES.SET_SESSION_TIME_REMAINING,
        sessionTimeRemaining,
    };
};

export const setSessionTimeoutModal = (showTimeoutModal) => {
    return {
        type: ROOT_ACTION_TYPES.SHOW_SESSION_TIMEOUT_MODAL,
        showTimeoutModal,
    };
};
//#endregion

//#region Busy Indicator actions
export const setActionIndicator = (indicator) => ({
    type: ROOT_ACTION_TYPES.SHOW_ACTION_INDICATOR,
    showActionIndicator: indicator,
});
//#endregion

//#region Set Global Error Indicator action
export const setGlobalErrorIndicator = (message) => ({
    type: ROOT_ACTION_TYPES.SHOW_GLOBAL_ERROR_INDICATOR,
    message: message,
});
//#endregion

//#region  Clear Global Error Indicator action when error is resolved
export const clearGlobalErrorIndicator = () => ({
    type: ROOT_ACTION_TYPES.CLEAR_GLOBAL_ERROR_INDICATOR,
});
//#endregion

//#region Load Category request/response actions
export const requestCategories = () => ({
    type: ROOT_ACTION_TYPES.LOAD_CATEGORY_REQUEST,
    timeStamp: new Date(),
    isFetching: true,
});

export const receiveCategories = (categories) => ({
    type: ROOT_ACTION_TYPES.LOAD_CATEGORY_RESPONSE,
    categories: categories,
    timeStamp: new Date(),
    isFetching: false,
});

export const loadCategoryFailure = (errorData) => ({
    type: ROOT_ACTION_TYPES.LOAD_CATEGORY_FAILURE,
    error: errorData,
    timeStamps: new Date(),
    isFetching: false,
});

export const toggleCategory = (categoryId) => ({
    type: ROOT_ACTION_TYPES.CATEGORY_TOGGLE,
    id: categoryId,
});

export const initiateCategory = (categoryId) => ({
    type: ROOT_ACTION_TYPES.CATEGORY_INITIATE,
    id: categoryId,
});

export const clearAllCategories = () => ({
    type: ROOT_ACTION_TYPES.CATEGORY_CLEAR_ALL,
});
//#endregion

//#region Load Product request/response actions
export const requestProducts = () => ({
    type: ROOT_ACTION_TYPES.LOAD_PRODUCT_REQUEST,
});

export const receiveProducts = (products, found) => ({
    type: ROOT_ACTION_TYPES.LOAD_PRODUCT_RESPONSE,
    products: products,
    totalFound: found,
});

export const loadProductFailure = (errorData) => ({
    type: ROOT_ACTION_TYPES.LOAD_PRODUCT_FAILURE,
    error: errorData,
});

export const setProductFilter = (filter) => ({
    type: ROOT_ACTION_TYPES.SET_PRODUCT_FILTER,
    filter: filter,
});

export const setProductPage = (page) => ({
    type: ROOT_ACTION_TYPES.SET_PRODUCT_PAGE,
    page: page,
});
//#endregion

//#region Storefront / Search actions
export const setSearchTerm = (searchTerm) => ({
    type: ROOT_ACTION_TYPES.SEARCH_SET_TERM,
    currentTerm: searchTerm,
});

export const initiateSearch = (searchTerm) => ({
    type: ROOT_ACTION_TYPES.SEARCH_INITIATE,
    isSearching: true,
    searchTerm: searchTerm,
});

export const resetSearch = () => ({
    type: ROOT_ACTION_TYPES.SEARCH_RESET,
    isSearching: false,
});

export const toggleSearchPopup = (isPopupVisible) => ({
    type: ROOT_ACTION_TYPES.SEARCH_POPUP_TOGGLE,
    isEntered: isPopupVisible,
});
//#endregion

//#region Load Partner request/response actions

export const requestPartnerTypes = () => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_TYPE_REQUEST,
});

export const loadPartnerTypeFailure = (errorData) => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_TYPE_FAILURE,
    error: errorData,
});

export const receivePartnerTypes = (partnerTypes) => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_TYPE_RESPONSE,
    partnerTypes: partnerTypes,
});

export const requestPartners = (page = 1, pageSize = 100, sort = "") => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_REQUEST,
    page: page,
    sort: sort,
    pageSize: pageSize,
});

export const loadPartnerFailure = (errorData) => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_FAILURE,
    error: errorData,
});

export const receivePartners = (partners) => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_RESPONSE,
    partners: partners,
});

export const requestPartnerByName = (partnerid) => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_BYNAME_REQUEST,
    partnerid: partnerid,
});

export const loadPartnerFailureByName = (errorData) => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_BYNAME_FAILURE,
    error: errorData,
});

export const receivePartnersByName = (partner) => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_BYNAME_RESPONSE,
    partner: partner,
});

export const setPartnerTypeForPartner = (partnerTypeId) => ({
    type: ROOT_ACTION_TYPES.SET_PARTNER_TYPE,
    partnerTypeId: partnerTypeId,
});

export const clearPartnerTypeForPartner = (partnerTypeId) => ({
    type: ROOT_ACTION_TYPES.CLEAR_PARTNER_TYPE,
    partnerTypeId: partnerTypeId,
});

export const clearPartner = () => ({
    type: ROOT_ACTION_TYPES.CLEAR_PARTNER,
});

//#endregion

//#region Load Users for a Partner request/response actions
export const requestUsersByPartner = () => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_USERS_REQUEST,
});

export const loadUsersByPartnerFailure = (errorData) => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_USERS_FAILURE,
    error: errorData,
});

export const receiveUsersByPartner = (usersByPartner) => ({
    type: ROOT_ACTION_TYPES.LOAD_PARTNER_USERS_RESPONSE,
    usersByPartner: usersByPartner,
});
//#endregion

//#region Thunk action creators
export const loadUserMetaData = () => (dispatch) => {
    dispatch({
        type: ROOT_ACTION_TYPES.LOAD_USER_META_DATA_REQUEST,
        isLoadingUserMetaData: true,
    });

    let url = HOST.ENDPOINTS.USERINFO;

    let marketPlaceApi = new FetchWrapper(url);
    marketPlaceApi.get(
        (result) => {
            dispatch({
                type: ROOT_ACTION_TYPES.LOAD_USER_META_DATA_RESPONSE,
                userProfile: result,
                isAuthenticated: true,
            });
        },
        (error) => {
            // reset profile when an error occurs.
            dispatch({
                type: ROOT_ACTION_TYPES.LOAD_USER_META_DATA_RESPONSE,
                userProfile: null,
                isAuthenticated: false,
            });
        }
    );
};

export const fetchCategories = () => (dispatch) => {
    dispatch(requestCategories());

    let url = HOST.ENDPOINTS.MP_CATEGORIES;
    let marketplaceApi = new FetchWrapper(url);

    return marketplaceApi.get((data) => dispatch(receiveCategories(data)));
};

// TODO: this does not appear to be used at all.
export const ActionIndicatorMessageObject = {
    message: "",
    show: false,
};

// construct the endpoint based on search vs. show all and paging parameters.
function buildSearchEndpointUrl(
    pageIndex,
    search,
    categories = [],
    filter = "All",
    isSearching = false
) {
    const pageSize = HOST.PRODUCT_PAGE_SIZE;
    const cats = categories.join();
    const categoriesFragment = cats ? "&categories=" + cats : "";
    const filterFragment =
        filter === "All" ? "" : `&partnerLevels=Platinum,Gold`;
    let url = HOST.ENDPOINTS.MP_PRODUCTS;

    if (isSearching && search) {
        search = encodeURIComponent(search);
        url = `${url}/search?searchPhrase=${search}&page=${pageIndex}&pageSize=${pageSize}${categoriesFragment}${filterFragment}`;
    } else {
        url = `${url}?page=${pageIndex}&pageSize=${pageSize}${categoriesFragment}${filterFragment}`;
    }
    return url;
}

export const fetchProducts =
    (loadedItems = []) =>
    (dispatch, getState) => {
        // Get all global state from the store, so we don't have to pass
        // all the arguments to search / load products in as parameters.
        const storeState = getState();

        const { filter, isSearching, page, searchTerm } =
            storeState.productData;
        const { selectedCategories } = storeState.categoryData;

        dispatch(requestProducts());

        const url = buildSearchEndpointUrl(
            page,
            searchTerm,
            selectedCategories,
            filter,
            isSearching
        );
        let marketplaceApi = new FetchWrapper(url);

        return marketplaceApi.get((data) =>
            dispatch(
                receiveProducts(
                    [...loadedItems, ...data.items],
                    data.totalCount
                )
            )
        );
    };

export const fetchPartners =
    (page = 1, sort = "") =>
    (dispatch) => {
        const pageSize = HOST.PARTNER_PAGE_SIZE;

        dispatch(requestPartners(page, pageSize, sort));

        const url = `${
            HOST.ENDPOINTS.REG_PARTNERS
        }?page=${page}&pageSize=${pageSize}${sort ? "&sort=" + sort : ""}`;

        let api = new FetchWrapper(url);
        return api.get(
            (data) => {
                dispatch(receivePartners(data));
            },
            (error) => {
                if (error.status === 404) {
                    dispatch(receivePartners(null));
                } else {
                    dispatch(setGlobalErrorIndicator(DefaultLoadError));
                }
            }
        );
    };

export const fetchPartnerTypes = () => (dispatch) => {
    dispatch(requestPartnerTypes());
    const url = HOST.ENDPOINTS.PARTNER_TYPES;
    let api = new FetchWrapper(url);
    return api.get((data) => {
        dispatch(receivePartnerTypes(data));
    });
};

export const fetchPartner = (partnerid) => (dispatch) => {
    dispatch(requestPartnerByName(partnerid));
    partnerid = encodeURIComponent(partnerid);
    const url = `${HOST.ENDPOINTS.REG_PARTNERS_ADD}?partnerName=${partnerid}`;
    let api = new FetchWrapper(url);
    return api.get((data) => {
        dispatch(receivePartnersByName(data));
    });
};

export const fetchUsersByPartner = (partnerIdentifier) => (dispatch) => {
    dispatch(requestUsersByPartner(partnerIdentifier));
    partnerIdentifier = encodeURIComponent(partnerIdentifier);
    const url = `${HOST.ENDPOINTS.REG_USERSBYPARTNERS}?partnerIdentifier=${partnerIdentifier}`;

    let api = new FetchWrapper(url);

    return api.get((data) => {
        dispatch(receiveUsersByPartner(data));
    });
};
//#endregion

//#region My success team

export const loadSuccessTeamData = (clientId) => (dispatch, getState) => {
    const { COMPANY_PRIVATE } = HOST.ENDPOINTS.COMPANY_PRIVATE;
    const _url = `${COMPANY_PRIVATE}/v1/SalesProcess/api/SalesForceInformation/SpecialistInfoAllClients_V3`;

    const {
        LOAD_SUCCESS_TEAM_REQUEST,
        LOAD_SUCCESS_TEAM_RESPONSE,
        LOAD_SUCCESS_TEAM_FAILURE,
    } = SUCCESS_TEAM_ACTION_TYPES;
    const {
        ERROR_CANT_LOAD_INFO,
    } = `We had trouble loading your information. Try again later.`;

    if (ENV === "local" || ENV === "demo" || UPPER_ENV.indexOf(ENV) < 0) {
        dispatch({
            type: LOAD_SUCCESS_TEAM_RESPONSE,
            successTeam: MOCK_DATA.Records,
        });
        return;
    }

    dispatch({
        type: LOAD_SUCCESS_TEAM_REQUEST,
        isLoadingSuccessTeamData: true,
    });

    let salesforceApi = new FetchWrapper(_url);
    return salesforceApi.get(
        (data) => {
            dispatch({
                type: LOAD_SUCCESS_TEAM_RESPONSE,
                successTeam: data.Records,
                isLoadingSuccessTeamData: false,
            });
        },
        (error) => {
            dispatch({
                type: LOAD_SUCCESS_TEAM_FAILURE,
                isLoadingSuccessTeamData: false,
                message: ERROR_CANT_LOAD_INFO,
            });
        }
    );
};

//#endregion
