import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import Button from "@paycor/picl/button";
import { LineNavright } from "@paycor/picl/icon";
import "./sitefeature.scss";
import { HOST } from "../../config/environment";
import IconPerson from "../../images/Person.svg";
import IconHandshake from "../../images/Handshake.svg";
import IconMarkup from "../../images/Markup.svg";

class SiteFeature extends Component {
    renderMobileCard(icon, header, text) {
        return (
            <div className="SiteFeatureContainerMobile">
                <div className="SiteFeatureImage">
                    <img src={icon} className="SiteFeatureIcon" alt={header} />
                </div>
                <div className="NavText">
                    <div className="CategoryHeader">{header}</div>
                    <div className="CategoryText">{text}</div>
                </div>
                <div className="NavArrow">
                    <LineNavright
                        color="#bec3cc"
                        size="16px"
                        width="25px"
                        height="25px"
                        className="icon"
                    />
                </div>
            </div>
        );
    }

    render() {
        const { history } = this.props;
        return (
            <React.Fragment>
                <div className="hidden-xs SiteFeatureContainer row">
                    <div className="col-sm-4">
                        <div className=" row SiteFeatureImage">
                            <img
                                src={IconPerson}
                                className="SiteFeatureIcon"
                                alt="clients"
                            />
                        </div>
                        <div className="row CategoryHeader">Clients</div>
                        <div className="row CategoryText">
                            Browse the partners and products we can use to meet
                            your unique business needs.
                        </div>

                        <div className="row">
                            <Button
                                id="btnBrowseSolutions"
                                aria-label="primary button"
                                busy={false}
                                disabled={false}
                                type="primary"
                                className="FeatureButton"
                                onClick={() => history.push("/storefront")}
                            >
                                Browse Apps
                            </Button>
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <div className="SiteFeatureImage">
                            <img
                                src={IconHandshake}
                                className="SiteFeatureIcon"
                                alt="partners"
                            />
                        </div>
                        <div className="CategoryHeader">Partners</div>
                        <div className="CategoryText">
                            We're looking for the right partners to help our
                            clients make a difference. Ready to take your
                            business to the next level?
                        </div>
                        <div className="row">
                            <Button
                                id="Customers"
                                aria-label="primary button"
                                busy={false}
                                disabled={false}
                                type="primary"
                                onClick={() => { window.open(HOST.PARTNER_REGISTRATION); }}
                                className="FeatureButton"
                            >
                                Partner With Us
                            </Button>
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <div className="SiteFeatureImage">
                            <img
                                src={IconMarkup}
                                className="SiteFeatureIcon"
                                alt="developers"
                            />
                        </div>
                        <div className="CategoryHeader">Developers</div>
                        <div className="CategoryText">
                            Build your own connected apps with access to our
                            RESTful APIs. Let's make something great!
                        </div>
                        <div className="row">
                            <Button
                                id="btnBrowseApis"
                                aria-label="primary button"
                                busy={false}
                                disabled={false}
                                type="primary"
                                className="FeatureButton"
                                onClick={() =>
                                    (window.location.href =
                                        HOST.API_DEVELOPER_PORTAL_API)
                                }
                            >
                                View APIs
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="visible-xs FeauxTainer">
                    <NavLink
                        id="searchmobile"
                        to="/storefront"
                        className="SiteFeatureLink"
                    >
                        {this.renderMobileCard(
                            IconPerson,
                            "Clients",
                            "Browse our industry-leading partners"
                        )}
                    </NavLink>

                    <NavLink
                        id="partnermobile"
                        to="/signup"
                        className="SiteFeatureLink"
                    >
                        {this.renderMobileCard(
                            IconHandshake,
                            "Partners",
                            "Ready to make a difference?"
                        )}
                    </NavLink>

                    <a
                        id="devportalmobile"
                        href={HOST.API_DEVELOPER_PORTAL}
                        className="SiteFeatureLink"
                    >
                        {this.renderMobileCard(
                            IconMarkup,
                            "Developers",
                            "Build your own connected apps"
                        )}
                    </a>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(SiteFeature);
