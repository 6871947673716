import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Collapsible from "@paycor/picl/collapsible";
import "./faq.scss";
import MetaTags from "react-meta-tags";

class FaqIndex extends Component {
    render() {
        let faqIndex = 1;
        return (
            <div>
                <MetaTags>
                      <title>Marketplace Help Center</title>
                    <meta
                        name="description"
                        content="We’re here to help! Find answers to the most common questions or connect with someone who can help."
                    />
                </MetaTags>
                <div className="helpcontent">
                    <div className="HelpHeader">Help</div>
                    <div className="Frequently-Asked-Que">
                        Frequently Asked Questions
                    </div>
                    <div className="QnA" />
                    <Collapsible handleMultiple={true}>
                        <Collapsible.Item
                            title={"What is Marketplace?"}
                            id={`faqItem_${faqIndex++}`}
                        >
                            <div style={{ padding: "1.25em" }}>
                                <p>
                                    Paycor's Marketplace gives you access to our
                                    partner technology and service providers.
                                    These are integrated products and valuable
                                    service offerings we recommend. Go to{" "}
                                    <NavLink to="/storefront">
                                        Browse Apps
                                    </NavLink>{" "}
                                    to discover new ways to streamline your
                                    business!
                                </p>
                            </div>
                        </Collapsible.Item>
                        <Collapsible.Item
                            title={"How do I use Marketplace?"}
                            isActive={false}
                            id={`faqItem_${faqIndex++}`}
                        >
                            <div
                                style={{ padding: "1.25em", fontWeight: "300" }}
                            >
                                <ol type="1">
                                    <li>
                                        Navigate to Marketplace from your Paycor
                                        account via the top navigation or from
                                        Paycor.com. Make sure you are signed
                                        into your Paycor account, so we can
                                        customize your Marketplace experience
                                        and provide you with the best
                                        information possible.
                                    </li>
                                    <li>
                                        Take a look at our Featured Partners on
                                        the Marketplace home page or go to
                                        Browse Apps to search for a specific
                                        product or service.
                                    </li>
                                    <li>
                                        On the Browse Apps page, you can search
                                        for something specific or use the
                                        filters on the left side of the screen
                                        to sift through our partner offerings.
                                    </li>
                                    <li>
                                        Click on any of the apps, products, or
                                        services that interest you. Each has a
                                        product detail page with information you
                                        may find valuable.
                                    </li>
                                    <li>
                                        Found something you want? Click{" "}
                                        <i>Contact Representative</i> to connect
                                        with the organization offering the app,
                                        product, or service. To help Paycor and
                                        our partners deliver the best solution
                                        possible, please provide as much
                                        information as you can in the comment
                                        box.
                                    </li>
                                </ol>
                            </div>
                        </Collapsible.Item>
                        <Collapsible.Item
                            title={
                                "What happens when I click on Contact Representative?"
                            }
                            isActive={false}
                            id={`faqItem_${faqIndex++}`}
                        >
                            <div style={{ padding: "1.25em" }}>
                                <p>
                                    If you're a current Paycor client searching
                                    for products and services, we recommend
                                    signing in before or immediately after you
                                    click <i>Contact Representative</i>. This
                                    will streamline and improve your Marketplace
                                    experience. When you click{" "}
                                    <i>Contact Representative</i>, a
                                    notification is sent to our internal team to
                                    let us know you're interested in working
                                    with one of our trusted partners. Unless
                                    you're inquiring about our Retirement or
                                    Benefits Administration partners, the
                                    information you provide in the form is also
                                    shared directly with a Partner
                                    representative. From there, a Partner
                                    representative will reach out to you with
                                    more information to guide you through the
                                    appropriate next steps.
                                </p>
                            </div>
                        </Collapsible.Item>
                        <Collapsible.Item
                            title={
                                "What if I can't find the product or service I am looking for?"
                            }
                            isActive={false}
                            id={`faqItem_${faqIndex++}`}
                        >
                            <div style={{ padding: "1.25em" }}>
                                <p>
                                    We are always working to improve our
                                    offerings and would love your feedback. If
                                    you have a product or service suggestion
                                    that would help you manage your business,{" "}
                                    <NavLink to="/contactus">
                                        please share your recommendation
                                    </NavLink>
                                    .
                                </p>
                            </div>
                        </Collapsible.Item>
                        <Collapsible.Item
                            title={
                                "What if I already use a product that is not yet on Marketplace?"
                            }
                            isActive={false}
                            id={`faqItem_${faqIndex++}`}
                        >
                            <div style={{ padding: "1.25em" }}>
                                <p>
                                    We're always evaluating new products or
                                    services to strengthen our growing network
                                    of Partners. If your current provider is not
                                    on Marketplace yet, please contact your
                                    salesperson for additional integration
                                    options.
                                </p>
                            </div>
                        </Collapsible.Item>
                        <Collapsible.Item
                            title={
                                "How do I view pricing for Marketplace products or services?"
                            }
                            isActive={false}
                            id={`faqItem_${faqIndex++}`}
                        >
                            <div style={{ padding: "1.25em" }}>
                                <p>
                                    Our partners are best qualified to assess
                                    your business needs and provide pricing. You
                                    will receive pricing shortly after you
                                    submit your request via the{" "}
                                    <i>Contact Representative</i> button located
                                    within the Partner's Product Detail page.
                                </p>
                            </div>
                        </Collapsible.Item>
                        <Collapsible.Item
                            title={
                                "How do I get in touch with the right person to learn more about a Marketplace offering?"
                            }
                            isActive={false}
                            id={`faqItem_${faqIndex++}`}
                        >
                            <div style={{ padding: "1.25em" }}>
                                <p>
                                    A Partner representative will contact you
                                    via the information (email or phone) that
                                    you provide in the{" "}
                                    <i>Contact Representative</i> form within
                                    1-2 business days. These representatives are
                                    equipped to answer questions about pricing,
                                    integration specifics, product
                                    demonstrations, and more. If you have
                                    immediate questions or concerns that can't
                                    be resolved by someone at the partner
                                    organization, please contact your Paycor
                                    Success Team or{" "}
                                    <NavLink to="/contactus">
                                        submit an inquiry
                                    </NavLink>
                                    . We're always ready to help!
                                </p>
                            </div>
                        </Collapsible.Item>
                        <Collapsible.Item
                            title={
                                "When I click on Contact Representative, who receives my information?"
                            }
                            isActive={false}
                            id={`faqItem_${faqIndex++}`}
                        >
                            <div style={{ padding: "1.25em" }}>
                                <p>
                                    When you click submit, Paycor receives a
                                    direct notification and your information is
                                    also sent to the organization who delivers
                                    the product or service. This information is
                                    used to connect you to the appropriate
                                    representative and to enhance your
                                    experience while evaluating their product or
                                    service.
                                </p>
                            </div>
                        </Collapsible.Item>
                        <Collapsible.Item
                            title={
                                "I'm not a Paycor client. Can I still use Marketplace?"
                            }
                            isActive={false}
                            id={`faqItem_${faqIndex++}`}
                        >
                            <div style={{ padding: "1.25em" }}>
                                <p>
                                    Yes! You're welcome to browse our Featured
                                    Apps, search for your favorite products, or
                                    learn more about our Partners. If you click{" "}
                                    <i>Contact Representative</i>, we'll connect
                                    you with a Paycor team member who is ready
                                    to help you evaluate your options.
                                </p>
                            </div>
                        </Collapsible.Item>
                        <Collapsible.Item
                            title={
                                "I already have a Benefits Administration system through my broker. Can they use Marketplace to set up an integration?"
                            }
                            isActive={false}
                            id={`faqItem_${faqIndex++}`}
                        >
                            <div style={{ padding: "1.25em" }}>
                                <p>
                                    We'd love to hear from your broker. Please
                                    ask them to{" "}
                                    <NavLink to="/signup">
                                        reach out to us
                                    </NavLink>
                                    .
                                </p>
                            </div>
                        </Collapsible.Item>
                    </Collapsible>
                </div>
            </div>
        );
    }
}

export default FaqIndex;
