export function truncate(str, maxsize) {
  if (str.length > maxsize) return str.substring(0, maxsize) + "...";
  else return str;
}

export function getQueryString(url) {
  var n = url.indexOf("?");
  return url.substring(n, url.length);
}

export function fixImageUri(srcUri) {
  var newUri = srcUri.replace("./", "/");
  return newUri;
}
