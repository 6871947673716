import React from "react";
import { connect } from "react-redux";
import Button from "@paycor/picl/button";
import Modal from "@paycor/picl/modal";
import { Phone, Envelope, Error } from "@paycor/picl/icon";
import "./mysuccessteam.scss";
import {
    getSuccessTeam,
    getIsLoadingSuccessTeam,
    getUserProfile,
} from "../../store/root-reducer";
import * as SUCCESS_TEAM_ACTIONS from "../../store/actions";

class SuccessTeamModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.showSuccessTeam,
        };
        this.props.loadUserMetaData();
    }
    componentWillMount() {
        this.props.loadSuccessTeamData();
    }

    getTypeHeader = (type) => {
        switch (type) {
            case "Payroll":
                return "Pay & Time";
            case "HRBenefitCOE":
                return "HR Core of Excellence";
            default:
                return type;
        }
    };

    getTypeDescription = (type) => {
        let _desc = "";
        switch (type) {
            case "Payroll":
                _desc = "paygrid, non-benefit deductions, perform time";
                break;
            case "HRBenefitCOE":
                _desc = "employee benefits, aca, COBRA";
                break;
            case "HR":
                _desc = "employee self-service, workflows";
                break;
            default:
                _desc = "";
                break;
        }

        return _desc === "" ? null : <div className="description">{_desc}</div>;
    };

    handleToggleModalButton = () => {
        this.props.okayClicked();
    };

    cleanPhoneNumber = (phone, extension) => {
        if (Boolean(phone)) {
            let _number = `+1${phone.replace(/\D/g, "")}`;
            if (Boolean(extension)) {
                _number += `p${extension}`;
            }
            return `tel:${_number}`;
        } else {
            /* eslint-disable-next-line */
            return "javascript:void(0)";
        }
    };

    render() {
        let show = this.state.show;
        const { isLoadingSuccessTeam, successTeam, userProfile } = this.props;

        return (
            <Modal
                show={show}
                showClose={true}
                size=""
                title="My Success Team"
                body={
                    <div className="successteammodal">
                        {userProfile.clients &&
                            userProfile.clients.length === 1 && (
                                <div className="client-reference">
                                    Your Client ID for Reference:{" "}
                                    <span className="client-details">{`${userProfile.clients[0].clientId} - ${userProfile.clients[0].name}`}</span>
                                </div>
                            )}
                        {isLoadingSuccessTeam ? (
                            <div className="loading-success-team">
                                <div className="teammember">
                                    <div className="type">&nbsp;</div>
                                    <div className="name">&nbsp;</div>
                                    <div className="phone">
                                        {/* eslint-disable-next-line */}
                                        <a href="javascript:void(0)">
                                            <Phone className="icon-phone" />{" "}
                                            <span>&nbsp;</span>
                                        </a>
                                    </div>
                                    <div className="email">
                                        {/* eslint-disable-next-line */}
                                        <a href="javascript:void(0)">
                                            <Envelope className="icon-email" />{" "}
                                            <span>&nbsp;</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="success-team">
                                {successTeam.length > 0 ? (
                                    successTeam.map((member, index) => (
                                        <div className="teammember">
                                            <div className="type">
                                                {member.SpecialistType}
                                            </div>
                                            <div className="name">
                                                {member.SpecialistName}
                                            </div>
                                            <div className="phone">
                                                <a
                                                    href={this.cleanPhoneNumber(
                                                        member.SpecialistPhone,
                                                        member.SpecialistExtension
                                                    )}
                                                >
                                                    <Phone className="icon-phone" />{" "}
                                                    {member.SpecialistPhone}
                                                    {Boolean(
                                                        member.SpecialistExtension
                                                    )
                                                        ? ` ext. ${member.SpecialistExtension}`
                                                        : ``}
                                                </a>
                                            </div>
                                            <div className="email">
                                                <a
                                                    href={`mailto:${member.SpecialistEmail}`}
                                                >
                                                    <Envelope className="icon-email" />{" "}
                                                    {member.SpecialistEmail}
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="banner-message">
                                        <Error />
                                        <span>
                                            Sorry, we can't find your specialist
                                            information. If you already have
                                            their contact info, please call or
                                            email them directly. If not, call us
                                            at{" "}
                                            <a href={`tel:1-800-381-0053`}>
                                                1-800-381-0053
                                            </a>{" "}
                                            and ask for your product specialist.
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                }
                footer={
                    <div className="modalFooterButtons">
                        <Button
                            aria-label="primary button"
                            busy={false}
                            disabled={false}
                            type="primary"
                            onClick={this.handleToggleModalButton}
                            children="OK"
                            className="modalButtons"
                        />
                    </div>
                }
                backdrop={true}
                footerStyle={Modal.Footer.DARK}
                onClose={this.handleToggleModalButton}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoadingSuccessTeam: getIsLoadingSuccessTeam(state),
        userProfile: getUserProfile(state),
        successTeam: getSuccessTeam(state),
    };
};

export default connect(mapStateToProps, SUCCESS_TEAM_ACTIONS)(SuccessTeamModal);
