import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "@paycor/picl/modal";
import PartnerTypeList from "./PartnerTypeList";
import { clearPartnerTypeForPartner } from "../../store/actions";
import Banner from "@paycor/picl/banner";
import Button from "@paycor/picl/button";

class PartnerTypesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missingTypes: false,
            showModal: false,
        };

        this.onSelectPartnerType = this.onSelectPartnerType.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
    }

    onClickSave() {
        const { partner, partnerTypes, dispatch } = this.props;

        if (partner) {
            const { types } = partner;

            // Filter out any selected elements that aren't in the list of valid partnerTypes.
            const invalidTypes = types.filter(
                (selected) =>
                    !partnerTypes.find((type) => type.partnerType === selected)
            );
            invalidTypes.map((type) =>
                dispatch(clearPartnerTypeForPartner(type))
            );

            if (types && types.length > 0) {
                this.setState({
                    showModal: false,
                });
            } else
                this.setState({
                    missingTypes: true,
                });
        }
    }

    onClickCancel() {
        this.setState({
            showModal: false,
        });
    }

    onSelectPartnerType() {
        this.setState({
            showModal: true,
            missingTypes: false,
        });
    }

    render() {
        const { showModal, isMissingTypes } = this.state;
        const { partner, isEditMode } = this.props;
        let types = [];

        if (partner) types = partner.types;

        return (
            <React.Fragment>
                <div>
                    <Button
                        aria-label="secondary button"
                        busy={false}
                        disabled={false}
                        type={Button.Types.SECONDARY}
                        onClick={this.onSelectPartnerType}
                        children={isEditMode ? "Edit Type" : "+ Add Type"}
                        className="addTypeButtons"
                    />
                </div>
                <Modal
                    show={showModal}
                    showClose={true}
                    size=""
                    title="Select Partner Type"
                    body={
                        <div className="modalPartnerTypes">
                            {isMissingTypes ? (
                                <Banner
                                    id={"DataMissingPartnerType"}
                                    message={
                                        "Please select at least one partner type."
                                    }
                                    status={Banner.Status.ERROR}
                                    show={true}
                                />
                            ) : (
                                ""
                            )}
                            Select one or more partner type.
                            <div className="headerPartnerTypes">
                                Partner Types
                            </div>
                            <div className="checkboxdiv">
                                <PartnerTypeList selectedTypes={types} />
                            </div>
                        </div>
                    }
                    footer={
                        <div className="modalFooterButtons">
                            <Button
                                aria-label="secondary button"
                                busy={false}
                                disabled={false}
                                type={Button.Types.SECONDARY}
                                onClick={this.onClickCancel}
                                children="Cancel"
                                className="modalButtons"
                            />
                            <Button
                                aria-label="primary button"
                                busy={false}
                                disabled={false}
                                type="primary"
                                onClick={this.onClickSave}
                                children="OK"
                                className="modalButtons"
                            />
                        </div>
                    }
                    backdrop={true}
                    footerStyle={Modal.Footer.DARK}
                    onClose={this.onClickCancel}
                />
            </React.Fragment>
        );
    }
}

const mapPropsToState = (state) => {
    const { partner } = state.partnerByNameData;
    const { partnerTypes } = state.partnerTypeData;
    return { partner, partnerTypes };
};

export default connect(mapPropsToState)(PartnerTypesModal);
