import React, { Component } from "react";
import { connect } from "react-redux";
import { clearAllCategories } from "../../store/actions";
import ProductList from "./ProductList";
import Button from "@paycor/picl/button";
import FilterPanel from "./FilterPanel";
import FilterNav from "./filterNav";
import CategoryList from "./categoryList";
import Drawer from "@paycor/picl/drawer";

class ProductContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        };
        this.onClick = this.onClick.bind(this);
        this.onApplyClicked = this.onApplyClicked.bind(this);
        this.onClearAllClicked = this.onClearAllClicked.bind(this);
    }

    onClick() {
        this.toggleModal();
    }

    toggleModal() {
        this.setState({ showModal: !this.state.showModal });
    }

    onApplyClicked() {
        this.toggleModal();
    }

    onClearAllClicked() {
        const { dispatch } = this.props;
        dispatch(clearAllCategories());
    }

    render() {
        const { searchTerm, isSearching, totalFound, products, filter } =
            this.props;

        const heading = filter !== "All" ? filter : "All Apps";

        const header = isSearching
            ? `${totalFound} result${
                  totalFound === 1 ? "" : "s"
              } for "${searchTerm}"`
            : `${heading} (${totalFound})`;

        const sortByClass = isSearching ? "visible" : "hidden";

        return (
            <React.Fragment>
                <div className="row">
                    <div className="StoreFrontHeader col-xs-12 col-md-9">
                        {header}
                    </div>
                    <div
                        className={`sortOrder col-md-3 ${sortByClass}-xs ${sortByClass}-sm ${sortByClass}-md ${sortByClass}-lg`}
                    >
                        Sorted by: Relevance
                    </div>
                </div>

                <div className="row">
                    <div
                        className="col-xs-6 col-sm-6 col-md-12"
                        style={{
                            color: "#6a7280",
                            paddingBottom: "16px",
                            paddingTop: "20px",
                        }}
                    >
                        {products.length} app{products.length === 1 ? "" : "s"}{" "}
                        shown
                    </div>
                    <div className="col-xs-6 col-sm-6 visible-xs visible-sm filterSortButton">
                        <Button
                            id="btnFilterSort"
                            type="secondary-action"
                            onClick={this.onClick}
                        >
                            Filter
                        </Button>
                    </div>
                </div>

                <ProductList />
                <Drawer isOpen={this.state.showModal}>
                    <FilterPanel
                        size="lg"
                        hideHeaderCloseButton={false}
                        onApplyClicked={this.onApplyClicked}
                        onClearAllClicked={this.onClearAllClicked}
                        title="Filter"
                    >
                        <FilterNav />
                        <div className="CategoriesHeader paddingtop">
                            Categories
                        </div>
                        <CategoryList />
                    </FilterPanel>
                </Drawer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { products, searchTerm, isSearching, totalFound, filter } =
        state.productData;
    return {
        products,
        searchTerm,
        isSearching,
        totalFound,
        filter,
    };
};

export default connect(mapStateToProps)(ProductContainer);
